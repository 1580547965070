import { sendRequest } from "../../hooks/requests/useRequest";

export async function getAllClients ()
{
    const requestOptions = {
        method: 'GET'
    };

    const response = await sendRequest(requestOptions, `Company/List`);

    if (response.code === 200)
        return response.data;
    else 
        return response;
}

export async function getCompanyBusinessNameByClientId (client_id)
{
    const requestOptions = {
        method: 'GET'
    };

    const response = await sendRequest(requestOptions, `Company/ListBusinessNames/${client_id}`);

    if (response.code === 200)
        return response.data;
    else return response;
}

export async function updateFiscalInformationClient({
    id_company_business_name, business_name, rfc, id_tax_regime, cp, email
}) {
    const body = {
        id_company_business_name,
        business_name, 
        rfc,
        id_tax_regime,
        cp, 
        email
    };

    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(body)
    };

    const response = await sendRequest(
        requestOptions, "Company/UpdateBusinessName"
    );

    console.log(response);

    if (response.code === 200)
        return {
            code: response.code,
            data: response.data
        }
    else if (response.code === 401)
        return response.code;
    else
        return {
            code: response.code,
            msg: response?.data?.msg
        };
}