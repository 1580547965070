import React, { useEffect, useState } from 'react'
import { sendRequest } from '../../../hooks/requests/useRequest';
import { validarSesion } from '../../../hooks/tokens/useToken';
import { useSweetAlert } from '../../../hooks/sweetAlerts/useSweetAlert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Code500 } from '../../../constant/globalMessage';
import { ComponentPresumtiveResult } from './ComponentPresumtiveResult';
import { Button, ButtonGroup, Col, Input, Row, Tooltip } from 'reactstrap'



export const ComponentCommentGlobal = ({ addIsolated,isolated_Micro,setIsolated_Micro,removeAddIsolated }) => {

    //*SAVE RESULTS INPUTS
    const handleChangeIsolated = (e, _id, _valid_Input) => {

        let _newArray = isolated_Micro

        let _valid = _newArray.find(x => x.id === _id);
    
        if (_valid !== undefined) {
            switch (_valid_Input) {
            case "antibio":
                _valid.antibiotic = e.target.value;
                break;
            default:
                break;
            }
        }
        setIsolated_Micro([..._newArray]);
    }

        

    return (
        <>
          <h5>Comentario Global</h5>
        
                      

        
                            <Col sm="10" className='p-l-0 p-r-0'>
                            
                                   
                                   
                                        <Input
                                        disabled={true}
                                        type='text'
                                        name="antibio"
                                        className='form-control form-control-sm input-air-primary border-primary input-mic'
                                        />
                                    
                              
                            </Col>
                            
                       
               
        </>
    )
}
