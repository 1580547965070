import React from 'react'
import {Button, Modal, ModalBody, CardBody, Table,Col, Form, Input, FormFeedback,Row, FormGroup } from 'reactstrap';
import moment from 'moment';
import Datetime from 'react-datetime';

export const ModalPreviewTicket = (props) => {

    const {modalPrint,toggleModalPrint,dataHistoric,printTicket,loadingPrint, downloadExcel, changeDate, handleHistoric, dateInit,dateEnd} = props;

    
    return (
        <Modal isOpen={modalPrint} toggle={toggleModalPrint} backdrop='static' keyboard={false} centered={true}  size="lg">
            <Form className="form theme-form" role="form">
                <ModalBody>   
                <CardBody className="socialprofile filter-cards-view p-l-0 p-b-0 p-r-0">
                    {
                        <>
                        <Col sm="12">
                        <Row>
                            <Col sm="12" className='text-center p-l-0 p-r-0' >
                            <label className=" text-center f-14 f-w-600"> HISTORIAL DE RECIBOS </label>
                            <div className='hr-detail-patient' >
                           </div>
                            </Col>
                            <Col sm="12" className='mt-3'>
                                <Row>

                                    <Col sm="3" className='p-1'>
                                        <i className="fa fa-calendar fa-lg text-info mr-1"></i> <b>Filtrar por fecha:</b>
                                    </Col>

  
                                    <Col sm="3" className='p-l-0' >
                                        <FormGroup className="">
                                            <Datetime
                                                inputProps={{
                                                    placeholder: 'YYYY-MM-DD',
                                                    name: "workFrom",
                                                    autoComplete: "false",
                                                    className: "form-control digits input-air-primary form-control-sm",
                                                }}
                                                timeFormat={false}
                                                dateFormat="YYYY-MM-DD"
                                                value={dateInit}
                                                locale="es"
                                                initialValue={new moment().format('YYYY-MM-DD')}
                                                onChange={(e) => changeDate(e, "init")}
                                                closeOnSelect={true}
                                            />
                                        </FormGroup>
                                    </Col> 
                                    <Col sm="3">
                                        <FormGroup >
                                            <Datetime
                                                inputProps={{
                                                    placeholder: 'YYYY-MM-DD',
                                                    name: "workFrom",
                                                    autoComplete: "false",
                                                    className: "form-control digits input-air-primary form-control-sm ",
                                                    size: "sm"
                                                }}
                                                timeFormat={false}
                                                dateFormat={"YYYY-MM-DD"}
                                                value={dateEnd}
                                                locale="es"
                                                initialValue={new moment().format('YYYY-MM-DD')}
                                                onChange={(e) => changeDate(e, "end")}
                                                closeOnSelect={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" className='p-0'>
                                        <Button type="button" onClick={() => handleHistoric(true)}  outline color="primary" size='sm' className={("text-center")}> FILTRAR HISTORIAL</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <div className='table-wrapper mt-2'>
                            <Table className="table table-hover table-bordered b-r-5 table-sm">
                                <thead className='b-r-5'>
                                    <tr style={{ backgroundColor: "#469ed7" }}>
                                        <th className='labelEnum ' style={{ textAlign: "center" }}>#</th>
                                        <th className='labelDate' style={{ textAlign: "center" }}>Fecha</th>
                                        <th className='labelActions' style={{ textAlign: "center"}}>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {loadingPrint && (
                                        <tr>
                                            <td colSpan={3} className='bg-info text-center f-16'>
                                                <i className="fa fa-spin fa-spinner"></i> Cargando...
                                            </td>
                                        </tr>
                                    )} */}

                                    {!loadingPrint && (!dataHistoric || dataHistoric.length === 0) && (
                                        <tr>
                                            <td colSpan={3} className="text-center">
                                                <label className="f-w-600 f-12">SIN HISTORIAL DE RECIBOS</label>
                                            </td>
                                        </tr>
                                    )}

                                    {!loadingPrint && dataHistoric?.map((_find, _key) => (
                                        <tr key={_key}>
                                            <td style={{ textAlign: "center" }}>
                                                <label className='f-w-600 f-12'>{_key + 1}</label>
                                            </td>

                                            <td style={{ textAlign: "center" }}>
                                                <label className='f-w-600 f-12'>{_find?.fech ?? "----"}</label>
                                            </td>

                                            <td style={{ textAlign: "center" }}>
                                                <img
                                                    src={require("../../assets/images/price/downloadExcel.png")}
                                                    alt="Excel"
                                                    title='Descargar Excel'
                                                    style={{ cursor: 'pointer', width: '36px', height: '36px' }}
                                                    onClick={() => downloadExcel(_find.id_cash_flow)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                   </div>

                        </Row>
                        </Col>
                        <Col sm="12" style={{textAlign : "right"}} className='p-l-0 p-r-0' > <br />
                       
                        <Row>
                           
                            <Col sm="12" className="d-flex justify-content-end mt-1">
                                <Button color="danger text-center" type="button" onClick={toggleModalPrint}>Cancelar</Button>
                            </Col>

                        </Row>
                            
                        </Col>
                        </>
                    }
                </CardBody>   
                </ModalBody>
            </Form>
        </Modal>
    )
}
