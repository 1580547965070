import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import { getPatientById, updateFiscalInformationPatient } from '../../services/patient/patient';
import { GetInvoiceId, getPayForms, sendStampIngress } from '../../services/invoice/invoice';
import { validarSesion } from '../tokens/useToken';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { getAllCommercialLines } from '../../services/commercialLine/commercialLine';
import { getAllClients, getCompanyBusinessNameByClientId, updateFiscalInformationClient } from '../../services/clients/clients';
import { getListUseOfCfdi } from '../../services/invoice/useCfdi';
import { getDetailWorkOrderByInvoice, getDetailWorkOrdersByDateRange, getDetailWorkOrdersByGeneralPublic, getWorkOrderByPatientOrCompany } from '../../services/workOrders/workOrders';
import { getTaxRegimeByTypePerson } from '../../services/invoice/taxRegime';
import { FormatAmount } from '../../utils/formatNumbers';
import ReceptorInvoice from '../../utils/enum/ReceptorInvoice';
import InformationGeneralPublicEnum, { listOfMonthsByGroupEnum, listOfMonthsEnum, listPeriodicityEnum, TypeVIsualizationEnum } from '../../utils/enum/Invoice_enum';
import TypePersonInvoiceEnum from '../../utils/enum/type_person.enum';
import ValidateFiscalFormPatient from '../../utils/validations/patientValidations';
import ValidateFiscalFormClient from '../../utils/validations/clientValidations';
import moment from 'moment';
import { getListBranches } from '../../services/branches/branches';

export const useInvoiceService = () => {

	const branchId = localStorage.getItem('branchId');
	const urlClient = localStorage.getItem('url_helen');

	const { id_work_order_r, id_patient_r, _id_company_r, request_id_invoice } = useParams();

	const listPayMethods = [
		{
			value: "PUE",
			label: "(PUE) PAGO EN UNA SOLA EXHIBICIÓN",
		},
		{
			value: "PPD",
			label: "(PPD) PAGO EN PARCIALIDADES O DIFERIDO"
		}
	];

	const listOfPeriodicity = listPeriodicityEnum;

	const history = useHistory();
	const [createSweet] = useSweetAlert();

	// * MODALS
	const [showUpdateFiscalInformationPatient, setShowUpdateFiscalInformationPatient] = useState(false);
	const [showUpdaetFiscalInformationClient, setShowUpdaetFiscalInformationClient] = useState(false)
	const [showExtraPatient, setShowExtraPatient] = useState(false);
	const [showPreviewInvoice, setShowPreviewInvoice] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [showError, setShowError] = useState(false);

	// * CARD VIEWS
	const [showCardFilterByDate, setShowCardFilterByDate] = useState(false);

	// * VALIDATIONS

	const [validationsPatient, setValidationsPatient] = useState({
		id_tax_regime_patient_valid: false,
		rfc_patient_valid: false,
		cp_patient_valid: false,
		email_valid: false,
		business_name_patient_valid: false
	});

	const [validationsBusinessName, setValidationsBusinessName] = useState({
		id_tax_regime_business_name_valid: false,
		business_name_valid: false,
		cp_business_name_valid: false,
		rfc_business_name_valid: false,
	});

	const [viewInformationReceptor, setViewInformationReceptor] = useState(false);
	const [filterTypeReceptor, setFilterTypeReceptor] = useState('');
	const [viewInformationIssuing, setViewInformationIssuing] = useState(false);
	const [isEditInvoice, setIsEditInvoice] = useState(false);

	// * FORM INVOICE
	const [formInvoice, setFormInvoice] = useState({
		invoice_id: null,
		patient_id: null,
		client_id: null,
		client_business_id: null,
		commercial_line_id: null,
		receptor_information: null,
		issuing_information: null,
		type: null,
		branch_id: branchId,
		pay_form_id: null,
		pay_method_id: null,
		use_cfdi_id: null,
		is_general_public: false,
		periodicity_id: null,
		month_id: null,
		year_billing: {
			label: moment().format('YYYY'),
			value: moment().format('YYYY'),
		},
		expedition_place: null,
		payment_conditions: null,
		comments: "",
		include_name_patient: true,
		include_detail_period: false,
		include_sells: false,
		type_visualization: TypeVIsualizationEnum.DETAIL,
		listOrders: [],
		viewGeneral: {
			productCode: "",
			unitCode: "",
			nameUnit: "",
			numberIdentification: "",
			description: "",
			unitPrice: 0,
			import: 0,
			discount: 0,
			tax: 0,
			retention: 0,
			total: 0,
		},
		viewGroup: [],
		global_description: ''
	});

	const [formPatientFiscalInformation, setFormPatientFiscalInformation] = useState({
		tax_regime_id_patient: 0,
		code_tax_regime: "",
		name_patient: "",
		business_name_patient: '',
		type_person_patient: TypePersonInvoiceEnum.FISICA,
		cp_patient: '',
		rfc_patient: '',
		email_patient: ''
	});

	const [formCompanyFiscalInformation, setFormCompanyFiscalInformation] = useState({
		tax_regime_id_client: 0,
		business_name_client: "",
		rfc_client: "",
		cp_client: "",
		email_client: "",
		type_person_client: TypePersonInvoiceEnum.MORAL,
		code_tax_regime_client: ""
	})

	const [optionsDue, setOptionsDue] = useState({
		subtotal: 0,
		discounts: 0,
		tax: 0,
		retentions: 0,
		total: 0
	});

	// * LIST DATA
	const [listCommercialLines, setListCommercialLines] = useState([]);
	const [listClients, setListClients] = useState([]);
	const [listPayForms, setListPayForms] = useState([]);
	const [listPayFormsWorkOrder, setListPayFormsWorkOrder] = useState([]);
	const [listUseOfCfdis, setListUseOfCfdis] = useState([]);
	const [listTaxRegimes, setListTaxRegimes] = useState([]);
	const [listClientCompanyBUsinessName, setListClientCompanyBUsinessName] = useState([]);
	const [listWorkOrdersTemp, setListWorkOrdersTemp] = useState([]);
	const [listFilterNims, setListFilterNims] = useState([]);
	const [listOfMonths, setListOfMonths] = useState([]);
	const [listBranches, setListBranches] = useState([]);

	// * SEARCH SELECTS
	const [searchPatientState, setSearchPatientState] = useState([]);
	const searchExtraPatient = [];
	const [searchValueInput, setSearchValueInput] = useState('');
	const [selectCompany, setSelectCompany] = useState(null);
	const [selectCommercialNameCompany, setSelectCommercialNameCompany] = useState(null);
	const [filterByBranch, setFilterByBranch] = useState(null);
	const [filterByPayMethod, setFilterByPayMethod] = useState(null);

	// * FILTER DATES
	const [filterDateRanges, setFilterDateRanges] = useState({
		startDate: moment().format("YYYY-MM-DD"),
		endDate: moment().format("YYYY-MM-DD")
	});

	// * LOADING WORK ORDERS
	const [isLoadingWorkOrders, setIsLoadingWorkOrders] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);

	//#region  USE EFFECTS FIRST LOADING

	useEffect(() => {
		GetCommercialLines();
		GetPayForms();
	}, []);

	useEffect(() => {
		typeVisualizationTable();
	}, [formInvoice.type_visualization]);

	useEffect(() => {
		if (request_id_invoice !== undefined)
			GetInvoiceById();
	}, [request_id_invoice]);

	useEffect(() => {

		if (_id_company_r > 0) {
			handleChangeTyepeReceptor(ReceptorInvoice.COMPANY)
			handleChangeClient({
				value: _id_company_r,
				label: "Selecciona una razón social"
			});
		}

	}, [_id_company_r])

	useEffect(() => {

		if (id_patient_r !== undefined && parseInt(id_patient_r) > 0 && _id_company_r === 0) {
			handleChangeTyepeReceptor(ReceptorInvoice.PATIENT)
			handleChangePatient({
				target: {
					value: id_patient_r
				}
			})
		}

	}, [id_patient_r]);

	//#endregion

	useMemo(() => {

		if (formInvoice.listOrders?.length > 0) {
			let subtotal = 0;
			let discounts = 0;
			let taxes = 0;
			let retentions = 0;
			let total = 0;

			formInvoice.listOrders.forEach((order) => {
				if (!order?.is_deleted) {
					subtotal += order.import;
					discounts += order?.total_discount;
					taxes += order?.price_tax;
					retentions += order?.retention;
					total += order.total;
				}
			});

			setOptionsDue({
				subtotal: FormatAmount(subtotal, 2),
				discounts: FormatAmount(discounts, 2),
				tax: FormatAmount(taxes, 2),
				retentions: FormatAmount(retentions, 2),
				total: FormatAmount(total, 2)
			});
		}

	}, [formInvoice.listOrders])

	const GetCommercialLines = async () => {
		const commercialLines = await getAllCommercialLines();

		if (commercialLines?.length > 0) {
			const list = commercialLines?.map(commercial => {
				return {
					value: commercial?.id_commercial_line,
					label: commercial?.long_name,
					rfc: commercial?.rfc,
					cp: commercial?.cp,
					id_tax_regime: commercial?.id_tax_regime,
					code_tax_regime: commercial?.code_tax_regime,
					name_tax_regime: commercial?.name_tax_regime,
					type_person: commercial?.type_person ? TypePersonInvoiceEnum.MORAL : TypePersonInvoiceEnum.FISICA,
				}
			});

			setListCommercialLines(list);
		}
		else
			setListCommercialLines([]);
	}

	const GetAllClients = async () => {
		const listClients = await getAllClients();

		if (listClients?.length > 0) {
			const list = listClients?.map(client => {
				return {
					value: client.id_company,
					label: `${client?.code} ${client?.name}`
				}
			});

			setListClients(list);
		}
		else setListClients([]);
	}

	const GetPayForms = async () => {
		const payForms = await getPayForms();

		if (payForms?.length > 0) {
			const list = payForms?.map(form => {
				return {
					label: form.abbreviation + " - " + form.name,
					value: form.id_pay_method,
					code: form.abbreviation
				}
			});

			setListPayForms(list);
		}
		else
			setListPayForms([]);
	}

	const GetPayFormsWorkOrder = async () => {
		const payForms = await getPayForms(true);

		if (payForms?.length > 0) {
			const list = payForms?.map(form => {
				return {
					label: form.abbreviation + " - " + form.name,
					value: form.id_pay_method,
					abbreviation: form.abbreviation
				}
			});

			setListPayFormsWorkOrder(list);
		}
		else
			setListPayFormsWorkOrder([]);
	}

	const GetUseCfdis = async (tax_regime_id, type_person) => {
		const listuseCfdi = await getListUseOfCfdi(
			tax_regime_id, type_person
		);

		if (listuseCfdi?.length > 0) {
			const list = listuseCfdi?.map(use => {
				return {
					value: use?.id_use_cfdi,
					label: `${use?.code} ${use?.name}`,
					code: use?.code
				};
			});

			setListUseOfCfdis(list);
		}
		else setListUseOfCfdis([]);
	}

	const GetListNimsByReceptor = async (patient_id, client_id) => {
		const listNims = await getWorkOrderByPatientOrCompany(patient_id, client_id);

		if (listNims?.length > 0) {

			const list = listNims?.map(order => {
				return {
					value: order?.id_work_order,
					label: (
						<>
							<img alt="flask" className="img-fluid text-info mr-2" src={require("../../assets/images/flask_laboratory.svg")} style={{ width: "30px", height: "30px" }} />
							<b>{order?.nim}  /   {order?.name_branch}  /   {order?.date_work_order}  /   {order?.name_patient}</b>
						</>
					),
					baseOption: `${order?.nim}  /   ${order?.name_branch}  /   ${order?.date_work_order}  /   ${order?.name_patient}`
				}
			});

			setListFilterNims(list);
		}
	}

	const GetListTaxRegime = async (version, is_physical_person = null, is_moral_person = null) => {
		const listRegimes = await getTaxRegimeByTypePerson(version, is_physical_person, is_moral_person);

		if (listRegimes?.length > 0) {
			const list = listRegimes?.map(regime => {
				return {
					id_tax_regime: regime.id_tax_regime,
					name: `(${regime.code}) ${regime.name}`
				}
			});

			setListTaxRegimes(list);
		}
	}

	const GetBranches = async () => {
		const listBranches = await getListBranches();

		if (listBranches?.length > 0) {
			const list = listBranches?.map(branch => {
				return {
					value: branch.id_branch,
					label: branch.name
				}
			});

			setListBranches(list);
		}
	}

	const GetInvoiceById = async () => {
		setShowLoading(true);
		const MyInvoice = await GetInvoiceId(request_id_invoice);

		if (MyInvoice.code === 200) {
			const data = MyInvoice?.data;

			setFilterTypeReceptor(data?.typeClient);

			let updateFormInvoice = formInvoice;

			if (data?.typeClient === ReceptorInvoice.GENERAL_PUBLIC) {
				updateFormInvoice = {
					...updateFormInvoice,
					client_id: null,
					client_business_id: null,
					patient_id: null,
					type: ReceptorInvoice.GENERAL_PUBLIC,
					is_general_public: true,
					receptor_information: {
						tax_regime_id: null,
						use_cfdi_id: InformationGeneralPublicEnum.CODE_USE_CFDI,
						name_commercial_line: InformationGeneralPublicEnum.COMMERCIAL_LINE,
						code_fiscal_regime: InformationGeneralPublicEnum.CODE_FISCAL_REGIME_616,
						name_fiscal_regime: InformationGeneralPublicEnum.NAME_FISCAL_REGIME_616,
						rfc: InformationGeneralPublicEnum.RFC,
						expedition_place: '',
						type_person: TypePersonInvoiceEnum.FISICA,
						code_use_cfdi: InformationGeneralPublicEnum.CODE_USE_CFDI,
						name_use_cfdi: InformationGeneralPublicEnum.NAME_USE_CFDI,
					},
					use_cfdi_id: {
						value: InformationGeneralPublicEnum.CODE_USE_CFDI,
						label: InformationGeneralPublicEnum.NAME_USE_CFDI,
					},
					pay_method_id: {
						value: "PUE",
						label: "(PUE) PAGO EN UNA SOLA EXHIBICIÓN",
					},
					periodicity_id: {
						value: data?.globalInvoice?.codeOfPeriodicity,
						label: `${data?.globalInvoice?.codeOfPeriodicity} - ${data?.globalInvoice?.nameOfPeriodicity}`
					},
					month_id: {
						value: data?.globalInvoice?.codeOfMonth,
						label: `${data?.globalInvoice?.codeOfMonth} - ${data?.globalInvoice?.nameOfMonth}`
					},
					year_billing: {
						value: data?.globalInvoice?.codeOfYear,
						label: data?.globalInvoice?.codeOfYear
					},
					include_sells: data?.configurationConceptBody?.includeConceptSell ?? false,
					include_name_patient: false,
					include_detail_period: data?.configurationConceptBody?.includePeriod ?? false,
				};

				setFilterDateRanges({
					startDate: data?.dateRanges.startDate,
					endDate: data?.dateRanges.endDate,
				});

				GetPayFormsWorkOrder();
				GetBranches();
			}
			else if (data?.typeClient === ReceptorInvoice.PATIENT) {
				const getPatient = await getPatientById(data?.patientId);
				await GetListNimsByReceptor(data?.patientId);

				updateFormInvoice = {
					...updateFormInvoice,
					patient_id: data?.patientId,
					type: ReceptorInvoice.PATIENT,
					receptor_information: {
						tax_regime_id: getPatient?.id_tax_regime ?? null,
						use_cfdi_id: getPatient?.id_use_cfdi ?? null,
						name_commercial_line: getPatient?.business_name ?? "",
						code_fiscal_regime: getPatient?.code_tax_regime ?? "",
						name_fiscal_regime: getPatient?.tax_regime ?? "",
						rfc: getPatient?.rfc ?? "",
						expedition_place: getPatient?.cp ?? "",
						type_person: getPatient?.type_person === 1 ? TypePersonInvoiceEnum.FISICA : TypePersonInvoiceEnum.MORAL,
						code_use_cfdi: getPatient?.code_use_cfdi ?? "",
						name_use_cfdi: getPatient?.name_use_cfdi ?? "",
						email: getPatient?.email ?? "",
						name_receptor: getPatient?.name_patient ?? ''
					}
				}

				if (getPatient?.id_tax_regime !== null)
					await GetUseCfdis(getPatient.id_tax_regime, getPatient?.type_person === 1 ? true : false);
			}
			else if (data?.typeClient === ReceptorInvoice.COMPANY) {
				setSelectCompany({
					label: data?.nameReceptor,
					value: data?.clientId
				});

				const getList = await getCompanyBusinessNameByClientId(data?.clientId);

				const list = getList?.map(bussinessName => {
					return {
						"value": bussinessName.id_company_business_name,
						"label": bussinessName.business_name,
						"id_company": bussinessName.id_company,
						"tax_regime_id": bussinessName.id_tax_regime ?? null,
						"use_cfdi_id": null,
						"rfc": bussinessName.rfc ?? "",
						"address": bussinessName.address,
						"colony": bussinessName.colony,
						"cp": bussinessName.cp ?? "",
						"phone": bussinessName.phone,
						"email": bussinessName.email,
						"code_tax_regime_business_name": bussinessName.code_tax_regime,
						"name_tax_regime": bussinessName.name_tax_regime,
						"type_person": bussinessName.type_person === 2 ? TypePersonInvoiceEnum.MORAL : TypePersonInvoiceEnum.FISICA,
						"name_receptor": data?.nameReceptor ?? '',
					}
				});

				setListClientCompanyBUsinessName(list);

				const businessId = list.find(business => business.value === data?.clientBusinessId);
				setSelectCommercialNameCompany(businessId);

				updateFormInvoice = {
					...updateFormInvoice,
					client_id: data?.clientId,
					client_business_id: data?.clientBusinessId,
					type: ReceptorInvoice.COMPANY,
					receptor_information: {
						name_commercial_line: businessId?.label ?? "",
						tax_regime_id: businessId?.tax_regime_id,
						use_cfdi_id: businessId?.use_cfdi_id,
						code_fiscal_regime: businessId?.code_tax_regime_business_name ?? "",
						name_fiscal_regime: businessId?.name_tax_regime ?? "",
						rfc: businessId?.rfc ?? "",
						expedition_place: businessId?.cp ?? "",
						type_person: businessId?.type_person,
						code_use_cfdiL: businessId?.code_use_cfdi ?? null,
						name_use_cfdi: businessId?.name_use_cfdi ?? null,
						email: businessId?.email ?? ""
					}
				};

				if (businessId?.tax_regime_id !== null)
					GetUseCfdis(businessId?.tax_regime_id, businessId?.type_person === TypePersonInvoiceEnum.MORAL ? false : true);

				await GetListNimsByReceptor(
					null, data?.clientId
				);
			}

			const commercialLines = await getAllCommercialLines();

			const findCommercialLine = commercialLines.find(
				com => com.id_commercial_line === data?.commercialLineId
			);

			const listOrders = data?.listOrders?.map((order) => {
				return {
					invoice_detail_id: order?.invoiceDetailId,
					class_product_code_service: order?.productCodeService ?? '',
					code_unit: order?.codeUnit ?? '',
					id_class_product_service: null,
					id_work_order: order?.workOrderId,
					id_work_order_exam: order?.workOrderExamId,
					id_work_order_profile: order?.workOrderProfileId ?? null,
					id_exam: order?.examId ?? null,
					id_profile: order?.profileId ?? null,
					id_price: order?.priceId ?? null,
					id_profile_price: order?.profilePriceId ?? null,
					id_profile_curve_price: order?.curvePriceId ?? null,
					id_product_code_service: null,
					id_unit: null,
					nim: order?.nim ?? '',
					identification_code: order?.identificationCode ?? "",
					name: order?.description ?? '',
					price: order?.price ?? 0,
					price_urgent: order?.priceUrgent ?? 0,
					import: order?.import ?? 0,
					price_tax: order?.priceTax ?? 0,
					total_discount: order?.totalDiscount ?? 0,
					total: order?.total ?? 0,
					product_code_service: order?.productCodeService ?? "",
					name_unit: order?.nameUnit ?? '',
					name_patient: order?.namePatient ?? '',
					retention: order?.totalRetention ?? 0,
					is_deleted: false
				}
			});

			updateFormInvoice = {
				...updateFormInvoice,
				invoice_id: data?.invoiceId ?? null,
				commercial_line_id: data?.commercialLineId,
				issuing_information: findCommercialLine === null ? null : {
					name: findCommercialLine?.long_name,
					rfc: findCommercialLine?.rfc,
					cp: findCommercialLine?.cp,
					id_tax_regime: findCommercialLine?.id_tax_regime,
					code_tax_regime: findCommercialLine?.code_tax_regime,
					name_tax_regime: findCommercialLine?.name_tax_regime,
					type_person: findCommercialLine?.type_person ? TypePersonInvoiceEnum.MORAL : TypePersonInvoiceEnum.FISICA,
				},
				expedition_place: findCommercialLine?.cp,
				use_cfdi_id: {
					value: data?.configurationBody?.useCfdiId,
					label: `${data?.configurationBody?.codeOfUseCfdi} ${data?.configurationBody?.nameOfUseCfdi}`,
					code: data?.configurationBody?.codeOfUseCfdi
				},
				pay_method_id: {
					value: data?.configurationBody?.codePayMethod,
					label: data?.configurationBody?.codePayMethod === "PUE"
						? "(PUE) PAGO EN UNA SOLA EXHIBICIÓN"
						: "(PPD) PAGO EN PARCIALIDADES O DIFERIDO"
				},
				pay_form_id: {
					value: data?.configurationBody?.payFormId,
					label: `${data?.configurationBody?.codePayForm} ${data?.configurationBody?.namePayForm}`,
					code: data?.configurationBody?.codePayForm
				},
				payment_conditions: data?.paymentConditions ?? "",
				listOrders: listOrders,
				type_visualization: data?.typeVisualization,
				include_name_patient: data?.configurationConceptBody?.includeNamePatient,
				include_detail_period: data?.configurationConceptBody?.includePeriod,
				include_sells: data?.configurationConceptBody?.includeConceptSell,
				global_description: data?.configurationConceptBody?.globalDescription ?? 'ANALISIS CLINICOS',
				comments: data?.additionalComments ?? ''
			};

			setFormInvoice({
				...formInvoice,
				...updateFormInvoice
			});

			setViewInformationIssuing(true);
			setViewInformationReceptor(true);
			setIsEditInvoice(true);

			setShowLoading(false);
		}
		else if (MyInvoice.code === 401)
			validarSesion(history, MyInvoice.code, saveInvoice);
		else if (MyInvoice.code === 500) {
			createSweet("error", "error", "Factura", "Se perdio la conexión, con el servidor");
			setShowError(true);
		}
		else if (MyInvoice.code === 400) {
			createSweet("error", "error", "Factura", "No se llevo acabo la operación");
			setShowError(true);
		}
		else {
			createSweet("warning", "warning", "Facturaaaa", MyInvoice?.data?.msg);
			setShowError(true);
		}
		setShowLoading(false);

	}

	const handleChangeTyepeReceptor = (receptor) => {
		setFilterTypeReceptor(receptor);

		if (receptor === ReceptorInvoice.GENERAL_PUBLIC) {
			setFormInvoice({
				...formInvoice,
				client_id: null,
				client_business_id: null,
				patient_id: null,
				type: ReceptorInvoice.GENERAL_PUBLIC,
				is_general_public: true,
				receptor_information: {
					tax_regime_id: null,
					use_cfdi_id: InformationGeneralPublicEnum.CODE_USE_CFDI,
					name_commercial_line: InformationGeneralPublicEnum.COMMERCIAL_LINE,
					code_fiscal_regime: InformationGeneralPublicEnum.CODE_FISCAL_REGIME_616,
					name_fiscal_regime: InformationGeneralPublicEnum.NAME_FISCAL_REGIME_616,
					rfc: InformationGeneralPublicEnum.RFC,
					expedition_place: '',
					type_person: TypePersonInvoiceEnum.FISICA,
					code_use_cfdi: InformationGeneralPublicEnum.CODE_USE_CFDI,
					name_use_cfdi: InformationGeneralPublicEnum.NAME_USE_CFDI,
				},
				use_cfdi_id: {
					value: InformationGeneralPublicEnum.CODE_USE_CFDI,
					label: InformationGeneralPublicEnum.NAME_USE_CFDI,
				},
				pay_method_id: {
					value: "PUE",
					label: "(PUE) PAGO EN UNA SOLA EXHIBICIÓN",
				},
				// ? CLEAN
				invoice_id: null,
				commercial_line_id: null,
				issuing_information: null,
				branch_id: branchId,
				pay_form_id: null,
				periodicity_id: null,
				month_id: null,
				year_billing: {
					label: moment().format('YYYY'),
					value: moment().format('YYYY'),
				},
				expedition_place: null,
				payment_conditions: null,
				comments: "",
				include_name_patient: true,
				include_detail_period: false,
				include_sells: false,
				type_visualization: TypeVIsualizationEnum.DETAIL,
				listOrders: [],
				viewGeneral: {
					productCode: "",
					unitCode: "",
					nameUnit: "",
					numberIdentification: "",
					description: "",
					unitPrice: 0,
					import: 0,
					discount: 0,
					tax: 0,
					retention: 0,
					total: 0,
				},
				viewGroup: [],
			    global_description: ''
			});
			setOptionsDue({
				...optionsDue,
				subtotal: 0,
				discounts: 0,
				tax: 0,
				retentions: 0,
				total: 0
			})

			setViewInformationReceptor(true);
			GetPayFormsWorkOrder();
			GetBranches();
		}
		else {
			setFormInvoice({
				...formInvoice,
				client_id: null,
				client_business_id: null,
				patient_id: null,
				receptor_information: null,
				pay_method_id: null,
				pay_form_id: null,
				use_cfdi_id: null,
				is_general_public: false,
				// ? clean
				invoice_id: null,
				commercial_line_id: null,
				issuing_information: null,
				type: null,
				branch_id: branchId,
				periodicity_id: null,
				month_id: null,
				year_billing: {
					label: moment().format('YYYY'),
					value: moment().format('YYYY'),
				},
				expedition_place: null,
				payment_conditions: null,
				comments: "",
				include_name_patient: true,
				include_detail_period: false,
				include_sells: false,
				type_visualization: TypeVIsualizationEnum.DETAIL,
				listOrders: [],
				viewGeneral: {
					productCode: "",
					unitCode: "",
					nameUnit: "",
					numberIdentification: "",
					description: "",
					unitPrice: 0,
					import: 0,
					discount: 0,
					tax: 0,
					retention: 0,
					total: 0,
				},
				viewGroup: [],
				global_description: ''
			});

			setOptionsDue({
				...optionsDue,
				subtotal: 0,
				discounts: 0,
				tax: 0,
				retentions: 0,
				total: 0
			})

			setViewInformationReceptor(false);

			if (receptor === ReceptorInvoice.COMPANY)
				GetAllClients();
		}
	}

	const handleChangePatient = async (e) => {
		const patient_id = e.target.value;

		if (patient_id !== 0 && patient_id !== null && patient_id !== "" && patient_id !== "0") {
			const getPatient = await getPatientById(patient_id);
			await GetListNimsByReceptor(patient_id);

			if (getPatient?.id_patient !== undefined) {
				setFormInvoice({
					...formInvoice,
					patient_id: patient_id,
					type: ReceptorInvoice.PATIENT,
					receptor_information: {
						tax_regime_id: getPatient?.id_tax_regime ?? null,
						use_cfdi_id: getPatient?.id_use_cfdi ?? null,
						name_commercial_line: getPatient?.business_name ?? "",
						code_fiscal_regime: getPatient?.code_tax_regime ?? "",
						name_fiscal_regime: getPatient?.tax_regime ?? "",
						rfc: getPatient?.rfc ?? "",
						expedition_place: getPatient?.cp ?? "",
						type_person: getPatient?.type_person === 1 ? TypePersonInvoiceEnum.FISICA : TypePersonInvoiceEnum.MORAL,
						code_use_cfdi: getPatient?.code_use_cfdi ?? "",
						name_use_cfdi: getPatient?.name_use_cfdi ?? "",
						email: getPatient?.email ?? "",
						name_receptor: getPatient?.name_patient ?? ''
					}
				});

				if (getPatient?.id_tax_regime !== null)
					await GetUseCfdis(getPatient.id_tax_regime, getPatient?.type_person === 1 ? true : false);

				setViewInformationReceptor(true);
			}
			else {
				if (getPatient.code === 401) {
					validarSesion(history, getPatient.code, getPatientById);
				}
				else if (getPatient.code === 500) {
					createSweet("warning", "warning", "Obtener información del paciente", "Ocurrio un problema al obtener la información del paciente");
				}
				else {
					createSweet("warning", "warning", "Paciente", getPatient.data.msg);
				}
			}
		}
		else {
			setFormInvoice({
				...formInvoice,
				patient_id: null,
				receptor_information: null,
			});

			setViewInformationReceptor(false);
		}
	}

	const handleChangeClient = async (e) => {
		setSelectCompany(e);

		if (e !== null && e?.value !== undefined) {
			const getList = await getCompanyBusinessNameByClientId(e.value);

			if (getList?.length > 0) {
				const list = getList?.map(bussinessName => {
					return {
						"value": bussinessName.id_company_business_name,
						"label": bussinessName.business_name,
						"id_company": bussinessName.id_company,
						"tax_regime_id": bussinessName.id_tax_regime ?? null,
						"use_cfdi_id": null,
						"rfc": bussinessName.rfc ?? "",
						"address": bussinessName.address,
						"colony": bussinessName.colony,
						"cp": bussinessName.cp ?? "",
						"phone": bussinessName.phone,
						"email": bussinessName.email,
						"code_tax_regime_business_name": bussinessName.code_tax_regime,
						"name_tax_regime": bussinessName.name_tax_regime,
						"type_person": bussinessName.type_person === 2 ? TypePersonInvoiceEnum.MORAL : TypePersonInvoiceEnum.FISICA,
						"name_receptor": e?.label ?? '',
					}
				});

				setListClientCompanyBUsinessName(list);
			}
			else setListClientCompanyBUsinessName([]);

			setFormInvoice({
				...formInvoice,
				client_id: e?.value,
				client_business_id: null,
				receptor_information: null,
				type: ReceptorInvoice.COMPANY
			});

			setViewInformationReceptor(false);
		}
		else {
			setFormInvoice({
				...formInvoice,
				client_id: null,
				client_business_id: null,
				receptor_information: null,
			});

			setViewInformationReceptor(false);
		}
	}

	const handleChangeClientBusinessId = async (e) => {
		setSelectCommercialNameCompany(e);
		if (e !== null) {
			await GetListNimsByReceptor(
				null, formInvoice.client_id
			);

			setFormInvoice({
				...formInvoice,
				client_business_id: e.value,
				type: ReceptorInvoice.COMPANY,
				receptor_information: {
					name_commercial_line: e?.label ?? "",
					tax_regime_id: e.tax_regime_id,
					use_cfdi_id: e.use_cfdi_id,
					code_fiscal_regime: e?.code_tax_regime_business_name ?? "",
					name_fiscal_regime: e?.name_tax_regime ?? "",
					rfc: e?.rfc ?? "",
					expedition_place: e?.cp ?? "",
					type_person: e?.type_person,
					code_use_cfdiL: e?.code_use_cfdi ?? null,
					name_use_cfdi: e?.name_use_cfdi ?? null,
					email: e?.email ?? ""
				}
			});

			if (e?.tax_regime_id !== null)
				GetUseCfdis(e?.tax_regime_id, e?.type_person === TypePersonInvoiceEnum.MORAL ? false : true);

			setViewInformationReceptor(true);
		}
		else {
			setFormInvoice({
				...formInvoice,
				client_business_id: null,
				receptor_information: null,
			});

			setViewInformationReceptor(false);
		}
	}

	const handleViewFilters = () => setViewInformationReceptor(false);

	const handleChangeIssuing = (e) => {
		const issuing_information = e === null ? null : {
			name: e?.label,
			rfc: e?.rfc,
			cp: e?.cp,
			code_tax_regime: e?.code_tax_regime,
			name_tax_regime: e?.name_tax_regime,
			type_person: e?.type_person,
			commercial_line_id: e?.value
		};

		setFormInvoice({
			...formInvoice,
			commercial_line_id: e?.value ?? null,
			issuing_information: e === null ? null : {
				name: e?.label,
				rfc: e?.rfc,
				cp: e?.cp,
				code_tax_regime: e?.code_tax_regime,
				name_tax_regime: e?.name_tax_regime,
				type_person: e?.type_person
			},
			expedition_place: e?.cp
		});

		if (e !== null) {
			if (id_work_order_r !== undefined && parseInt(id_work_order_r) > 0) {
				handleChangeFilterNims({
					value: id_work_order_r,
				}, false, issuing_information);
			}

			setViewInformationIssuing(true);
		}
		else setViewInformationIssuing(false);
	}

	const handleChangeInput = (e, nameInput) => {
		if (nameInput === 'periodicity_id') {
			if (e?.value === "05")
				setListOfMonths(listOfMonthsByGroupEnum);
			else
				setListOfMonths(listOfMonthsEnum);			
		}		

		let updateInputs = {};

		if (nameInput === "include_name_patient" || nameInput === "include_detail_period" || nameInput === "include_sells") {
			updateInputs = {
				include_name_patient: (nameInput === "include_name_patient" ? e : false),
				include_detail_period: (nameInput === "include_detail_period" ? e : false),
				include_sells: (nameInput === "include_sells" ? e : false)
			};
		}
		else {
			updateInputs = {
				[nameInput]: e
			}
		}

		setFormInvoice({
			...formInvoice,
			...updateInputs
		});

		if (nameInput === "month_id")
			handleCalculateDates(e.value);
	}

	const handleCalculateDates = (codeOfMonth) => {
		let date_start = "";
		let date_end = "";
		
		// * FILTER BY MONTH
		if (formInvoice.periodicity_id?.value === "04") {
			date_start = `${formInvoice.year_billing?.value}-${formInvoice.month_id.value}-01`;
			date_end = moment(date_start).endOf('months').format("YYYY-MM-DD");

			setFilterDateRanges({
				startDate: date_start,
				endDate: date_end
			});
		}
		else if (formInvoice.periodicity_id?.value === "05") {
			switch (formInvoice?.month_id?.value) {
				case "13":
					date_start = `${formInvoice.year_billing?.value}-01-01`;
					date_end = moment(`${formInvoice.year_billing?.value}-02-01`).endOf('months').format("YYYY-MM-DD");

					setFilterDateRanges({
						startDate: date_start,
						endDate: date_end
					});
					break;
				case "14":
					date_start = `${formInvoice.year_billing?.value}-03-01`;
					date_end = moment(`${formInvoice.year_billing?.value}-04-01`).endOf('months').format("YYYY-MM-DD");

					setFilterDateRanges({
						startDate: date_start,
						endDate: date_end
					});
					break;
				case "15":
					date_start = `${formInvoice.year_billing?.value}-05-01`;
					date_end = moment(`${formInvoice.year_billing?.value}-06-01`).endOf('months').format("YYYY-MM-DD");

					setFilterDateRanges({
						startDate: date_start,
						endDate: date_end
					});
					break;
				case "16":
					date_start = `${formInvoice.year_billing?.value}-07-01`;
					date_end = moment(`${formInvoice.year_billing?.value}-08-01`).endOf('months').format("YYYY-MM-DD");

					setFilterDateRanges({
						startDate: date_start,
						endDate: date_end
					});
					break;
				case "17":
					date_start = `${formInvoice.year_billing?.value}-09-01`;
					date_end = moment(`${formInvoice.year_billing?.value}-10-01`).endOf('months').format("YYYY-MM-DD");

					setFilterDateRanges({
						startDate: date_start,
						endDate: date_end
					});
					break;
				case "18":
					date_start = `${formInvoice.year_billing?.value}-11-01`;
					date_end = moment(`${formInvoice.year_billing?.value}-12-01`).endOf('months').format("YYYY-MM-DD");

					setFilterDateRanges({
						startDate: date_start,
						endDate: date_end
					});
					break;

				default:
					break;
			}
		}
		else {
			date_start = `${formInvoice.year_billing?.value}-${codeOfMonth}-01`;

			setFilterDateRanges({
				startDate: date_start,
				endDate: date_start
			});
		}
	}

	const handleChangeFilterNims = async (e, is_temp_patient = false, issuing_information = null) => {
		if (issuing_information === null && formInvoice?.issuing_information?.type_person === undefined) {
			createSweet("warning", "warning", "Emisor, razón social", "Selecciona un emisor");
			return;
		}

		if (e?.value) {
		   setIsLoadingWorkOrders(true);	
		} else  setIsLoadingWorkOrders(false);	

		const detailWorkOrder = await getDetailWorkOrderByInvoice(e.value, issuing_information !== null ? issuing_information.type_person : formInvoice.issuing_information.type_person,
			formInvoice.type === ReceptorInvoice.COMPANY ? formInvoice.client_id : null
		);

		if (detailWorkOrder?.length > 0) {
			if (!is_temp_patient) {
				let listTmpNims = listFilterNims;

				if (e.value !== 0) {
					listTmpNims.map(order => {
						if (e.value === order.value)
							order.isDisabled = true;

						return order;
					});
				}

				const listOrders = formInvoice.listOrders;

				if (listOrders?.find(order => order.id_work_order === e.value) !== undefined) {
					createSweet('warning', 'warning', 'El NIM seleccionado ya se encuentra seleccionado');
					setIsLoadingWorkOrders(false);
					setListFilterNims([...listTmpNims]);
					return;
				}

				listOrders.push(...detailWorkOrder);

				setFormInvoice({
					...formInvoice,
					commercial_line_id: issuing_information !== null ? issuing_information.commercial_line_id : formInvoice.commercial_line_id,
					issuing_information: issuing_information !== null ? issuing_information : formInvoice.issuing_information,
					listOrders: [...listOrders]
				});

				setListFilterNims([...listTmpNims]);
			}
			else {
				setIsLoadingWorkOrders(false);
				return detailWorkOrder;
			}
		}
		else if (detailWorkOrder?.code === 401) {
			validarSesion(history, detailWorkOrder.code, handleChangeFilterNims);
		}
		else if (detailWorkOrder?.code === 500) {
			createSweet("warning", "warning", "Obtener información del paciente", "Ocurrio un problema al obtener la información del paciente");
		}
		else {
			createSweet("warning", "warning", "Orden", detailWorkOrder.msg);
		}

		setIsLoadingWorkOrders(false);
	}

	const handleUpdateFiscalInformation = async () => {
		if (formInvoice.type === ReceptorInvoice.PATIENT) {
			setFormPatientFiscalInformation({
				business_name_patient: formInvoice.receptor_information?.name_commercial_line,
				code_tax_regime: formInvoice.receptor_information?.code_fiscal_regime,
				cp_patient: formInvoice.receptor_information?.expedition_place,
				email_patient: formInvoice.receptor_information?.email,
				name_patient: formInvoice.receptor_information?.name_receptor,
				rfc_patient: formInvoice.receptor_information?.rfc,
				tax_regime_id_patient: formInvoice.receptor_information?.tax_regime_id,
				type_person_patient: formInvoice.receptor_information?.type_person
			});
			setShowUpdateFiscalInformationPatient(true);
			await GetListTaxRegime("4.0", formInvoice.receptor_information?.type_person === TypePersonInvoiceEnum.FISICA ? true : null, formInvoice.receptor_information?.type_person === TypePersonInvoiceEnum.MORAL ? true : null);
		}
		else if (formInvoice.type === ReceptorInvoice.COMPANY) {
			setFormCompanyFiscalInformation({
				business_name_client: formInvoice.receptor_information?.name_commercial_line,
				code_tax_regime_client: formInvoice.receptor_information?.code_fiscal_regime,
				cp_client: formInvoice.receptor_information?.expedition_place,
				email_client: formInvoice.receptor_information?.email,
				rfc_client: formInvoice.receptor_information?.rfc,
				tax_regime_id_client: formInvoice.receptor_information?.tax_regime_id,
				type_person_client: formInvoice.receptor_information?.type_person
			});
			setShowUpdaetFiscalInformationClient(true);
			await GetListTaxRegime("4.0", formInvoice.receptor_information?.type_person === TypePersonInvoiceEnum.FISICA ? true : null, formInvoice.receptor_information?.type_person === TypePersonInvoiceEnum.MORAL ? true : null);
		}
	}

	const handleToggleModal = (type) => {
		if (type === "PATIENT")
			setShowUpdateFiscalInformationPatient(!showUpdateFiscalInformationPatient);
		else if (type === "CLIENT")
			setShowUpdaetFiscalInformationClient(!showUpdaetFiscalInformationClient);
		else if (type === "EXTRA_PATIENT")
			setShowExtraPatient(!showExtraPatient);
	}

	const handleChangeInputEditPatient = async (e) => {
		switch (e?.target?.name) {
			case "business_name_patient":
				setFormPatientFiscalInformation({
					...formPatientFiscalInformation,
					business_name_patient: e?.target?.value
				});
				break;
			case "rfc_patient":
				setFormPatientFiscalInformation({
					...formPatientFiscalInformation,
					rfc_patient: e?.target?.value
				});
				break;
			case "type_person_patient":
				setFormPatientFiscalInformation({
					...formPatientFiscalInformation,
					type_person_patient: e?.target?.value === "1" ? TypePersonInvoiceEnum.FISICA : TypePersonInvoiceEnum.MORAL,
					tax_regime_id_patient: 0,
				});

				await GetListTaxRegime("4.0", e?.target?.value === "1" ? true : null, e?.target?.value === "2" ? true : null);
				break;
			case "id_tax_regime_patient":
				setFormPatientFiscalInformation({
					...formPatientFiscalInformation,
					tax_regime_id_patient: e?.target?.value
				});
				break;
			case "cp_patient":
				setFormPatientFiscalInformation({
					...formPatientFiscalInformation,
					cp_patient: e?.target?.value
				});
				break;

			default:
				setFormPatientFiscalInformation({
					...formPatientFiscalInformation,
					email_patient: e?.target?.value
				});
				break;
		}
	}

	const handleChangeInputEditClient = async (e) => {
		switch (e?.target?.name) {
			case "business_name":
				setFormCompanyFiscalInformation({
					...formCompanyFiscalInformation,
					business_name_client: e?.target?.value
				});
				break;
			case "rfc_business_name":
				setFormCompanyFiscalInformation({
					...formCompanyFiscalInformation,
					rfc_client: e?.target?.value
				});
				break;
			case "id_tax_regime_business_name":
				setFormCompanyFiscalInformation({
					...formPatientFiscalInformation,
					tax_regime_id_client: e?.target?.value
				});
				break;
			case "cp_business_name":
				setFormCompanyFiscalInformation({
					...formCompanyFiscalInformation,
					cp_client: e?.target?.value
				});
				break;

			default:
				setFormCompanyFiscalInformation({
					...formCompanyFiscalInformation,
					email_client: e?.target?.value
				});
				break;
		}
	}

	const handleAddNewWorkOrder = () => {
		handleToggleModal("EXTRA_PATIENT");
	}

	const handleChangeAddNewWorkOrder = async (e) => {
		setSearchValueInput(e);

		const workOrderId = e?.target?.value ?? null;

		let listWOrdersTemp = listWorkOrdersTemp;

		if (workOrderId !== null) {
			if (listWorkOrdersTemp?.find(order => order.id_work_order === workOrderId) !== undefined) {
				createSweet('warning', 'warning', 'El NIM seleccionado ya se encuentra seleccionado');
				return;
			}

			const detailOrder = await handleChangeFilterNims({ value: workOrderId }, true);

			listWOrdersTemp.push(...detailOrder);

			setListWorkOrdersTemp([...listWOrdersTemp]);
		}
	}

	const handleConfirmExtraPatients = () => {
		if (listWorkOrdersTemp?.length === 0)
			handleToggleModal("EXTRA_PATIENT");
		else {
			let listTmpNims = listFilterNims;

			listTmpNims.map(order => {
				if (listWorkOrdersTemp?.find(order => order?.id_work_order === order.value) !== undefined)
					order.isDisabled = true;
				return order;
			});

			const listOrders = formInvoice.listOrders;
			listOrders.push(...listWorkOrdersTemp);

			setFormInvoice({
				...formInvoice,
				listOrders: [...listOrders]
			});

			setListFilterNims([...listTmpNims]);

			handleToggleModal("EXTRA_PATIENT");
			setListWorkOrdersTemp([]);

		}
	}

	const handleFilterDates = async (event, picker) => {
		setIsLoadingWorkOrders(true);

		setFilterDateRanges({
			...filterDateRanges,
			startDate: picker.startDate?.format("YYYY-MM-DD"),
			endDate: picker.endDate?.format("YYYY-MM-DD")
		});

		const detailWorkOrder = await getDetailWorkOrdersByDateRange(picker.startDate?.format("YYYY-MM-DD"), picker.endDate?.format("YYYY-MM-DD"), formInvoice.issuing_information.type_person,
			formInvoice.type === ReceptorInvoice.COMPANY ? formInvoice.client_id : null
		);

		if (detailWorkOrder?.length === 0)
			createSweet("warning", "warning", "Orden", `No se encontraron resultados en el rango seleccionado`);
		else if (detailWorkOrder?.length > 0) {
			const listOrders = formInvoice.listOrders;

			detailWorkOrder.forEach(order => {
				if (!listOrders?.some(list => list?.id_work_order_exam === order?.id_work_order_exam)) {
					listOrders.push(order);
				}
			});

			setFormInvoice({
				...formInvoice,
				listOrders: [...listOrders]
			});
		}
		else if (detailWorkOrder?.code === 401) {
			validarSesion(history, detailWorkOrder.code, handleFilterDates);
		}
		else if (detailWorkOrder?.code === 500) {
			createSweet("warning", "warning", "Obtener información del paciente", "Ocurrio un problema al obtener la información del paciente");
		}
		else {
			createSweet("warning", "warning", "Orden", detailWorkOrder.msg);
		}
		setIsLoadingWorkOrders(false);
	}

	const handleFilterByGeneralPublic = async () => {
		setIsLoadingWorkOrders(true);

		const detailWorkOrder = await getDetailWorkOrdersByGeneralPublic(formInvoice.periodicity_id?.value, filterDateRanges.startDate, filterDateRanges.endDate,
			filterByBranch?.value ?? null,
			filterByPayMethod?.value ?? null
		);

		if (detailWorkOrder?.length === 0)
			createSweet("warning", "warning", "Orden", `No se encontraron resultados en el rango seleccionado`);
		else if (detailWorkOrder?.length > 0) {
			const listOrders = [];

			detailWorkOrder.forEach(order => {
				if (!listOrders?.some(list => list?.id_work_order === order?.id_work_order)) {
					listOrders.push(order);
				}
			});

			setFormInvoice({
				...formInvoice,
				listOrders: [...listOrders]
			});
		}
		else if (detailWorkOrder?.code === 401) {
			validarSesion(history, detailWorkOrder.code, handleFilterDates);
			setFormInvoice({
				...formInvoice,
				listOrders: []
			});
		}
		else if (detailWorkOrder?.code === 500) {
			createSweet("warning", "warning", "Obtener información del paciente", "Ocurrio un problema al obtener la información del paciente");
			setFormInvoice({
				...formInvoice,
				listOrders: []
			});
		}
		else {
			createSweet("warning", "warning", "Orden", detailWorkOrder.msg);
			setFormInvoice({
				...formInvoice,
				listOrders: []
			});
		}
		setIsLoadingWorkOrders(false);
	}

	const handleChangeTypeVisualization = (e, nameInput) => {
		handleChangeInput(e, nameInput);
	}

	const handleDeleteTest = (id_exam = null, id_profile = null, id_work_order_exam = null, id_work_order_profile = null,_id_workOreder = null, _valid = false) => {

		let listTest = [];

		if (id_exam !== null && id_work_order_exam !== null) {
			listTest = formInvoice?.listOrders?.map(test => {
				if (test?.id_exam === id_exam && test?.id_work_order_exam === id_work_order_exam)
					return {
						...test,
						is_deleted: true
					};
				else
					return test;
			});
		}
		else if (id_profile !== null && id_work_order_profile !== null) {
			listTest = formInvoice?.listOrders?.map(test => {
				if (test?.id_profile === id_profile && test?.id_work_order_profile === id_work_order_profile)
					return {
						...test,
						is_deleted: true
					};
				else
					return test;
			});
		}

		if (_valid) {
			listTest = formInvoice?.listOrders?.map(test => {
				if (test?.id_work_order === _id_workOreder)
					return {
						...test,
						is_deleted: true
					};
				else
					return test;
			});
			
		}

		setFormInvoice({
			...formInvoice,
			listOrders: [...listTest]
		});
	}

	const typeVisualizationTable = () => {
		if (formInvoice.type_visualization === TypeVIsualizationEnum.GLOBAL) {
			if (formInvoice?.listOrders?.length === 0)
				alert('You most select one option');
			else {
				setFormInvoice((prevState) => {
					let subtotal = 0;
					let discounts = 0;
					let taxes = 0;
					let retentions = 0;
					let total = 0;

					prevState.listOrders.forEach((order) => {
						if (!order?.is_deleted) {
							subtotal += order.import;
							discounts += order?.total_discount;
							taxes += order?.price_tax;
							retentions += order?.retention;
							total += order.total;
						}
					});

					return {
						...prevState,
						viewGeneral: {
							productCode: "85121801",
							unitCode: "E48",
							nameUnit: "UNIDAD DE SERVICIO",
							numberIdentification: "ESTUDIOS-LABORATORIO",
							description: "ANALISIS CLINICOS",
							unitPrice: subtotal,
							import: subtotal,
							discount: discounts,
							tax: taxes,
							retention: retentions,
							total,
						}
					}
				});
			}
		}
		else if (formInvoice.type_visualization === TypeVIsualizationEnum.TEST) {
			let aggroupTest = [];

			formInvoice.listOrders.forEach(test => {
				if (!test?.is_deleted) {
					if (test?.id_exam !== null) {
						const findExam = aggroupTest?.find(aggroup => aggroup?.id_exam === test?.id_exam);

						if (findExam !== undefined) {
							findExam.quantity += 1;
							// findExam.price += test?.price;
							// findExam.price_urgent += test?.price_urgent;
							findExam.import += test?.import;
							findExam.price_tax += test?.price_tax;
							findExam.total_discount += test?.total_discount;
							findExam.total += test?.total;
							findExam.retention += test?.retention;
							findExam.listWorkOrderExams.push(
								{
									InvoiceDetailId: test?.invoice_detail_id ?? null,
									WorkOrdeerId: test?.id_work_order ?? null,
									WorkOrderExamId: test?.id_work_order_exam ?? null,
									WorkOrderProfileId: null,
									PriceId: test?.id_price ?? null,
									ProfilePriceId: null,
									CurvePriceId: null,
									Quantity: 1,
									Total: test?.total,
									EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (test?.total_retentions ?? 0) > 0 ? true : false : (test?.retention ?? 0) > 0 ? true : false,
									EnableTax: (test?.price_tax ?? 0) > 0 ? true : false,
								}
							);
						}
						else
							aggroupTest.push({
								id_exam: test?.id_exam,
								id_profile: null,
								name_test: test?.name ?? '',
								quantity: 1,
								price: test?.price,
								price_urgent: test?.price_urgent,
								import: test?.import,
								price_tax: test?.price_tax,
								total_discount: test?.total_discount,
								total: test?.total,
								class_product_code_service: test?.class_product_code_service,
								code_unit: test?.code_unit,
								name_unit: test?.name_unit,
								retention: test?.retention,
								identification_code: 'ESTUDIOS-LABORATORIO',
								listWorkOrderExams: [
									{
										InvoiceDetailId: test?.invoice_detail_id ?? null,
										WorkOrdeerId: test?.id_work_order ?? null,
										WorkOrderExamId: test?.id_work_order_exam ?? null,
										WorkOrderProfileId: null,
										PriceId: test?.id_price ?? null,
										ProfilePriceId: null,
										CurvePriceId: null,
										Quantity: 1,
										Total: test?.total,
										EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (test?.total_retentions ?? 0) > 0 ? true : false : (test?.retention ?? 0) > 0 ? true : false,
										EnableTax: (test?.price_tax ?? 0) > 0 ? true : false,
									}
								]
							});

					}
					else if (test?.id_profile !== null) {
						const findProfile = aggroupTest?.find(aggroup => aggroup?.id_profile === test?.id_profile);

						if (findProfile !== undefined) {
							findProfile.quantity += 1;
							// findProfile.price += test?.price;
							// findProfile.price_urgent += test?.price_urgent;
							findProfile.import += test?.import;
							findProfile.price_tax += test?.price_tax;
							findProfile.total_discount += test?.total_discount;
							findProfile.total += test?.total;
							findProfile.retention += test?.retention;
							findProfile.listWorkOrderProfiles.push(
								{
									InvoiceDetailId: test?.invoice_detail_id ?? null,
									WorkOrderId: test?.id_work_order ?? null,
									WorkOrderExamId: null,
									WorkOrderProfileId: test?.id_work_order_profile,
									PriceId: null,
									ProfilePriceId: test?.id_profile_price,
									CurvePriceId: test?.id_profile_curve_price ?? null,
									Quantity: 1,
									Total: test?.total,
									EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (test?.total_retentions ?? 0) > 0 ? true : false : (test?.retention ?? 0) > 0 ? true : false,
									EnableTax: (test?.price_tax ?? 0) > 0 ? true : false,
								}
							);
						}
						else
							aggroupTest.push({
								id_exam: null,
								id_profile: test?.id_profile,
								name_test: test?.name ?? '',
								quantity: 1,
								price: test?.price,
								price_urgent: test?.price_urgent,
								import: test?.import,
								price_tax: test?.price_tax,
								total_discount: test?.total_discount,
								total: test?.total,
								class_product_code_service: test?.class_product_code_service,
								code_unit: test?.code_unit,
								name_unit: test?.name_unit,
								retention: test?.retention,
								identification_code: 'ESTUDIOS-LABORATORIO',
								listWorkOrderProfiles: [
									{
										InvoiceDetailId: test?.invoice_detail_id ?? null,
										WorkOrderId: test?.id_work_order ?? null,
										WorkOrderExamId: null,
										WorkOrderProfileId: test?.id_work_order_profile,
										PriceId: null,
										ProfilePriceId: test?.id_profile_price,
										CurvePriceId: test?.id_profile_curve_price ?? null,
										Quantity: 1,
										Total: test?.total,
										EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (test?.total_retentions ?? 0) > 0 ? true : false : (test?.retention ?? 0) > 0 ? true : false,
										EnableTax: (test?.price_tax ?? 0) > 0 ? true : false,
									}
								],
							})
					}
				}
			});

			setFormInvoice({
				...formInvoice,
				viewGroup: aggroupTest
			});
		}
		else if (formInvoice.type_visualization === TypeVIsualizationEnum.DETAIL) {
			setFormInvoice((prevState) => {
				return {
					...prevState,
					viewGeneral: {
						productCode: "",
						unitCode: "",
						nameUnit: "",
						numberIdentification: "",
						description: "",
						unitPrice: 0,
						import: 0,
						discount: 0,
						tax: 0,
						retention: 0,
						total: 0,
					}
				}
			})
		}
	}

	const handlePreviewInvoice = () => {
		if (formInvoice.listOrders.length === 0) {
			createSweet("warning", "warning", "Previsualización", "Selecciona al menos una orden");
			return;
		}

		setLoadingPreview(!loadingPreview);
		setShowPreviewInvoice(!showPreviewInvoice);

		setTimeout(() => {
			setLoadingPreview(false);
		}, 3000);
	}

	const handleLoadingPreInvoice = () => {
		setShowLoading(!showLoading);
	}

	const saveUpdateFiscalInformation = async (e) => {
		e.preventDefault();

		let newValidations = {};
		const validations = ValidateFiscalFormPatient(formPatientFiscalInformation, newValidations)
		if (validations.statusValidation) {
			setValidationsPatient(validations.newValidations);
			return;
		}

		const response = await updateFiscalInformationPatient({
			patient_id: formInvoice.patient_id,
			business_name: formPatientFiscalInformation.business_name_patient,
			cp: formPatientFiscalInformation.cp_patient,
			email: formPatientFiscalInformation.email_patient,
			id_tax_regime: formPatientFiscalInformation.tax_regime_id_patient,
			rfc: formPatientFiscalInformation.rfc_patient,
			type_person: formPatientFiscalInformation.type_person_patient
		});

		if (response.code === 200) {
			setFormPatientFiscalInformation({
				business_name_patient: response.data.business_name ?? '',
				code_tax_regime: response.data?.code_tax_regime ?? '',
				cp_patient: response.data?.postal_code ?? '',
				email_patient: response.data?.email,
				name_patient: response.data?.full_name ?? '',
				rfc_patient: response.data?.rfc ?? '',
				tax_regime_id_patient: response.data?.id_tax_regime,
				type_person_patient: response.data?.type_person === 1 ? TypePersonInvoiceEnum.FISICA : TypePersonInvoiceEnum.MORAL,
			});

			setFormInvoice({
				...formInvoice,
				receptor_information: {
					tax_regime_id: response.data?.id_tax_regime ?? null,
					use_cfdi_id: response.data?.id_use_cfdi ?? null,
					name_commercial_line: response.data?.business_name ?? "",
					code_fiscal_regime: response.data?.code_tax_regime ?? "",
					name_fiscal_regime: response.data?.name_tax_regime ?? "",
					rfc: response.data?.rfc ?? "",
					expedition_place: response.data?.postal_code ?? "",
					type_person: response.data?.type_person === 1 ? TypePersonInvoiceEnum.FISICA : TypePersonInvoiceEnum.MORAL,
					code_use_cfdi: response.data?.code_use_cfdi ?? "",
					name_use_cfdi: response.data?.name_use_cfdi ?? "",
					email: response.data?.email ?? "",
					name_receptor: response.data?.full_name ?? ''
				}
			});

			createSweet("create", "info", "Exito!", "Paciente actualizado");
			handleToggleModal("PATIENT");
			await GetUseCfdis(response.data?.id_tax_regime, response.data?.type_person === 1 ? true : false);
		}
		else if (response.code === 401)
			validarSesion(history, response.code, saveUpdateFiscalInformation);
		else if (response.code === 500)
			createSweet("error", "error", "Datos fiscales", "Se perdio la conexión, con el servidor");
		else if (response.code === 400)
			createSweet("error", "error", "Datos fiscales", "No se llevo acabo la operación");
		else
			createSweet("warning", "warning", "Datos fiscales", response.msg);
	}

	const saveUpdateClientInformation = async (e) => {
		e.preventDefault();

		let newValidations = {};
		const validations = ValidateFiscalFormClient(formCompanyFiscalInformation, newValidations);

		if (validations.statusValidation) {
			setValidationsBusinessName(validations.newValidations);
			return;
		}

		const response = await updateFiscalInformationClient({
			id_company_business_name: formInvoice.client_business_id,
			business_name: formCompanyFiscalInformation.business_name_client,
			cp: formCompanyFiscalInformation.cp_client,
			email: formCompanyFiscalInformation.email_client,
			id_tax_regime: formCompanyFiscalInformation.tax_regime_id_client,
			rfc: formCompanyFiscalInformation.rfc_client,
		});

		if (response.code === 200) {
			setFormInvoice({
				...formInvoice,
				receptor_information: {
					tax_regime_id: response.data?.tax_regime_id ?? null,
					use_cfdi_id: null,
					name_commercial_line: response.data?.business_name ?? "",
					code_fiscal_regime: response.data?.code_fiscal_regime ?? "",
					name_fiscal_regime: response.data?.fiscal_regime_name ?? "",
					rfc: response.data?.rfc ?? "",
					expedition_place: response.data?.expedition_place ?? "",
					type_person: response.data?.type_person === 1 ? TypePersonInvoiceEnum.FISICA : TypePersonInvoiceEnum.MORAL,
					code_use_cfdi: "",
					name_use_cfdi: "",
					email: response.data?.email ?? "",
					name_receptor: ''
				}
			});

			createSweet("create", "info", "Exito!", "Cliente actualizado");
			handleToggleModal("CLIENT");
		}
		else if (response.code === 401)
			validarSesion(history, response.code, saveUpdateClientInformation);
		else if (response.code === 500)
			createSweet("error", "error", "Datos fiscales", "Se perdio la conexión, con el servidor");
		else if (response.code === 400)
			createSweet("error", "error", "Datos fiscales", "No se llevo acabo la operación");
		else
			createSweet("warning", "warning", "Datos fiscales", response.msg);
	}

	const saveInvoice = async (stamp_invoice = false) => {
		;
		handleLoadingPreInvoice();
		const body = {
			InvoiceId: formInvoice.invoice_id,
			IsPreview: stamp_invoice,
			TypePersonIssuing: formInvoice.issuing_information.type_person,
			TypePersonReceptor: formInvoice.receptor_information.type_person,
			CommercialLineId: formInvoice.commercial_line_id,
			BranchId: formInvoice.branch_id,
			ClientId: formInvoice.client_id ?? null,
			ClientBusinessId: formInvoice.client_business_id ?? null,
			PatientId: formInvoice.patient_id ?? null,
			UseCfdiId: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? 0 : formInvoice.use_cfdi_id?.value ?? null,
			CodeOfUseCfdi: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? formInvoice.receptor_information.code_use_cfdi : formInvoice.use_cfdi_id?.code ?? null,

			PayFormId: formInvoice.pay_form_id?.value ?? null,
			CodePayForm: formInvoice.pay_form_id?.code ?? null,
			TypeDocument: 'I',
			CodePayMethod: formInvoice.pay_method_id?.value ?? null,
			ConfigurationConcepts: {
				IncludeNamePatient: formInvoice.include_name_patient,
				IncludePeriod: formInvoice.include_detail_period,
				IncludeConceptSell: formInvoice.include_sells,
				GlobalDescription: formInvoice.global_description ?? ''
			},
			DateRanges: filterDateRanges
				? {
					StartDate: filterDateRanges.startDate,
					EndDate: filterDateRanges.endDate
				}
				: null,
			ExpeditionPlace: formInvoice.expedition_place,
			GlobalInvoice: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? {
				CodeOfPeriodicity: formInvoice.periodicity_id?.value ?? null,
				CodeOfMonth: formInvoice.month_id?.value ?? null,
				CodeOfYear: formInvoice.year_billing?.value ?? null
			} : null,
			IsGeneralPublic: formInvoice.is_general_public,
			PaymentConditions: formInvoice.payment_conditions,
			AdditionalComments: formInvoice.comments ?? '',
			TypeClient: formInvoice.type,
			TypeVisualization: formInvoice.type_visualization,
			ListOrders: formInvoice.type_visualization === TypeVIsualizationEnum.GLOBAL ? formInvoice.listOrders?.filter(order => !order.is_deleted)?.map(order => {
				return {
					InvoiceDetailId: order?.invoice_detail_id ?? null,
					WorkOrderId: order?.id_work_order ?? null,
					WorkOrderExamId: order?.id_work_order_exam ?? null,
					WorkOrderProfileId: order?.id_work_order_profile ?? null,
					PriceId: order?.id_price ?? null,
					ProfilePriceId: order?.id_profile_price ?? null,
					CurvePriceId: order?.id_profile_curve_price ?? null,
					Quantity: 1,
					Total: order?.total ?? 0,
					EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (order?.total_retentions ?? 0) > 0 ? true : false : (order?.retention ?? 0) > 0 ? true : false,
					EnableTax: (order?.price_tax ?? 0) > 0 ? true : false,
				}
			}) : [],
			ViewDetail: formInvoice.type_visualization === TypeVIsualizationEnum.DETAIL ? formInvoice.listOrders?.filter(order => !order.is_deleted)?.map(order => {
				return {
					ProductCode: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? order?.product_code_service ?? null : order?.class_product_code_service ?? null,
					IdentificationCode: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? order?.nim ?? null : order?.identification_code,
					Description: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? order?.name_patient ?? null : order?.name,
					UnitCode: order?.code_unit,
					Quantity: 1,
					Total: order?.total ?? 0,
					EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (order?.total_retentions ?? 0) > 0 ? true : false : (order?.retention ?? 0) > 0 ? true : false,
					EnableTax: (order?.price_tax ?? 0) > 0 ? true : false,
					InvoiceDetailId: order?.invoice_detail_id ?? null,
					WorkOrderId: order?.id_work_order ?? null,
					WorkOrderExamId: order?.id_work_order_exam ?? null,
					WorkOrderProfileId: order?.id_work_order_profile ?? null,
					PriceId: order?.id_price ?? null,
					ProfilePriceId: order?.id_profile_price ?? null,
					CurvePriceId: order?.id_profile_curve_price ?? null,
					ListWorkOrderProfiles: [],
					ListWorkOrderExams: []
				}
			}) : [],
			ViewGeneral: formInvoice.type_visualization === TypeVIsualizationEnum.GLOBAL ? {
				ProductCode: formInvoice.viewGeneral?.productCode,
				IdentificationCode: formInvoice.viewGeneral?.numberIdentification,
				Description: formInvoice.viewGeneral?.description,
				UnitCode: formInvoice.viewGeneral?.unitCode,
				Quantity: 1,
				Total: formInvoice.viewGeneral?.total ?? 0,
				EnableRetention: (formInvoice.viewGeneral?.retention ?? 0) > 0 ? true : false,
				EnableTax: (formInvoice.viewGeneral?.tax ?? 0) > 0 ? true : false,
				ListWorkOrderProfiles: [],
				ListWorkOrderExams: []
			} : null,
			ViewGroup: formInvoice.type_visualization === TypeVIsualizationEnum.TEST ? formInvoice.viewGroup?.map(order => {
				return {
					ProductCode: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? order?.product_code_service ?? null : order?.class_product_code_service,
					IdentificationCode: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? order?.nim ?? null : order?.identification_code,
					Description: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? order?.name_patient ?? null : order?.name_test,
					UnitCode: order?.code_unit,
					Quantity: order?.quantity,
					Total: order?.total ?? 0,
					EnableRetention: formInvoice.type === ReceptorInvoice.GENERAL_PUBLIC ? (order?.total_retentions ?? 0) > 0 ? true : false : (order?.retention ?? 0) > 0 ? true : false,
					EnableTax: (order?.price_tax ?? 0) > 0 ? true : false,
					ListWorkOrderExams: order?.listWorkOrderExams ?? [],
					ListWorkOrderProfiles: order?.listWorkOrderProfiles ?? [],
				}
			}) : [],
		}

		const sendStamp = await sendStampIngress(body);

		if (sendStamp.code === 200) {
			createSweet("create", "info", "Exito!", "Se genero la factura con exito!");

			if (formInvoice.invoice_id !== null)
				setTimeout(() => {
					window.location.href = '../../../facturacion/Emitidos';
				}, 2000);
			else
				setTimeout(() => {
					window.location.replace('../invoice/service');
				}, 2000);
		}
		else if (sendStamp.code === 401)
			validarSesion(history, sendStamp.code, saveInvoice);
		else if (sendStamp.code === 500)
			createSweet("error", "error", "Factura", "Se perdio la conexión, con el servidor");
		else if (sendStamp.code === 400)
			createSweet("error", "error", "Factura", "No se llevo acabo la operación");
		else
			createSweet("warning", "warning", "Factura", sendStamp.msg);

		setShowLoading(false);
	}

	



	return {
		showUpdateFiscalInformationPatient, showUpdaetFiscalInformationClient, showExtraPatient, showPreviewInvoice,

		setShowCardFilterByDate, showCardFilterByDate, isEditInvoice, showError,

		filterByBranch, setFilterByBranch,

		filterByPayMethod, setFilterByPayMethod,

		viewInformationReceptor, viewInformationIssuing,

		listCommercialLines, listClients, listClientCompanyBUsinessName, listPayMethods,
		listPayForms, listUseOfCfdis, listFilterNims, listTaxRegimes, listOfPeriodicity, listOfMonths,
		listBranches, listPayFormsWorkOrder,

		listWorkOrdersTemp,

		formInvoice, formPatientFiscalInformation, formCompanyFiscalInformation,

		filterTypeReceptor, searchPatientState, searchExtraPatient, searchValueInput,

		selectCompany, selectCommercialNameCompany,

		filterDateRanges, setFilterDateRanges, optionsDue,

		isLoadingWorkOrders,

		loadingPreview, showLoading,

		handleChangeTyepeReceptor,
		handleChangePatient,
		handleChangeClient,
		handleChangeIssuing,
		handleChangeClientBusinessId,
		handleViewFilters,
		handleChangeInput,
		handleChangeFilterNims,
		handleUpdateFiscalInformation,
		handleToggleModal,
		handleChangeInputEditPatient,
		handleChangeInputEditClient,
		handleConfirmExtraPatients,

		handleAddNewWorkOrder,
		handleChangeAddNewWorkOrder,

		handleFilterDates,
		handleFilterByGeneralPublic,
		handleChangeTypeVisualization,

		handleDeleteTest,

		handlePreviewInvoice, handleLoadingPreInvoice,

		saveUpdateFiscalInformation, saveUpdateClientInformation,
		saveInvoice,

		validationsPatient, validationsBusinessName
	}
}
