import React, { Fragment} from 'react'
import { SearchAsync } from '../../../components/elements/SearchAsync';
import { Button, Card, Badge, Col, Container, CardBody, Row, CardHeader, Label, Input ,Table,CardFooter,ButtonGroup,Form} from 'reactstrap';
import { useQuoter } from '../../../hooks/micro/useQuoter'
import 'animate.css';
import { ModalViewExamsQuoter } from './ModalViewExamsQuoter';
import Select from 'react-select';
import Datetime from 'react-datetime';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import { CollapseData } from './DataGrid/CollapseData';
import FormatNumbers  from '../../../utils/formatNumbers';
import { ConfigServer } from '../../../data/config';
import './styleQuoterMic.css';
import { ToolTips } from '../../../components/tooltips/ToolTips';
import { badgeClasses } from '@mui/material';


export const IndexQuoterMic = () => {

	const {
		
		//*TYPE CLIENT
        handleChangeTypeClient,checkTypeClient,handleChangePatient,informationPatient,listCompanies,handleChangeCompany,informationCompany,
        
        //*QUOTER
        handleChangeComments,handlePickerValues,handleChangeSampling,

        //*TYPE OF SAMPLE
        handleSelectDetermination,searchSampleState,examProfileState,handleSelectMuestra,

        //*FORM MIC
        formQuoterMic,

        //*ACTION TYPE SAMPE
        handleDeleteTypeSample,handleAddQuantity,handleDeleteQuantity,

        //*TABLE
        viewButtons,handleObservations,listRules,handleSearchRules,handleRemoveDeterminacion,handleDiscount,

		//*TOGGLE EXAMS
        handleToggleExams,handleViewExamsProfile,modalExams,listExams,

		//*PRICES
		optionsTotal,total_tax,

		//*VIEW QUOTER
		handleViewQuoter,handleClosedQuoter,viewQuoter,nameBranch,loadingCreate,
		
        //*CREATE
        saveQuatation,downloadQuoter,handleNewQuatation,handleNavigateSampling,

		//*UPDATE QUOTER
        updateQuatation,

		//*CONFIGURATION BUTTONS
		isHovered,handleMouseEnter,handleMouseLeave,handleMouseEnterClean,handleMouseLeaveClean,isHoveredClean,

		//*
		informationQuatation,handleExpandRow,hoveredColumn,setHoveredColumn,expandedRow,

		getSearchQuoter,handleFilterValues,handleSearchPatient,handleSearchClient,

		getFilter,handleCleanFilter,

		//*AGREEMENT
		listAgreement,handleChangeAgreement
	
	} = useQuoter();

	const divInformationPatient = <>
	<Col sm="12">	
		<div className='project-box p-1' style={{ borderWidth: '0px', opacity: "0.8" }}>
			<Row className='mt-1'>
				<Col sm="4" md="4" lg="4">
					<small>Fecha de nacimiento</small> <br />
					<label><i className="fa fa-circle text-info"></i> {informationPatient.birthday ?? "----"}</label>
				</Col>
				<Col sm="3" md="3" lg="3">
					<small>Edad</small> <br />
					<label style={{ fontSize: "11px" }}><i className="fa fa-circle text-info"></i> {informationPatient.age ?? "----"}</label>
				</Col>
				<Col sm="4" md="4" lg="4">
					<small>Curp</small> <br />
					<label><i className="fa fa-circle text-info"></i> {informationPatient.curp ?? "----"}</label>
				</Col>
			</Row>
			<Row className='mt-1'>
				<Col sm="7" md="7" lg="7">
					<small>Email</small> <br />
					<label><i className="fa fa-circle text-info"></i> {informationPatient.email ?? "----"}</label>
				</Col>
				<Col sm="3" md="3" lg="3">
					<small>Telefono</small> <br />
					<label><i className="fa fa-circle text-info"></i> {informationPatient.phone ?? "----"}</label>
				</Col>
				<Col sm="1">
				</Col>
				<Col sm="1" md="1" lg="1">
					{
						<Button id='btn-tooltip-filter-p'  outline color='secondary' className='p-3' style={{ borderRadius: 40, height: 30, width: 30, display: "flex", flexWrap: "nowrap", alignContent: "right", justifyContent: "center", alignItems: "center", position: "absolute", right: 15 }}
						>
						<i className="fa fa-users"></i>
						</Button>
					}
				</Col>
			</Row>
		</div>
	</Col>
	</>

	const divInformationCompany = <>
		<Col sm="12">
		{
			informationCompany.map((_find) => {
				return(
					<div className='project-box p-1' style={{ borderWidth: '0px', opacity: "0.8" }}>
				<Row className='mt-1'>
					<Col sm="4" md="4" lg="7">
						<small>Nombre</small> <br />
						<label><i className="fa fa-circle text-info"></i> {_find?.name ?? "----"}</label>
					</Col>
					<Col sm="3" md="3" lg="3">
						<small>Telefono</small> <br />
						<label style={{ fontSize: "11px" }}><i className="fa fa-circle text-info"></i> {_find?.phone ?? "----"}</label>
					</Col>
					<Col sm="4" md="4" lg="2">
						<small>Factura</small> <br />
						<label><i className="fa fa-circle text-info"></i> {_find?.require_invoice ?? "----"}</label>
					</Col>
				</Row>
				<Row className='mt-1'>
					<Col sm="4" md="4" lg="4">
					<small>Pago generado</small> <br />
					<label><i className="fa fa-circle text-info"></i> {_find?.payment ?? "----"}</label>
					</Col>
					<Col sm="4" md="4" lg="8">
						<small>Correo</small> <br />
						<label><i className="fa fa-circle text-info"></i> {_find?.contact_email ?? "----"}</label>
					</Col>
				</Row>
			</div>

				)
			})

		}
			
		</Col>
	</>
	   const divStyle = {
		backgroundColor: isHovered ? "#1e90ff" : "#46afe5",
		borderColor: "#46afe5",
		color: "#fff",
		textAlign: "center",
		cursor: "pointer",
		padding: "8px 16px",  
	  };

	  const divStyleClean = {
		backgroundColor: isHoveredClean ? "#dc3545" : "#dc3545a6",
		borderColor: "#46afe5",
		color: "#fff",
		textAlign: "center",
		cursor: "pointer",
		padding: "8px 16px",  
	  };

	// ? AGREEMENT
	const divAgreement= <>
		<Col sm="12" className='pt-2'>
		<h6>Convenio</h6> 
            <Select
			isDisabled={formQuoterMic.id_quatation > 0}
            classNamePrefix='select'
            name="id_agreement"  
			placeholder = "Selecciona el convenio deseado"      
			options={listAgreement}     
			value={formQuoterMic.id_agreement}      
			onChange={(e) => handleChangeAgreement(e)}  
            />	
		</Col>
	</>

	return (
		<Fragment>
			<Container fluid={true} className='p-l-0 p-r-0' >
				<Row className='mt-2 mb-3 '>
					<Col sm="12">
					<Row className='pt-3' >
						<Col sm="12" md="12" lg="9" className='p-r-0'>
						    {	
							!viewQuoter &&<Card>
							<CardHeader style={{ paddingTop: "10px", paddingBottom: "10px" }} className='text-rigth'>
                                <Row>
								<Col sm="12" md="12" lg="4" >
									<Button disabled={formQuoterMic.id_quatation > 0 ?  true : false}  type="button" color='warning'  className='btn-block round btn-air-secondary text-center' onClick={() => handleChangeTypeClient(4)} outline={checkTypeClient === 4 ? false : true} >
									<div className='w-100'>
										<Col sm="12" style={{textAlign:"center"}}>
										<img alt="flask"  src={require("../../../assets/images/price/codeQuoter.svg")} style={{ width: "28px", height: "28px" }} />
										</Col>
                                            </div>
                                            {!formQuoterMic.valid_sampling&&<span className='f-w-600'>Buscar cotización</span>}
											{formQuoterMic.valid_sampling&&<span className='f-w-600'>{formQuoterMic.code_quatation}</span>}
                                        </Button>
									</Col>
                                    <Col sm="12" md="12" lg="4">
                                        <Button disabled={formQuoterMic.id_quatation > 0 ?  true : false} color="info" type="button"  className='btn-block round btn-air-secondary text-center' onClick={() => handleChangeTypeClient(1)} outline={checkTypeClient === 1 ? false : true} >
										<Col sm="12" style={{textAlign:"center"}}>
										<img alt="flask"  src={require("../../../assets/images/price/particular-company.svg")} style={{ width: "28px", height: "28px" }} />
										</Col>
                                            <span className='f-w-600'>Paciente particular</span>
                                        </Button>
                                    </Col>
                                    <Col sm="12" md="12" lg="4">
                                        <Button disabled={formQuoterMic.id_quatation > 0 ?  true : false} color="primary" type="button"  className='btn-block text-center' onClick={() => handleChangeTypeClient(3)} outline={checkTypeClient === 3 ? false : true}>
										<Col sm="12" style={{textAlign:"center"}}>
										<img alt="flask"  src={require("../../../assets/images/price/company-micro.svg")} style={{ width: "28px", height: "28px" }} />
										</Col>
                                            <span className='f-w-600'>Empresas</span>
                                        </Button>
                                    </Col>
									
                                </Row>
                            </CardHeader>
							{checkTypeClient !== 4 &&<CardBody  style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
								<Row>
									<Col sm="12" md="12" lg="6" className='pt-4' >
									<h6>Cliente</h6>
									<Row>
										{
											checkTypeClient === 3 && <Col sm="12">
												<div className='form-group'>
												<Select
													isDisabled={formQuoterMic.id_quatation > 0}
													classNamePrefix="select"
													name="id_company"
													value={formQuoterMic.id_company}
													placeholder="Selecciona una empresa"
													options={listCompanies}
													onChange={(e) => handleChangeCompany(e)}
																								
												/>
												</div>
											</Col>
										}
										{
											checkTypeClient === 1 && <Col sm="12">
												<div className='form-group'>
													<SearchAsync
														cacheOptions={false}
														value={formQuoterMic.id_patient}
														name="id_patient"
														method={handleChangePatient}
														loincState={ formQuoterMic.searchPatientState}
														url="Patient/SearchPatient"
														maxLenght={3}
														placeholder="Búsqueda por nombre o curp"
														mDisabled={formQuoterMic.id_quatation > 0}
												    />
												</div>
											</Col>
										}
									</Row>
									<Row>
										{
										 informationPatient.name !== undefined && checkTypeClient === 1 &&  divInformationPatient
										}
										{
										informationCompany.length > 0 && checkTypeClient === 3 && divInformationCompany 
										}
										{
										informationCompany.length > 0 && checkTypeClient === 3 && divAgreement
										}
									</Row>

									</Col>
									
									<Col sm="12" md="12" lg="6" className='pt-4' >
									<h6>Datos de Muestreo</h6>
									<Col sm="12">
									<Label className="col-form-label">Observaciones <span className='f-14' style={{ color: "#0079C7" }}>*</span></Label>
									<textarea
										name="observations"
										className="form-control input-air-primary form-control-sm "
										value={formQuoterMic.observations}
										onChange={handleChangeComments}
										style={{fontSize:"11px"}}
									/>
									</Col>

									<Col sm="12" className='pt-4'>
									<Row>
									<Col sm="5" className='pt-1'>
									<Label className="col-form-label">Requiere Muestreo</Label> <br /> 
									<Label className="switch ">
										<Input type="checkbox" name="is_sampling" onChange={handleChangeSampling} checked={formQuoterMic.is_sampling} />
										<span className="slider round"></span>
									</Label>
									</Col>
									<Col sm="7" className={formQuoterMic.is_sampling === true ? 'pt-1' : 'd-none'}>
									<label className='pt-2'>Fecha de muestreo</label>
									    <div>
										<Datetime
										inputProps={{
											placeholder: 'aaaa-mm-dd hh:mm',
											name: 'date_sample',
											autoComplete: "off",
											className: "form-control digits form-control-sm",
										}}
										timeFormat="HH:mm"
										timeConstraints={{
											hours: { min: 0, max: 23 },
											minutes: { min: 0, max: 59 },
											seconds: { min: 0, max: 59 }
										}}
										value={formQuoterMic.date_sample ? moment(formQuoterMic.date_sample, 'YYYY-MM-DD HH:mm') : null}
										dateFormat="YYYY-MM-DD"
										locale="es"
										onChange={handlePickerValues}
									/>
										</div>
									</Col>
									</Row>
									</Col>
									</Col>
								</Row>
								<hr />
								{(formQuoterMic.id_company !== null || formQuoterMic.id_patient !== null) &&<Row>
									<Col sm="12">
									<h6>Tipo de muestras y determinaciones</h6>
									<Col sm="12">
										<Row className='pt-3'>
											<Col sm="12" md="12" lg="3" className='p-r-0 p-l-0'>
											<label className='txt-secondary pt-2 f-14'>Selecciona el tipo de muestra</label>
											</Col>
											<Col sm="12" md="12" lg="9" className='p-l-0'>
											<div className='form-group'>
												<SearchAsync
													cacheOptions={false}
													value={formQuoterMic.type_sample_select}
													name="id_sample"
													method={handleSelectMuestra}
													loincState={searchSampleState}
													url="TypeSamples/SearchTypeSample"
													maxLenght={3}
													placeholder="Búsqueda de muestras"
												/>
											</div>
											</Col>
											<Col sm="9" className='p-l-0'>
											</Col>
										</Row>
										
								
									</Col>
									</Col>
								</Row>}
							</CardBody>}
							{
								checkTypeClient === 4 && <CardBody style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px" }}>
									  <div className='text-left'>
                                     <p>Mostrando - {informationQuatation?.length} resultados</p>
                                    </div> <br />
									<div className='table-wrapper-mic-quoter pt-4'>
										<Table className='b-r-5 table table-xs '>
										<thead className='b-r-5' style={{textAlign : "center"}} >
										<tr style={{ backgroundColor: "#f3f3f3", borderBottom : "#e3ebf3", borderTop:"#e3ebf3" }} >
										<th style={{width:"10%"}}></th>
										<th >Código</th>
										<th >Tipo</th>
										<th >Cliente</th>
										<th >fecha</th>
										<th >Iva</th>
										<th >Acciones</th>
										</tr>
										</thead>
										<tbody style={{textAlign : "center"}}>
										{
											loadingCreate && <tr>
												<td colSpan={6} className=' text-center f-16'>
												<Col sm="12" md="12" lg="12" className='text-center pt-4'>
												<img alt="flask" className="animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: "100px", height: "100px" }} />
												<br />
												<label className='txt-secondary f-18'>Cargando.....</label>
												</Col>
												<br />
												</td>
											</tr>
										}
										{ !loadingCreate &&  informationQuatation?.map((_find, _k) => (
											
											<React.Fragment key={_find?.id}>
											<tr onMouseEnter={() => setHoveredColumn(_k)} style={{ backgroundColor: hoveredColumn === _k ? '#46afe50d' : 'inherit' }}>
											<td style={{width:"10%"}}>
											<i style={{color:"#46AFE5"}}  className={`fa fa-${expandedRow === _k ? 'plus-square' : 'plus-square-o'} f-20  `} onClick={() => handleExpandRow(_k)}></i>
											</td>
												<td style={{color:"#f8d62b"}}> <label className='f-w-600'>{_find?.code_quoter ?? "------"}</label> </td>
												<td className=' f-w-700'><label style={{color:"#6c757d"}} className='f-w-600'>{_find?.type ?? "------"}</label> </td>
												<td className=' f-w-700'><label style={{color:"#6c757d"}} className='f-w-600'>{_find?.client ?? "------"}</label> </td>
												<td ><label style={{color:"#6c757d"}} className='f-w-600'>{_find?.date_quoter ?? "------"}</label></td>
												<td style={ _find?.tax === "16%" ? {color:"#46AFE5"} : {color:"#0079C7"} }> <label className='f-w-700'>{_find?.tax ?? 0}</label> </td>
												<td> 
												{
												    _find.id_work_order === null ? 
														<Badge style={{ cursor: "pointer" }} color='warning' onClick={() => getSearchQuoter(_find?.code_quoter)}>
														<i id={`tooltip-edit-${_k}`}style={{color:"#fff", fontSize:"11px"}} className='fa fa-edit ' ></i>
															<ToolTips
															placement="top"
															dataTarget={`tooltip-edit-${_k}`}
															dataText={'Editar cotización'}
															/>
														</Badge>
													    : 
													    <Badge style={{ cursor: "pointer" }} color='dark'>
														<i  id={`tooltip-authorized-${_k}`} style={{color:"#fff", fontSize:"11px"}} className='fa fa-ticket' ></i>
															<ToolTips
															placement="top"
															dataTarget={`tooltip-authorized-${_k}`}
															dataText={'Se a convertido en orden de trabajo'}
															/>
														</Badge>
												}
												<Badge style={{ cursor: "pointer" }} color='primary' onClick={() => downloadQuoter(_find?.id_quoter)}>
													<i id={`tooltip-pdf-${_k}`} style={{color:"#fff", fontSize:"11px"}} className='fa fa-file-pdf-o '></i>
													<ToolTips
                                                        placement="top"
                                                        dataTarget={`tooltip-pdf-${_k}`}
                                                        dataText={'Descargar cotización'}
                                                        />
												</Badge>
												<Badge className={_find.id_work_order === null ? "" : "d-none"} style={{ cursor: "pointer" }} color='success' onClick={() => handleNavigateSampling(_find?.code_quoter)}>
													<i id={`tooltip-samplig-${_k}`}  style={{color:"#fff", fontSize:"11px"}} className='icofont icofont-prescription '></i>
													<ToolTips
                                                        placement="top"
                                                        dataTarget={`tooltip-samplig-${_k}`}
                                                        dataText={'Iniciar Muestreo'}
                                                        />
												</Badge>
												</td>
											</tr>
											{expandedRow === _k && (
												<tr style={{backgroundColor :"#1f9ad924"}}>
												<td colSpan={7} style={{textAlign : "justify"}} >
												<Table className='b-r-5 table table-sm'>
													<thead className='b-r-5'>
														<tr style={{ backgroundColor: "#033d5b", borderBottom: "#e3ebf3", borderTop: "#e3ebf3" }}>
															<th style={{ textAlign: "center", width: "2%" , color:"#fff"}}>Cantidad</th>
															<th style={{ textAlign: "center", width: "9%" , color:"#fff" }}>Muestra</th>
															<th style={{ textAlign: "center", width: "50%", color:"#fff" }}>Determinaciones</th>
															<th style={{ textAlign: "center", width: "50%", color:"#fff" }}>Metodologia</th>
														</tr>
													</thead>
													<tbody>
													{_find.list_determinations.map((item, index) => {											
														return (
														<React.Fragment key={index}>
															<tr>
															<td style={{ textAlign: "center", color:"#9fa0a1", width: "2%" }}>
																<label style={{fontSize:"10px"}} className='f-w-600'>{1}</label>
															</td>
															<td style={{ textAlign: "center", color:"#9fa0a1", width: "9%" }}>
																<label style={{fontSize:"10px"}} className='f-w-600'>{item.name_sample ?? "---"}</label>
															</td>
															<td style={{ textAlign: "center", color:"#9fa0a1", width: "50%" }}>
																{item.list_determinations.length === 0 ? (
																<span>No hay determinaciones disponibles</span>
																) : (
																item.list_determinations.map((determination, key) => (
																	<label style={{fontSize:"10px"}} key={key}>-{determination.name ?? "---"}</label>
																))
																)}
															</td>
															<td style={{ textAlign: "center", color:"#9fa0a1", width: "30%" }}>
																{item.list_determinations.length === 0 ? (
																<span>No hay determinaciones disponibles</span>
																) : (
																item.list_determinations.map((determination, key) => (
																	determination.list_nom.map((_find, _key) => (
																	<label style={{fontSize:"10px"}} key={_key} className='f-w-600'>-{_find.name ?? "---"}</label>
																	))
																))
																)}
															</td>
														
															</tr>    
														</React.Fragment>
														);
													})}
													</tbody>
												</Table> 
											</td>
											</tr>
											)}
											</React.Fragment>
											))}
										</tbody>
										</Table>
									</div>
								</CardBody>
							}
							</Card>
							}
							{
							    viewQuoter && <Card>
									<CardHeader style={{ paddingTop: "10px", paddingBottom: "10px" }} className='text-rigth'>
										<Row  >
											<Col sm="12" md="6" lg="6" className='p-l-0'>
											<label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-info"></i> Datos del cliente</label>  <br />
											{checkTypeClient === 1 && <div>
											<label style={{fontSize:"11px",color:"#6c757dc9"}} className='f-w-600 '>Cliente:&nbsp;{informationPatient.client ?? "----"}</label> <br />
											<label style={{fontSize:"11px",color:"#6c757dc9"}} className='f-w-600 '>Correo:&nbsp;{informationPatient.email ?? "----"}</label> <br />
											<label style={{fontSize:"11px",color:"#6c757dc9"}} className='f-w-600 '>Telefono:&nbsp;{informationPatient.phone ?? "----"} </label> <br />
											</div>}
											{checkTypeClient === 3 && <div>
												{informationCompany.map((_find) => {
													return(
														<>
														<label style={{fontSize:"11px",color:"#6c757dc9"}} className='f-w-600 '>Cliente:&nbsp;{_find?.name ?? "----"}</label> <br />
														<label style={{fontSize:"11px",color:"#6c757dc9"}} className='f-w-600 '>Correo:&nbsp;{_find?.contact_email ?? "----"}</label> <br />
														<label style={{fontSize:"11px",color:"#6c757dc9"}} className='f-w-600 '>Telefono:&nbsp;{_find?.phone ?? "----"} </label> <br />
														</>
													)
												})
											}
											</div> }
											</Col>
											<Col sm="12" md="6" lg="6">
											<Row>
												<Col sm="8">
												</Col>
												<Col sm="4" className='p-r-0'>
												<div className="media profile-media" > 
												<img className="b-r-10  "
													src={ConfigServer.serverUrl + "/api/Configuration/Logo"}
													style={{ width: "100%" }}
													alt="Invoice" />
												</div>
												</Col>
											</Row>
											<Col sm="12" md="12" lg="12" className='p-r-0' style={{textAlign:"right"}}>
											<label style={{fontSize:"14px",color:"#6c757dc9"}} className='f-w-600 '>{nameBranch}</label>
											</Col>
											</Col>
										</Row>
									</CardHeader>
									<CardBody className='pb-0' style={{ paddingTop: "15px", paddingLeft: "15px", paddingRight: "15px", paddingBottom:"1px" }}>
										{!loadingCreate &&<div className= 'table-wrapper-mic'>
											<Table className="table-border-vertical table-xs">
												<thead className='b-r-5'>
													<tr style={{ backgroundColor: "#033d5b", borderBottom: "#e3ebf3", borderTop: "#e3ebf3" }}>
														<th style={{ textAlign: "center", width: "2%", color: "#fff" }}>Cantidad</th>
														<th style={{ textAlign: "center", width: "9%", color: "#fff" }}>Muestra</th>
														<th style={{ textAlign: "center", width: "50%", color: "#fff" }}>Concepto</th>
														<th style={{ textAlign: "center", width: "30%", color: "#fff" }}>Metodologia</th>
														<th className={formQuoterMic.id_company !== null ? "d-none" : ""} style={{ textAlign: "center", width: "10%", color: "#fff" }}>Subtotal</th>
														<th className={formQuoterMic.id_company !== null ? "d-none" : ""} style={{ textAlign: "center", width: "5%", color: "#fff" }}>Descuento</th>
														<th style={{ textAlign: "center", width: "10%", color: "#fff" }}>Total</th>
														<th style={{ textAlign: "center", width: "5%", color: "#fff" }}>Iva</th>
													</tr>
												</thead>
												<tbody>
												{formQuoterMic.list_type_samples.map((_obj) => {
													const backgroundColor = _obj.list_determinations.some(determination => determination.discount_par > 0) ? 'bg-warning' : 'bg-success';
													const backgroundColorIva = _obj.list_determinations.some(determination => determination.value_iva === 16) ? 'bg-primary' : 'bg-secondary';
													
													return (
													<React.Fragment key={_obj.id}>
														{_obj.list_determinations.map((_find, index) => {
														const _index = index === 0; 
														const _colors = _index ? "#1f9ad9" : "#ccc"; 
														const _colorsTxt = _index ? "#fff" : "#fff"; 

														return (
															<tr style={{ paddingBottom: 30 }} key={_find.id}>
															<td style={{ background: _colors, color: _colorsTxt, fontWeight: "bold", fontSize: "10px", textAlign: "center", verticalAlign: "middle" }}>
																{_index ? _obj.cant : ''}
															</td>
															<td style={{ background: _colors, color: _colorsTxt, fontWeight: "bold", fontSize: "10px", textAlign: "center", verticalAlign: "middle" }}>
																{_index ? _obj.name_sample : ''}
															</td>
															<td style={{ border: '1px solid #0079C7', textAlign: "center", verticalAlign: "middle" }}>{_find?.name}</td>
															<td style={_find.list_nom.length > 0 ? { background: "#00294024", border: '1px solid #002940', textAlign: "center", verticalAlign: "middle" } : { textAlign: "center", verticalAlign: "middle" }}>
																{_find.list_nom.length > 0 ? (
																<div>
																	{_find.list_nom.map((_x) => (
																	<label key={_x.code}> { _x?.name ?? "------" } </label>
																	))}
																</div>
																) : (
																<label className='f-w-600'>{"---------------------"}</label>
																)}
															</td>
															<td className={formQuoterMic.id_company !== null ? "bg-warning" : backgroundColor} style={{ textAlign: "center", color: "#9fa0a1", width: "5%", border: '1px solid #0079C7', verticalAlign: "middle" }}>
																<label className='f-w-600' style={{ color: _colorsTxt, fontSize: "13px" }}>${_find.price}</label>
															</td>
															<td className={formQuoterMic.id_company !== null ? "d-none" : backgroundColor} style={{ textAlign: "center", color: "#9fa0a1", width: "5%", border: '1px solid #0079C7', verticalAlign: "middle" }}>
																{_find.discount_par > 0 ? (
																<label className='f-w-600' style={{ color: _colorsTxt, fontSize: "13px" }}>{_find.discount_par}%</label>
																) : (
																<label style={{ color: _colorsTxt, fontSize: "13px" }}>-------</label>
																)}
															</td>
															<td className={formQuoterMic.id_company !== null ? "d-none" : backgroundColor} style={{ textAlign: "center", color: _colorsTxt, width: "10%", fontSize: "13px", border: '1px solid #0079C7', verticalAlign: "middle" }}>
																{_find.discount_par > 0 ? `$${Math.round(FormatNumbers(_find.discount_tota, 2))}` : `$${Math.round(FormatNumbers(_find.can_total, 2))}`}
															</td>
															<td className={backgroundColorIva} style={{ textAlign: "center", color: _colorsTxt, width: "50%", border: '1px solid #0079C7', verticalAlign: "middle" }}>
																<label className='f-w-600' style={{ color: _colorsTxt, fontSize: "13px" }}>{_find.value_iva ?? "---"}%</label>
															</td>
															</tr>
														);
														})}
													</React.Fragment>
													);
												})}
												</tbody>
											</Table>
									</div>}
									{loadingCreate &&     <Col sm="12" md="12" lg="12" className='text-center'>
										<img alt="flask" className="animate__animated animate__pulse animate__infinite" src={require("../../../assets/images/loginHelenLabs/HelenLabs.png")} style={{ width: "100px", height: "100px" }} />
										<br />
										<p className=' text-primary'><label className='f-w-400 f-16'>Generando Cotización</label></p>
                                    </Col>}
									</CardBody>
									<CardFooter className='pt-0 pb-4 pr-3'>
										<Col sm="12" className='pt-4 p-r-0' style={{textAlign:"end"}}>
										<ButtonGroup className='pt-3'>
										  {formQuoterMic.statusQuoter && <Button
												className=" btn-air-danger "
												color="secondary"
												size="sm"
												onClick={() => downloadQuoter()}
												><i className="icofont icofont-download-alt f-14"></i>&nbsp;Descargar cotización
											</Button>}
											{!formQuoterMic.statusQuoter &&<Button
												className=" btn-air-danger "
												color="primary"
												size="sm"
												onClick={formQuoterMic.code_quatation === null ? saveQuatation  : updateQuatation}
												disabled={loadingCreate ? true : false}
												><i className="icofont icofont-file-pdf f-14"></i>&nbsp;{formQuoterMic.code_quatation === null ? "Generar cotización" : "Guardar edición"}
											</Button>}
											{!formQuoterMic.statusQuoter && <Button
												className=" btn-air-danger "
												color="success"
												size="sm"
												onClick={handleClosedQuoter}
												disabled={loadingCreate ? true : false}
											><i className="fa fa-edit f-14"></i>&nbsp;Editar cotización
											</Button>}
											{formQuoterMic.statusQuoter &&  checkTypeClient === 1 && <Button
												className=" btn-air-danger "
												color="success"
												size="sm"
												onClick={() => handleNavigateSampling(formQuoterMic.code_quatation)}
											><i className="icofont icofont-id f-14"></i>&nbsp;Continuar Muestreo
											</Button>}
										</ButtonGroup>
										</Col>

									</CardFooter>

								</Card>
							}
						</Col>
						    {
							checkTypeClient !== 4 && <Col sm="12" md="12" lg="3" >
							<Col sm="12" className='p-l-0 p-r-0'>
							<Card style={{height:"473px"}}>
							<Col sm="12">
								<h6 className='txt-secondary pt-2'>Resúmen</h6>
								<div className='hr-detail-patient '></div>
							
								<Row className='pb-4 pt-4'>
									<Col sm="12" md="12" lg="12">
									<label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-info"></i> Observaciones del Muestreo</label> <br />
									{formQuoterMic.valid_sampling && <label>{ formQuoterMic?.observations ?? "----"}</label>}
									</Col>
								</Row > 
								<Row className='pt-4'>
									<Col sm="12" md="12" lg="12">
									<label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-info"></i> Resúmen del pago</label> <br /> 
									</Col>
								</Row >
								{/* style={{ maxWidth: "400px", margin: "auto" }} */}
								{formQuoterMic.valid_sampling  &&<div  className="table-responsive pt-1" >
									<Table style={{ fontSize: "11px" }}>
										<thead>
											<tr style={{ backgroundColor: "#46AFE5" }}>
												<td colSpan={2} style={{ color: "#fff", textAlign: "center", padding: "5px" }}>
													Detalle de movimientos
												</td>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td style={{ padding: "5px" }}>
													<label className='f-12'>Subtotal</label>
												</td>
												<td className="text-center" style={{ padding: "5px" }}>
													<label>$ {FormatNumbers(optionsTotal.subtotal,2)}</label>
												</td>
											</tr>
											<tr>
												<td style={{ padding: "5px" }}>
													<label style={{fontSize:"13px"}} className={total_tax === 16 ? 'f-w-600 txt-primary' : 'f-w-600 txt-secondary' }  >I.V.A({total_tax}%)</label>
												</td>
												<td className="text-center" style={{ padding: "5px" }}>
													<label>$ {FormatNumbers(optionsTotal.tax,2)}</label>
												</td>
											</tr>
											<tr>
												<td style={{ padding: "5px" }}>
													<label style={{fontSize:"13px"}} className='f-w-600'>Total</label>
												</td>
												<td className="text-center f-w-600 bg-dark" style={{ padding: "5px" }}>
													<label style={{fontSize:"13px"}} className='f-w-600'>$ {FormatNumbers(optionsTotal.total,2)}</label>
												</td>
											</tr>
										</tbody>
									</Table>
								</div> } <br />
								{formQuoterMic.valid_sampling &&<Row className="pb-4 pt-2">
									<Col sm="12" md="12" lg="12" className='pt-1'>
									<label style={{fontSize:"12px"}} className='f-w-600 '><i className="fa fa-circle text-info"></i> Acciones</label> <br /> 
									{
									formQuoterMic.statusQuoter &&  <div className='pointer' style={{backgroundColor :"#f8d62b", color:"#fff", paddingBottom:"5px", paddingTop:"5px", textAlign:"center"}} onClick={handleNewQuatation}  ><i className="fa fa-file-pdf-o"></i> Generar Nueva cotización</div>
									}
									{
									!formQuoterMic.statusQuoter && <div style={ viewQuoter ? {backgroundColor :"#6c757dcc", color:"#fff", paddingBottom:"5px", paddingTop:"5px", textAlign:"center"} : {backgroundColor :"#28a745", color:"#fff", paddingBottom:"5px", paddingTop:"5px", textAlign:"center"}} type="submit" onClick={viewQuoter ? handleClosedQuoter : handleViewQuoter } outline color={viewQuoter ? "danger" : "primary" }   size="xs"  > {viewQuoter ? "Datos de la Cotización" : "Previsualizar cotización" } </div>
									} <br />
									{
									formQuoterMic.id_quatation !== null && formQuoterMic.valid_sampling && <div className='pointer'  style={{backgroundColor :"#f8d62b", color:"#fff", paddingBottom:"5px", paddingTop:"5px", textAlign:"center"}} onClick={() => handleChangeTypeClient(4)}  ><i className="fa fa-file-pdf-o"></i>Cargar otra cotización</div>
									}		
									</Col>
								</Row >} 
								</Col>
								</Card>
							</Col>
								
							</Col>
                            }
							{
								checkTypeClient === 4 &&
								<Col sm="12" md="12" lg="3">
								<Card>
									<CardHeader className='p-3  bg-warning'>
									  <i className='fa fa-filter fIconFilter f-20 '></i>
									  <label className='f-14'>Filtros de Busqueda</label>
									</CardHeader>
									<CardBody className='p-3'>
										<Row className='pb-4'>
											<Col sm="12" md="12" lg="12" className='text-center'>
											</Col>         
											<Col sm="12" md="12" lg="12">
											<label className='f-w-600 f-12'>Fecha de incio</label>
											<Input type='date'value={formQuoterMic.start_date} style={{fontSize:"14px"}} className='border-0 mr-2' name='start_date' onChange={handleFilterValues} ></Input>
											</Col>    
											<Col sm="12" md="12" lg="12" className='pt-2'>
											<label className='f-w-600 f-12'>Fecha Final</label>
											<Input type='date' value={formQuoterMic.end_date} style={{fontSize:"14px"}}  className='border-0 mr-2' name='end_date' onChange={handleFilterValues} > </Input>
											</Col>   
											<Col sm="12" md="12" lg="12" className='pt-1'>
											<label className='f-w-600 f-12'>Código</label>
											<Input value={formQuoterMic.code_quatation} style={{fontSize:"14px"}} name='code_quatation' onChange={handleFilterValues}  className='border-1 mr-2'  > </Input>
											</Col>  
											<Col sm="12" md="12" lg="12" className='pt-1'>
											<label className='f-w-600 f-12'>Paciente</label>
											<div className='form-group'>
													<SearchAsync
														cacheOptions={false}
														value={formQuoterMic.id_patient_search}
														name="id_patient_search"
														method={handleSearchPatient}
														loincState={ formQuoterMic.searchPatientQuoter}
														url="QuoterDeterminations/ListPatientsMicro"
														maxLenght={3}
														placeholder="Búsqueda por nombre o curp"
												    />
												</div>
											</Col>
											<Col sm="12" md="12" lg="12" className='pt-1'>
											<label className='f-w-600 f-12'>Empresa</label>
											<div className='form-group'>
											<Select
													classNamePrefix="select"
													name="id_company_search"
													value={formQuoterMic.id_company_search}
													placeholder="Selecciona una empresa"
													options={listCompanies}
													onChange={(e) => handleSearchClient(e)}																								
												/>
												</div>
											</Col>
											<Col sm="12" md="12" lg="12" className='pt-1'>
											 <div type="submit" onClick={(e) => getFilter(e, true)}  className='pointer' style={divStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
											Buscar
											</div> 
											</Col>
											<Col sm="12" md="12" lg="12" className='pt-1'>
											 <div onClick={handleCleanFilter}  className='pointer' style={divStyleClean} onMouseEnter={handleMouseEnterClean} onMouseLeave={handleMouseLeaveClean}>
											Limpiar
											</div> 
											</Col>
							
										</Row>

										
									</CardBody>
								</Card>
							    </Col>  
							}
						<Col sm="12">
							{
								!viewQuoter && !formQuoterMic.statusQuoter && formQuoterMic.list_type_samples.length >= 0 && formQuoterMic?.list_type_samples.map((_type,_key) => {
									return <CollapseData
									items={_type}
									{
									...{
										//*ACTION TYPE SAMPLE
										formQuoterMic,examProfileState,handleSelectDetermination,_key,checkTypeClient,
										//*TABLE
										viewButtons,handleObservations,listRules,handleSearchRules,handleRemoveDeterminacion,handleDeleteTypeSample,
										handleAddQuantity,handleDeleteQuantity,handleViewExamsProfile,handleDiscount,
									}}
									/>
								})
							}
						</Col>
					</Row>
					</Col>
				</Row>
			</Container >
			 <ModalViewExamsQuoter
				{
				...{ modalExams, handleToggleExams, listExams }
				}
			/>	
		
		</Fragment >
	)
}