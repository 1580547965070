import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendRequest } from '../requests/useRequest';
import { useSweetAlert } from '../sweetAlerts/useSweetAlert';
import { validarSesion } from '../tokens/useToken';
import moment from 'moment';
import { ConfigServer } from "../../data/config";
import {validateBacterium,unValidateBacterium,releaseBacterium,unreleaseBacterium } from '../../services/micro/micro'


export const useCaptureResults = () => {
    const { nim } = useParams();
    const history = useHistory();

    //*VARIABLES LOCAL STORAGE
    let id_branch = localStorage.getItem('branchId');
    let credit_payment = localStorage.getItem('credit_payment');
    let enable_interface_imagenology = localStorage.getItem('enable_interface_imagenology');
    
    enable_interface_imagenology = (enable_interface_imagenology === "true" ? true : false);
    const [createSweet, sweetConfirmation, sweetDelete, sweetError, createSweett, sweetConfirmationUpdate, sweetConfirmRepeatExam, sweetConfirmationChangeClient, sweetConfirmationCancel, sweetValidDate,sweetDeleteBacterium1] = useSweetAlert()

    const [profiles, setProfiles] = useState([]);
    const [sections, setSections] = useState([]);
    const [patient, setPatient] = useState({});
    const [listDoctors, setListDoctor] = useState([]);
    const [sendDoctor, setSendDoctor] = useState(false);
    const [observations, setObservations] = useState("");
    const [commentsFlebo, setCommentsFlebo] = useState("");
    const [analytes, setAnalytes] = useState([]);
    const [workOrderId, setWorkOrderId] = useState(0);
    const [type_file, setType_file] = useState("");
    const [workMedicalOrderPDF, setworkMedicalOrderPDF] = useState("");
    const [workMedicalOrderPDFDownload, setworkMedicalOrderPDFDownload] = useState("");
    const [workMedicalOrderPDFLoading, setworkMedicalOrderPDFLoading] = useState(false);
    //const [workMedicalOrder, setMedicalOrder] = useState("");
    const [workMedicalOrder, setWorkMedicalOrder] = useState("");
    const [analytesComplex, setAnalytesComplex] = useState([]);
    const [printAvailable, setPrintAvailable] = useState(false);
    const [dobleValidacion, setDobleValidacion] = useState(false);
    const [resultsPartial, setResultsPartial] = useState(false);
    const [paid, setpaid] = useState(false);
    const [idp, setIdp] = useState(0)
    const [commentsPrintResult, setCommentsPrintResult] = useState("");
    const [funcionesFormulas, setFuncionesFormulas] = useState([]);

    const nameUser = localStorage.getItem("nameUser");
    const userSections = localStorage.getItem("sections");
    const userFilterSections = localStorage.getItem("filter_sections");
    const [nimPatient, setNimPatient] = useState([]);

    const [microBacteriums, setMicroBacteriums] = useState([]);
    const [microAntibiograms, setMicroAntibiograms] = useState([]);
    const [microAntibiotics, setMicroAntibiotics] = useState([]);

    const [method, setMethod] = useState("");
    const [modal, setModal] = useState(false);
    const [modalTitle1, setModalTitle1] = useState("");
    const [typeModal, setTypeModal] = useState("view");
    const [typeModalMedical, setTypeModalMedical] = useState("view2");
    const [modalMedical, setModalMedical] = useState(false);

    const [backNim, setBackNim] = useState(null);
    const [nextNim, setNextNim] = useState(null);

    //*MICROBACTERIUMS
    const [presumptive, setPresumptive] = useState([]);
    const [taptopStyle,setTapTopStyle] = useState("none")

    const [loadingButton, setLoadingButton] = useState(false);
    const [modalRelease, setModalRelease] = useState(false);
    const [loadingRelease, setLoadingRelease] = useState(false);

    const [modalViewImagenology, setModalViewImagenology] = useState(false);
    const [modalTitleImg, setModalTitleImg] = useState("");
    const [documentImagenology, setDocumentImagenology] = useState("");


    const executeScroll = () => {
        window.scrollTo({top:0,left:0,behavior: 'smooth'})
    }

    const handleScroll = () => {
        if(window.scrollY > 600){
            setTapTopStyle("block")
        }else{
            setTapTopStyle("none")
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll',handleScroll)
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll)
          }
    },[])
   
    const toggle = () => {
        setModal(!modal);
    }

    const toggleMedical = () => {
        setModalMedical(!modalMedical);
    }

    const handleCreate = () => {
        setTypeModalMedical("view2");
        setModalTitle1("Orden Medica")
        previewWorkOrder();
        toggleMedical()
    }

    const getViewPreview = (_valid) => {

        console.log(_valid);
        

        if (_valid === true) {
            console.log("1");
            
            previewProgressWorkOrder(_valid)
            
        }
        else {
            console.log("2");
            
            previewWorkOrder();
        }
        setTypeModal("view");
        toggle();
    }

    const toggleLoadingButton = (status) => {
        setLoadingButton(status)
    }

    const toggleRelease = () => {
        setLoadingRelease(false);
        setModalRelease(!modalRelease);
    }

    const toggleModalViewImagenology = () => {
        setModalViewImagenology(!modalViewImagenology);
    }

    const handleGetDocumentImagenology = async (id_exam, id_work_order_exam) => {

        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + "/api/Exam/DownloadResultsImagenology/" + id_exam + "/" + id_work_order_exam, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se descargo el documento");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                let objectUrl = window.URL.createObjectURL(blob);

                setDocumentImagenology(objectUrl);
                toggleModalViewImagenology();
                setModalTitleImg("visualizar documento");
            })
    }

    const getFuncionesFormula = async () => {
        let requestOptions = {
            method: 'GET',
        };
        const respuesta = await sendRequest(requestOptions, 'Exam/GetFuncionesFormula');
        if (respuesta.code === 200) {
            setFuncionesFormulas(respuesta.data)
            return respuesta.data;
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, getFuncionesFormula);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    const [loadingPatient, setLoadingPatient] = useState(false);

    //!INFORMATION GENERAL PATIENT
    const getData = async (funcionesFormulasAux = []) => {

        setLoadingPatient(false);

        let nuevas_secciones = JSON.parse(userSections);
        let filtro_secciones = JSON.parse(userFilterSections);

        nuevas_secciones = nuevas_secciones.filter(x => {
            if (filtro_secciones.find(y => y.id_section === x)) {
                return x
            }
        })

        let payload = {
            "nim": nim,
            "sections_array": nuevas_secciones,
            "id_branch": id_branch
        }

        let data = JSON.stringify(payload);

        let requestOptions = {
            method: 'POST',
            body: data

        };

        const respuesta = await sendRequest(requestOptions, "Exam/GetCaptureResults");
        
        setLoadingPatient(true);
        if (respuesta.code === 200) {

            if (respuesta.data.observations !== null) {
                setObservations(respuesta.data.observations);
            }

            if (respuesta.data.comments !== null) {
                setCommentsFlebo(respuesta.data.comments);
            }

            setCommentsPrintResult(respuesta.data.comments_print_result);
            setNimPatient(respuesta.data.nim)

            setSendDoctor(respuesta.data.send_doctor);
            setListDoctor(respuesta.data.listDoctors);

            let validacion_impresion = true;
            let secciones = [];
            setWorkOrderId(respuesta.data.id_work_order);
            setWorkMedicalOrder(respuesta.data.medical_order);
            setType_file(respuesta.data.type_file);
          
            setIdp(respuesta.data.patient.id_patient)

            if (respuesta.data.exams.length > 0) {
                respuesta.data.exams.forEach(exam => {

                    let seccion = secciones.find(x => x.id === exam.id_section);
                    if (seccion === undefined) {
                        secciones.push({
                            id: exam.id_section,
                            name: exam.name_section,
                            exams: [exam],
                            profiles: []
                        });
                    } else {
                        secciones.map(obj => {
                            if (obj.id === exam.id_section) {
                                obj.exams = [
                                    ...obj.exams,
                                    exam
                                ]
                            }
                            return obj;
                        })
                    }
                });
            }

            setSections(secciones);
            let cumpleanios = new Date(respuesta.data.patient.birthday);
            let age = calculateAge(cumpleanios);
            let format_age = "";
            let days_age = 0;
            if (age <= 0) {
                format_age = calculateAgeMonths(cumpleanios);
                let fecha1 = moment(respuesta.data.patient.birthday);
                let fecha2 = moment();
                days_age = fecha2.diff(fecha1, 'days');
            }

            let paciente = {
                ...respuesta.data.patient,
                age: age,
                format_age: format_age,
                days_age: days_age
            }

            setPatient(paciente);
            //!ANALYTES
            let analitos = [];

            //!BACTERIUMS
            let microbacterias = [];

            //!PRESUMTIVE RESULTS
            let presumtive1 = [];


            if (respuesta.data.profiles.length > 0) {

                respuesta.data.profiles.forEach(profile => {

                    let readyToValidateProfile = true;
                    let readyToReleaseProfile = true;
                    profile.exams.forEach(exam => {                        
                        //END SECTION PARA MICROBIOLOGIA CLINICA
                        if (!enable_interface_imagenology && exam.is_imagenology) {
                            if (exam.imagenology !== null) {
                                if (exam.imagenology.isCaptured === false) {
                                    readyToValidateProfile = false;
                                }
                                if (exam.imagenology.userValidate === null) {
                                    readyToReleaseProfile = false
                                }
                            }
                        }

                        exam.analytes !== null && exam.analytes.forEach(element => {
                            if (element.required) {
                                if (element.isCaptured === false) {
                                    readyToValidateProfile = false;
                                }
                                if (element.userValidate === null) {
                                    readyToReleaseProfile = false;
                                }
                            }
                        });
                        if (!exam.isRelease) {
                            validacion_impresion = false;
                        }

                        let exam_method = "";
                        let id_exam_method = "";
                        let id_method = "";
                        if (exam.method_selected > 0) {
                            exam_method = exam.method_selected;
                        } else {
                        }

                        if (!exam.is_imagenology) {
                            try {
                                id_exam_method = exam.methods[0].id_exam_method;
                                id_method = exam.methods[0].id_method;
                            } catch (error) {
                            }
                        }

                        exam.analytes.forEach(analyte => {

                            if (analyte.id_type_analyte === 7) {
                                getDataAnalyteComplex(analyte.id_analyte, funcionesFormulasAux);
                            }

                            if (analyte.compute !== "" && analyte.id_type_analyte === 7) {
                                let formula = analyte.compute;
                                if (funcionesFormulasAux.length > 0) {
                                    funcionesFormulasAux.forEach(form => {
                                        let form_js = form.toLowerCase();
                                        for (let index = 0; index < 5; index++) {
                                            formula = formula.replaceAll(form, "Math." + form_js);
                                        }
                                    })
                                } else {
                                    funcionesFormulas.forEach(form => {
                                        let form_js = form.toLowerCase();
                                        for (let index = 0; index < 5; index++) {
                                            formula = formula.replaceAll(form, "Math." + form_js);
                                        }
                                    })
                                }
                                analyte.compute = formula;
                            }

                            let valor_nuevo = "";
                            let nuevo_comentario = '';
                            let symbol = "";

                            let print_chart = false;
                            let is_interface = false;
                            let is_file = false;
                            let file_base64 = "";
                            if (analyte.capture !== null) {
                                if (analyte.id_type_analyte === 1 || analyte.id_type_analyte === 6 || analyte.id_type_analyte === 14) {
                                    valor_nuevo = analyte.capture.text;
                                    symbol = analyte.capture.symbols;
                                    if (analyte.id_type_analyte === 14) {
                                        is_file = true;
                                        file_base64 = analyte.capture.file_base64;
                                    }

                                } else {
                                    valor_nuevo = analyte.capture.number;
                                    symbol = analyte.capture.symbols;
                                }
                                is_interface = analyte.capture.is_interface;
                                nuevo_comentario = analyte.capture.comments;
                                print_chart = analyte.capture.print_chart;
                            }

                            if (analyte.id_type_analyte === 14) {
                                is_file = true;
                            }

                            if (profile.curves !== null && profile.curves !== undefined && profile.curves.length > 0) {
                                profile.curves.forEach((curva, llaveCurva) => {
                                    if (analyte.sub_id === curva.time.value) {
                                        analitos.push({
                                            "id": analyte.id_analyte,
                                            "sub_id": curva.time.value,
                                            "capture": valor_nuevo,
                                            "send": analyte.send,
                                            "id_exam": exam.id_exam,
                                            "id_type_analyte": analyte.id_type_analyte,
                                            "id_exam_method": exam_method,
                                            "id_exam_method_main": id_method,
                                            "id_app_code_or_method_exam": id_exam_method,
                                            "comment": nuevo_comentario,
                                            "id_work_order_exam": exam.id_work_order_exam,
                                            "is_exam": false,
                                            "is_interface": is_interface,
                                            "integers": analyte.integers,
                                            "decimals": analyte.decimals,
                                            "id_profile": profile.id_profile,
                                            "file_base64": analyte.file_base64,
                                            "is_file": analyte.is_file,
                                            "print_chart": print_chart,
                                            "is_captured": analyte.isCaptured,
                                            "symbols" :symbol
                                        });
                                       analyte.id_analyte_references = analyte.id_analyte_references;
                                    }
                                });
                            } else {
                                let dataMeter = {
                                    "id": analyte.id_analyte,
                                    "capture": valor_nuevo,
                                    "send": analyte.send,
                                    "id_exam": exam.id_exam,
                                    "id_type_analyte": analyte.id_type_analyte,
                                    "id_exam_method": exam_method,
                                    "id_exam_method_main": id_method,
                                    "id_app_code_or_method_exam": id_exam_method,
                                    "comment": nuevo_comentario,
                                    "id_work_order_exam": exam.id_work_order_exam,
                                    "is_exam": false,
                                    "id_profile": profile.id_profile,
                                    "id_section": exam.id_section,
                                    "is_interface": is_interface,
                                    "integers": analyte.integers,
                                    "decimals": analyte.decimals,
                                    "is_file": is_file,
                                    "file_base64": file_base64,
                                    "print_chart": print_chart,
                                    "is_captured": analyte.isCaptured,
                                    "symbols" :symbol
                                };
                                analyte.id_analyte_references = analyte.id_analyte_references;
                                analitos.push(dataMeter);
                            }
                        })
                        

                    // ! BACTERIUMS
                    if (exam.bacteriums !== undefined && exam.bacteriums !== null) {
                
                        exam.bacteriums = exam.bacteriums.forEach((_bacter,_key )=> {

                            // ? CONFIGURATION INPUTS
                            const _id_mic_bacterium = { "value": _bacter?.id_mic_bacterium, "label": _bacter?.label_mic_bacterium };
                            const _id_bacterium_count_text = { "value": _bacter?.id_bacterium_count_text, "label": _bacter?.label_bacterium_count_text };
                            const _id_mic_stain_gram = { "value": _bacter?.id_mic_stain_gram, "label": _bacter?.label_mic_stain_gram };
                            const _id_mic_antibiogram = { "value": _bacter?.id_mic_antibiogram, "label": _bacter?.label_mic_antibiogram};


                            const _ids_resistance_mechanisms = _bacter?.resistance_mechanisms.map(_mechanism => ({
                                value: _mechanism?.id_resistance_mechanism,
                                label: _mechanism?.resistance_mechanism
                            }));

                            let _new_array =[]

                            if (_bacter.bacterium_texts.length > 0 ) {
                                _bacter.bacterium_texts.forEach((_find,key) => {
                                    _new_array.push({
                                        "id_work_order_exam_bacterium_text": _find.id_work_order_exam_bacterium_text,
                                        "text": _find.text,
                                        "id": key+ 1
                                    })
                                });
                            }

                            microbacterias.push({
                                id_temp:_key + 1,
                                "id_work_order_exam": exam.id_work_order_exam,
                                "id_mic_stain_gram": _id_mic_stain_gram,
                                "id_mic_bacterium": _id_mic_bacterium,
                                "quantity_number": _bacter?.quantity_number,
                                "id_bacterium_count_text": _bacter?.label_bacterium_count_text === null ? null :  _id_bacterium_count_text,
                                "comment_resistance_mechanism": _bacter?.comment_resistance_mechanism,
                                "type_bacterium": _bacter?.type_bacterium,
                                "id_mic_antibiogram": _id_mic_antibiogram,
                                "ids_resistance_mechanisms": _ids_resistance_mechanisms,
                                "bacterium_texts": _new_array ?? [],
                                "antibiotics":_bacter?.antibiotics ?? [],
                                "view1":_bacter.type_bacterium === 1 ? true : false,
                                "view2":_bacter.type_bacterium === 2 ? true : false,
                                "view3":_bacter.type_bacterium === 3 ? true : false,
                                "update": true,
                                "released_at":  _bacter?.released_at ?? "",
                                "released_by":  _bacter?.released_by ?? "",
                                "validated_at":  _bacter?.validated_at ?? "",
                                "validated_by":  _bacter?.validated_by ?? "",
                            });
                        });
                        exam.bacteriums = microbacterias
                    }
                      
                    //! PRESUMPTIVE_RESULTS
                    if (exam.presumptive_results !== undefined && exam.presumptive_results !== null) {     
                        // ? OBJECT RESULTS   
                            const groupedResults = {};
                            exam.presumptive_results.presumptives.forEach((_presumptive,key) => {

                        // ? INPUTS PRESUMTIVE RESULTS
                            const tincion = { "value": _presumptive?.id_mic_stain, "label": _presumptive?.label_mic_stain };
                            const resultado = { "value": _presumptive?.id_mic_stain_result, "label": _presumptive?.label_mic_stain_result };
                            const mic = { "value": _presumptive?.id_mic_bacterium, "label": _presumptive?.label_mic_bacterium };

                            // ? VALIDATE OBJECT NEW
                                if (!groupedResults[_presumptive.id_work_order_exam]) {
                                    groupedResults[_presumptive.id_work_order_exam] = {
                                        id: key + 100,
                                        id_work_order_exam: exam?.presumptive_results.id_work_order_exam,
                                        close_capture_presumptive_result: exam?.presumptive_results.close_capture_presumptive_result,
                                        print_presumptive : exam?.presumptive_results.print_presumptive ,
                                        presumptives: []
                                    };
                                }

                                groupedResults[_presumptive?.id_work_order_exam].presumptives.push({
                                    id: key + 1 ,
                                    id_presumptive_result: _presumptive?.id_presumptive_result,
                                    id_mic_stain: tincion,
                                    id_mic_stain_result: resultado,
                                    id_mic_bacterium: mic,
                                    label_mic_stain: _presumptive?.label_mic_stain,
                                    label_mic_stain_result: _presumptive?.label_mic_stain_result,
                                    label_mic_bacterium: _presumptive?.label_mic_bacterium,
                                    observations: _presumptive?.observations,
                                    time: _presumptive?.time,
                                    id_work_order_exam: _presumptive?.id_work_order_exam,
                                    isEdit: true,
                                    user_release: _presumptive?.user_release,
                                    user_validate: _presumptive?.user_validate,
                                    validate_at: _presumptive?.validate_at,
                                    print_result: _presumptive?.print_result
                                });
                            });

                            const _new_array = Object.values(groupedResults);
                            exam.presumptive_results = _new_array
                            presumtive1.push(_new_array)
                    }
                    
                    });
                    profile.readyToValidateProfile = readyToValidateProfile;
                    profile.readyToReleaseProfile = readyToReleaseProfile;
                    profile.id_work_order = respuesta.data.id_work_order;
                });
                setProfiles(respuesta.data.profiles);
            }

            secciones.forEach(seccion => {
                seccion.exams.forEach(exam => {
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    let exam_method = "";
                    let id_exam_method = "";
                    let id_method = "";
                    if (exam.method_selected > 0) {
                        exam_method = exam.method_selected;
                    } else {
                    }

                    if (!exam.is_imagenology) {
                        try {
                            id_exam_method = exam.methods[0].id_exam_method;
                            id_method = exam.methods[0].id_method;
                        } catch (error) {
                        }
                    }

                    exam.analytes !== null && exam.analytes.forEach(analyte => {
                        if (analyte.id_type_analyte === 7) {
                            getDataAnalyteComplex(analyte.id_analyte, funcionesFormulasAux);
                        }

                        let valor_nuevo = "";
                        let nuevo_comentario = '';
                        let _symbol = '';

                        let print_chart = false;
                        let is_interface = false;
                        let is_file = false;
                        let file_base64 = "";

                        if (analyte.capture !== null) {
                            if (analyte.id_type_analyte === 1 || analyte.id_type_analyte === 6 || analyte.id_type_analyte === 14) {

                                valor_nuevo = analyte.capture.text;

                                if (analyte.id_type_analyte === 14) {
                                    is_file = true;
                                    file_base64 = analyte.capture.file_base64;
                                }

                            } else {
                                valor_nuevo = analyte.capture.number;
                                _symbol = analyte.capture.symbol ===  "" ? null : analyte.capture.symbol

                            }
                            is_interface = analyte.capture.is_interface;
                            nuevo_comentario = analyte.capture.comments;
                            print_chart = analyte.capture.print_chart;
                        }

                        if (analyte.id_type_analyte === 14) {
                            is_file = true;
                        }
                        if (analyte.compute !== "" && analyte.id_type_analyte === 7) {
                            let formula = analyte.compute;
                            if (funcionesFormulasAux.length > 0) {
                                funcionesFormulasAux.forEach(form => {
                                    let form_js = form.toLowerCase();
                                    for (let index = 0; index < 5; index++) {
                                        formula = formula.replaceAll(form, "Math." + form_js);
                                    }
                                })
                            } else {
                                funcionesFormulas.forEach(form => {
                                    let form_js = form.toLowerCase();
                                    for (let index = 0; index < 5; index++) {
                                        formula = formula.replaceAll(form, "Math." + form_js);
                                    }
                                })
                            }
                            analyte.compute = formula;
                        }
                        analitos.push({
                            "id": analyte.id_analyte,
                            "capture": valor_nuevo,
                            "send": analyte.send,
                            "id_exam": exam.id_exam,
                            "id_type_analyte": analyte.id_type_analyte,
                            "id_exam_method": exam_method,
                            "id_exam_method_main": id_method,
                            "id_app_code_or_method_exam": id_exam_method,
                            "decimals": analyte.decimals,
                            "compute": analyte.compute,
                            "variable": (analyte.id_analyte_references !== null ? exam.abbreviation.replaceAll(" ", "").replace(/[^a-zA-Z0-9]/g, '').toLowerCase() : exam.abbreviation.replaceAll(" ", "").replace(/[^a-zA-Z0-9]/g, '').toLowerCase()) + "_" + analyte.code.replace(/[^a-zA-Z0-9]/g, '').toLowerCase(),
                            "comment": nuevo_comentario,
                            "id_work_order_exam": exam.id_work_order_exam,
                            "is_exam": true,
                            "id_section": exam.id_section,
                            "is_interface": is_interface,
                            "integers": analyte.integers,
                            "decimals": analyte.decimals,
                            "is_file": is_file,
                            "file_base64": file_base64,
                            "print_chart": print_chart,
                            "is_captured": analyte.isCaptured,
                            "symbol":_symbol

                        });

                        analyte.id_analyte_references = analyte.id_analyte_references;
                    })

                    // ! BACTERIUMS
                    if (exam.bacteriums !== undefined && exam.bacteriums !== null) {
                
                        exam.bacteriums = exam.bacteriums.forEach((_bacter,_key )=> {

                            // ? CONFIGURATION INPUTS
                            const _id_mic_bacterium = { "value": _bacter?.id_mic_bacterium, "label": _bacter?.label_mic_bacterium };
                            const _id_bacterium_count_text = { "value": _bacter?.id_bacterium_count_text, "label": _bacter?.label_bacterium_count_text };
                            const _id_mic_stain_gram = { "value": _bacter?.id_mic_stain_gram, "label": _bacter?.label_mic_stain_gram };
                            const _id_mic_antibiogram = { "value": _bacter?.id_mic_antibiogram, "label": _bacter?.label_mic_antibiogram};


                            const _ids_resistance_mechanisms = _bacter?.resistance_mechanisms.map(_mechanism => ({
                                value: _mechanism?.id_resistance_mechanism,
                                label: _mechanism?.resistance_mechanism
                            }));

                            let _new_array =[]

                            if (_bacter.bacterium_texts.length > 0 ) {
                                _bacter.bacterium_texts.forEach((_find,key) => {
                                    _new_array.push({
                                        "id_work_order_exam_bacterium_text": _find.id_work_order_exam_bacterium_text,
                                        "text": _find.text,
                                        "id": key+ 1
                                    })
                                });
                            }

                            microbacterias.push({
                                id_temp:_key + 1,
                                "id_work_order_exam": exam.id_work_order_exam,
                                "id_mic_stain_gram": _id_mic_stain_gram,
                                "id_mic_bacterium": _id_mic_bacterium,
                                "quantity_number": _bacter?.quantity_number,
                                "id_bacterium_count_text": _bacter?.label_bacterium_count_text === null ? null :  _id_bacterium_count_text,
                                "comment_resistance_mechanism": _bacter?.comment_resistance_mechanism,
                                "type_bacterium": _bacter?.type_bacterium,
                                "id_mic_antibiogram": _id_mic_antibiogram,
                                "ids_resistance_mechanisms": _ids_resistance_mechanisms,
                                "bacterium_texts": _new_array ?? [],
                                "antibiotics":_bacter?.antibiotics ?? [],
                                "view1":_bacter.type_bacterium === 1 ? true : false,
                                "view2":_bacter.type_bacterium === 2 ? true : false,
                                "view3":_bacter.type_bacterium === 3 ? true : false,
                                "update": true,
                                "released_at":  _bacter?.released_at ?? "",
                                "released_by":  _bacter?.released_by ?? "",
                                "validated_at":  _bacter?.validated_at ?? "",
                                "validated_by":  _bacter?.validated_by ?? "",
                            });
                        });
                        exam.bacteriums = microbacterias
                    }

                    //! PRESUMPTIVE_RESULTS
                    if (exam.presumptive_results !== undefined && exam.presumptive_results !== null) {     
                        // ? OBJECT RESULTS   
                            const groupedResults = {};
                            exam.presumptive_results.presumptives.forEach((_presumptive,key) => {

                        // ? INPUTS PRESUMTIVE RESULTS
                            const tincion = { "value": _presumptive?.id_mic_stain, "label": _presumptive?.label_mic_stain };
                            const resultado = { "value": _presumptive?.id_mic_stain_result, "label": _presumptive?.label_mic_stain_result };
                            const mic = { "value": _presumptive?.id_mic_bacterium, "label": _presumptive?.label_mic_bacterium };

                            // ? VALIDATE OBJECT NEW
                                if (!groupedResults[_presumptive.id_work_order_exam]) {
                                    groupedResults[_presumptive.id_work_order_exam] = {
                                        id: key + 100,
                                        id_work_order_exam: exam?.presumptive_results.id_work_order_exam,
                                        close_capture_presumptive_result: exam?.presumptive_results.close_capture_presumptive_result,
                                        print_presumptive : exam?.presumptive_results.print_presumptive ,
                                        presumptives: []
                                    };
                                }

                                groupedResults[_presumptive?.id_work_order_exam].presumptives.push({
                                    id: key + 1 ,
                                    id_presumptive_result: _presumptive?.id_presumptive_result,
                                    id_mic_stain: tincion,
                                    id_mic_stain_result: resultado,
                                    id_mic_bacterium: mic,
                                    label_mic_stain: _presumptive?.label_mic_stain,
                                    label_mic_stain_result: _presumptive?.label_mic_stain_result,
                                    label_mic_bacterium: _presumptive?.label_mic_bacterium,
                                    observations: _presumptive?.observations,
                                    time: _presumptive?.time,
                                    id_work_order_exam: _presumptive?.id_work_order_exam,
                                    isEdit: true,
                                    user_release: _presumptive?.user_release,
                                    user_validate: _presumptive?.user_validate,
                                    validate_at: _presumptive?.validate_at,
                                    print_result: _presumptive?.print_result
                                });
                            });

                            const _new_array = Object.values(groupedResults);
                            exam.presumptive_results = _new_array
                            presumtive1.push(_new_array)
                    }
                })
            });   

           //!OBJECT PRESUMTIVE RESULTS
            const presumtive_results_capture = presumtive1.filter(item => Object.keys(item).length !== 0).flat();

            setAnalytes([...analitos]);
            setMicroBacteriums([...microbacterias]);
            setPresumptive([...presumtive_results_capture])
            setDobleValidacion(respuesta.data.doble_validacion);
            setPrintAvailable(respuesta.data.is_released);
            setpaid(respuesta.data.paid);
            setResultsPartial(respuesta.data.results_partial);
        }
        else if (respuesta.code === 401) validarSesion(history, respuesta.code, getData);
        
        else if (respuesta.code === 500) createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
    }

    const getDataAnalyteComplex = async (id_analyte, funcionesFormulasAux = []) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/GetDataAnalyteComplex/' + id_analyte);

        if (respuesta.code === 200) {
            let aux = analytesComplex;
            let datos = respuesta.data;
            let formula = respuesta.data.formula;
            if (funcionesFormulasAux.length > 0) {
                funcionesFormulasAux.forEach(form => {
                    let form_js = form.toLowerCase();
                    formula = formula.replaceAll(form, "Math." + form_js);
                })
            } else {
                funcionesFormulas.forEach(form => {
                    let form_js = form.toLowerCase();
                    formula = formula.replaceAll(form, "Math." + form_js);
                })
            }
            datos.formula = formula;
            aux.push(datos);
            setAnalytesComplex([...aux]);
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, getDataAnalyteComplex);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
    }

    //! SAVE CAPTURE RESULT 'Exam/SaveCaptureResults'
    const saveIndividualAnalyte = async (obj) => {
       
        let analitos = analytes;
  
        analitos.forEach(element => {
            if (element.variable !== undefined) {
                if (element.id === obj.id_analyte) {
                    window[element.variable] = obj.valor !== "" && parseFloat(obj.valor);
                } else {
                    window[element.variable] = element.capture !== "" && parseFloat(element.capture);
                }
            }
        });
        analitos.map(x => {
    
            if (x.id === obj.id_analyte ) {
                if (x.sub_id !== undefined) {
                    if (x.sub_id === obj.sub_id) {
                        if (obj.active === true ) {
                            return;    
                        }

                        x.capture = obj.valor;
                        x.id_exam_method = obj.id_exam_method;
                        x.id_exam_method_main = obj.id_exam_method_main;
                        x.send = obj.send;
                        x.id_app_code_or_method_exam = obj.id_app_code_or_method_exam;
                        x.validate_format = obj.validate_format;
                        x.is_file = obj.is_file;
                        x.id_analyte_age_gender_range = obj.id_analyte_age_gender_range;
                        x.id_analyte_number_alert = obj.id_analyte_number_alert;
                        x.ids_analyte_text_alerts = obj.ids_analyte_text_alerts;
                        x.id_analyte_references = obj.id_analyte_references;
                        x.symbols = obj.symbols

                    }
                } else {
                    if (obj.active === true ) {
                        return; 
                    }
                    x.capture = obj.valor;
                    x.id_exam_method = obj.id_exam_method;
                    x.id_exam_method_main = obj.id_exam_method_main;
                    x.send = obj.send;
                    x.id_app_code_or_method_exam = obj.id_app_code_or_method_exam;
                    x.validate_format = obj.validate_format;
                    x.is_file = obj.is_file;
                    x.id_analyte_age_gender_range = obj.id_analyte_age_gender_range;
                    x.id_analyte_number_alert = obj.id_analyte_number_alert;
                    x.ids_analyte_text_alerts = obj.ids_analyte_text_alerts;
                    x.id_analyte_references = obj.id_analyte_references;
                    x.symbols = obj.symbols
                }
            }
            if (x.compute !== "" && x.id_type_analyte === 7) {
                let resultado = "";
                if (x.is_interface) {
                    resultado = parseFloat(x.capture);
                }
                else { 
                    try {
                        resultado = eval(x.compute);
                    } catch (error) {
                    }
                }

                if (resultado !== "") {
                    if (!isNaN(resultado)) {
                        if (isFinite(resultado)) {
                            if (resultado === 0) {
                                x.capture = resultado;
                            } else {
                                try {
                                    x.capture = resultado.toFixed(x.decimals);
                                } catch (error) {
                                }
                            }
                        }
                    }
                }
            }
            return x;
        })
       
        analytesComplex.forEach(element => {
        recalculateComplex(analitos);
        });

        if (obj.active === true) {
            let _validAn = analitos
            let _validAnd = _validAn.find(x => x.id === obj.id_analyte);

    
            if (_validAnd) {
                _validAnd.symbols= obj.symbols
                setAnalytes(
                    _validAnd
                );
               }
            
        }
        setAnalytes([...analitos]);
    }

    const saveIndividualExamImagenology = async (obj) => {
        let formData = new FormData();


        formData.append("id_work_order", workOrderId);
        formData.append("id_exam", obj.id_exam);
        formData.append("capture", obj.valor);

        let requestOptions = {
            method: 'POST',
            body: formData
        };

        const response = await sendRequest(requestOptions, "Exam/SaveCaptureResultsImagenology", 'multipart/form-data');

        if (response.code === 200) {

            let updateSections = sections;
            let updateProfiles = profiles;

            let validacion_impresion = true;
            updateSections.map(section => {
                if (section.id === obj.id_section) {
                    section.exams.map(exam => {
                        if (exam.id_exam === obj.id_exam && exam.id_work_order_exam === obj.id_work_order_exam) {

                            let captureData = {
                                id_user: 0,
                                name_document: obj.valor
                            };

                            exam.imagenology.capture = captureData;

                            let usersCapture = exam.imagenology.usersCapture;

                            let datetime_actual = moment();

                            let user_capture = {
                                id_work_order_exam_imagenology: Date.now() + "-" + exam.id_exam,
                                username: "",
                                full_name: nameUser,
                                result_capture: obj.valor,
                                date_capture: datetime_actual.format("D/M/YYYY hh:mm a")
                            }

                            if (usersCapture === null || usersCapture === undefined) {
                                usersCapture = [user_capture];
                            }
                            else {
                                let validacion = usersCapture[0];
                                if (validacion.result_capture == user_capture.result_capture) {
                                    usersCapture = [user_capture, ...usersCapture];
                                }
                            }

                            let user_validate = {
                                id_work_order_exam_imagenology: Date.now() + "validate" + exam.id_exam,
                                full_name: nameUser,
                                date_capture: moment().format("D/M/YYYY hh:mm a"),
                                result_capture: "",
                                username: ""
                            };

                            exam.imagenology = {
                                ...exam.imagenology,
                                userValidate: user_validate,
                                usersCapture: usersCapture,
                                isCaptured: true
                            };
                        }

                        if (!exam.isRelease) {
                            validacion_impresion = false;
                        }

                        return exam;
                    });
                }
                return section;
            });

            updateProfiles.map(perfil => {
                if (perfil.id_profile === obj.sectionProfileId) {
                    let readyToValidateProfile = true;
                    let readyToReleaseProfile = true;
                    perfil.exams.map(exam => {
                        if (exam.id_exam === obj.id_exam && exam.id_work_order_exam === obj.id_work_order_exam) {
                            let captureData = {
                                id_user: 0,
                                name_document: obj.valor
                            };

                            exam.imagenology.capture = captureData;

                            let usersCapture = exam.imagenology.usersCapture;
                            let datetime_actual = moment();

                            let user_capture = {
                                "id_work_order_exam_imagenology": Date.now() + "-" + exam.id_exam,
                                username: "",
                                full_name: nameUser,
                                result_capture: obj.valor,
                                date_capture: datetime_actual.format("D/M/YYYY hh:mm a")
                            };

                            if (usersCapture === null || usersCapture === undefined) {
                                usersCapture = [user_capture];
                            }
                            else {
                                let validacion = usersCapture[0];
                                if (validacion.result_capture == user_capture.result_capture) {
                                    usersCapture = [user_capture, ...usersCapture];
                                }
                            }

                            let user_validate = {
                                id_work_order_exam_imagenology: Date.now() + "validate" + exam.id_exam,
                                full_name: nameUser,
                                date_capture: moment().format("D/M/YYYY hh:mm a"),
                                result_capture: "",
                                username: ""
                            };

                            exam.imagenology = {
                                ...exam.imagenology,
                                userValidate: user_validate,
                                usersCapture: usersCapture,
                                isCaptured: true
                            };
                        }

                        if (exam.imagenology.required) {
                            if (exam.imagenology.isCaptured === false) {
                                readyToValidateProfile = false;
                            }

                            if (exam.imagenology.userValidate === null) {
                                readyToReleaseProfile = false;
                            }
                        }
                        return exam;
                    });

                    perfil.readyToValidateProfile = readyToValidateProfile;
                    perfil.readyToReleaseProfile = readyToReleaseProfile;
                }

                return perfil;
            });

            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            createSweet("success", "success", "Resultado guardado con exito");
        }

    }

    const recalculateComplex = (analitos) => {
        analytesComplex.forEach(element => {
            let resultado = '';
            Object.entries(element.list_analytes).forEach(variable => {
                let valor = "";
                let analyte_aux = analitos.find(q => q.id == variable[0]);
                if(analyte_aux==undefined){
                    analyte_aux = analitos.find(q => q.id_analyte_references == variable[0]);
                 }
                let aux = "";

                try {
                    aux = analyte_aux.capture;
                } catch (error) {
                    console.log(analyte_aux);
                    aux = "";                    
                }

                if (aux !== "") {
                    if (!isNaN(aux)) {
                        if (isFinite(aux)) {
                            if (aux === 0) {
                                valor = aux;
                            } else {
                                valor = parseFloat(aux);
                            }
                        }
                    }
                }
                else{
                    valor = NaN;
                }
                window[variable[1]] = valor;
            });
            let resultado_final = "";
            let analito_final = analitos.find(a => a.id == element.id_analyte);
            try {
                // console.log(element.formula);
                resultado = eval(element.formula);
            } catch (error) {
                console.log('Error aL EVALUAR LA FORMULA',element.formula);
            }
            // let liberar = false;
            // let intentos = 5;
            // let formula_aux = element.formula;
            // // while (liberar === false && intentos > 0) {
            // for (let indice = 0; indice < 5; indice++) {
            //     try {
            //         //console.log("pas de nuevo", formula_aux, intentos, liberar);
            //         resultado = eval(formula_aux);
            //         //console.log(resultado);
            //         liberar = true;
            //     } catch (error) {
            //         if (error.name === "ReferenceError") {
            //             let hola = error.message.split(" ", 1);
            //             hola = hola[0];
            //             formula_aux = formula_aux.replace(hola, "Math." + hola.toLowerCase());
            //             intentos = intentos - 1;
            //         }
            //         // //console.log(error.name);
            //         // //console.log(error.message);
            //         // //console.log(error.stack);
            //     }
            // }
            // //console.log(resultado, element);
            if (resultado !== "") {
                if (!isNaN(resultado)) {
                    if (isFinite(resultado)) {
                        resultado = parseFloat(resultado);
                        if (resultado === 0) {
                            resultado_final = resultado;
                        } else {
                            resultado_final = resultado.toFixed(analito_final.decimals);
                        }
                    }
                }
            }
            analitos.map(a => {
                if (a.id === element.id_analyte) {
                    a.capture = resultado_final;
                    if (a.id_exam_method === "" || a.id_exam_method === 0) {
                        a.id_exam_method = a.id_exam_method_main;
                    }
                }
                return a;
            })
        });
        // debugger
        setAnalytes([...analitos]);
    }

    const saveAllAnalytes = async () => {

        toggleLoadingButton(true);

        //*VALIDATION PRESUMTIVE
        let _validPresuntives = presumptive.length > 0 ? true : false

        if (_validPresuntives) {         
            if (validTestRanges()) {
                toggleLoadingButton(false);
                createSweet("warning", "warning", "Preliminares", "Debe de llenar al menos un campo para poder guardarlo");
                return;
            }  
        }

        // ? VALIDATION BACTERIUM
        let _validBacterium = microBacteriums.length > 0 ? true : false

        if (_validBacterium) {                 
            if (validBacterium()) {
                toggleLoadingButton(false);
                createSweet("warning", "warning", "Mecanismos de Resistencia", "Debe de llenar los campos obligatorios para guardar un mecanismo de resistencia");
                return;
            }  
        }

        //*CONFIGURATION ANALYTES
        let analitosSend = analytes;
        analitosSend = analitosSend.filter(x => (x.is_captured ? true : (x.capture !== "")));
        analitosSend = analitosSend.filter(x => x.id_exam_method !== "");

        //*CONFIGURATION BACTERIUM
        let _send_Bacterium = microBacteriums;

        _send_Bacterium = _send_Bacterium.map(bacterium => {        
            const filteredAntibiotics = bacterium.antibiotics.filter(antibiotic => 
                (antibiotic.id_antibiotic_bacterium_result !== null && antibiotic.id_antibiotic_bacterium_result !== undefined) || (antibiotic.cim !== "")
            );
        
            return {
                id_work_order_exam: bacterium.id_work_order_exam,
                id_mic_stain_gram: bacterium.id_mic_stain_gram.value,
                id_mic_bacterium: bacterium.id_mic_bacterium.value,
                quantity_number: bacterium.quantity_number,
                id_bacterium_count_text: bacterium.id_bacterium_count_text === null ? null : bacterium.id_bacterium_count_text.value,
                comment_resistance_mechanism: bacterium.comment_resistance_mechanism,
                type_bacterium: bacterium.type_bacterium,
                id_mic_antibiogram: bacterium.id_mic_antibiogram === null ?  null : bacterium.id_mic_antibiogram.value,
                ids_resistance_mechanisms: bacterium.ids_resistance_mechanisms.length > 0 ?  bacterium.ids_resistance_mechanisms.map(mechanism => mechanism.value) : [] ,
                bacterium_texts: bacterium.bacterium_texts,
                antibiotics: filteredAntibiotics  
            };
        });

        //*CONFIGURATION RESULT PRESUNTIVE
        const presumtive_results_capture = presumptive.map(item => {
            const newItem = { ...item };
            //!FILTER DELETE
            newItem.presumptives = newItem.presumptives.filter(element => !element.idDelete);
            //!VALUE INPUTS 
            newItem.presumptives.forEach(element => {
             
                //!VALUE INPUTS SENS
                element.id_mic_bacterium1 = element.id_mic_bacterium;
                element.id_mic_stain1 = element.id_mic_stain;
                element.id_mic_stain_result1 = element.id_mic_stain_result;
                //!VALUE RECUPERATE INPUTS
                element.id_mic_bacterium = element.id_mic_bacterium.value;
                element.id_mic_stain = element.id_mic_stain.value;
                element.id_mic_stain_result = element.id_mic_stain_result.value;
            });
            return newItem;
        });

       //*PAYLOAD SEND
       let payload = {
        "id_work_order": workOrderId,
        "analytes_capture": analitosSend,
        "bacteriums_capture": _send_Bacterium, 
        "presumtive_results_capture": presumtive_results_capture
    };

    console.log("🚀 ~ saveAllAnalytes ~ payload:", payload)


        let data = JSON.stringify(payload);

        let requestOptions = {
            method: 'POST',
            body: data
        };
        //*END PAYLOAD SEND
        const respuesta = await sendRequest(requestOptions, 'Exam/SaveCaptureResults');

        if (respuesta.code === 200) {

            // * UPDATE ANALYTES
            const updateAnalytes =  analytes?.map(analyte => {
                if (analitosSend.find(ana => ana?.id === analyte?.id) !== undefined)
                {
                    return {
                        ...analyte,
                        is_captured: (analyte.is_captured && analyte.capture === '' ? false : true)
                    }
                }
                else
                    return analyte;
            });
    
            setAnalytes([...updateAnalytes]);

            let updateSections = sections;
            let updateProfiles = profiles;
            analitosSend.forEach(analito => {
                if (analito.is_exam) {
                    //EXAMENES
                    updateSections.map(section => {
                        if (section.id === analito.id_section) {
                            section.exams.map(exam => {
                                if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                    let reAnalytes = exam.analytes.map(analyte => {
                                        if (analyte.id_analyte === analito.id) {
                                            let precoded = null;
                                            let number = null;
                                            let text = "";

                                            if (analito.id_type_analyte === 1) {
                                                precoded = analito.capture === '' ? '' : parseInt(analito.capture);
                                            } else if (analito.id_type_analyte === 6) {
                                                text = analito.capture;
                                            } else if (analito.id_type_analyte === 2) {
                                                number = analito.capture === '' ? '' : parseInt(analito.capture);
                                            } else {
                                                number = analito.capture === '' ? '' : parseFloat(analito.capture, analyte.decimals);
                                            }

                                            let captureData = {
                                                "id_user": 0,
                                                "id_precoded_text": precoded,
                                                "number": number,
                                                "text": text,
                                                "id_exam_method": analito.id_app_code_or_method_exam,
                                                "is_interface": false,
                                                "comments": analito.comment
                                            }

                                            analyte.capture = captureData;

                                            let usersCapture = analyte.usersCapture;

                                            let datetime_actual = moment();
                                            let user_capture = {
                                                "id_work_order_exam_analyte": Date.now() + "-" + analito.id,
                                                "username": "",
                                                "full_name": nameUser,
                                                "result_capture": analito.capture,
                                                "date_capture": datetime_actual.format("D/M/YYYY hh:mm a")
                                            };

                                            if (usersCapture === null || usersCapture === undefined) {
                                                usersCapture = [user_capture];
                                            } else {
                                                let validacion = usersCapture[0];
                                                if (validacion.result_capture === user_capture.result_capture) {
                                                    usersCapture = [user_capture, ...usersCapture];
                                                }
                                            }
                                            analyte.usersCapture = usersCapture;

                                            analyte.isCaptured = true;
                                            analyte.is_captured = true;
                                            analyte.required=true;
                                        }
                                        return analyte;
                                    })
                                    exam.analytes = [...reAnalytes];
                                    exam.method_selected = analito.id_app_code_or_method_exam;
                                }
                                return exam;
                            })
                        }
                        return section;
                    })
                } else {
                    //PERFILES
                    updateProfiles.map(perfil => {
                        if (perfil.id_profile === analito.id_profile) {
                            let readyToValidateProfile = true;
                            let readyToReleaseProfile = true;
                            perfil.exams.map(exam => {

                                if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                    let reAnalytes = exam.analytes.map(analyte => {
                                        if (analito.sub_id !== null && analito.sub_id !== undefined) {
                                            if ((analyte.id_analyte === analito.id) && (analyte.sub_id === analito.sub_id)) {
                                                let precoded = null;
                                                let number = null;
                                                let text = "";

                                                if (analito.id_type_analyte === 1) {
                                                    precoded = analito.capture === '' ? '' : parseInt(analito.capture);
                                                } else if (analito.id_type_analyte === 6) {
                                                    text = analito.capture;
                                                } else if (analito.id_type_analyte === 2) {
                                                    number = analito.capture === '' ? '' : parseInt(analito.capture);
                                                } else {
                                                    number = analito.capture === '' ? '' : parseFloat(analito.capture, analyte.decimals);
                                                }

                                                let captureData = {
                                                    "id_user": 0,
                                                    "id_precoded_text": precoded,
                                                    "number": number,
                                                    "text": text,
                                                    "id_exam_method": analito.id_app_code_or_method_exam,
                                                    "is_interface": false,
                                                    "comments": analito.comment
                                                }
                                                analyte.capture = captureData;

                                                let usersCapture = analyte.usersCapture;

                                                let datetime_actual = moment();
                                                let user_capture = {
                                                    "id_work_order_exam_analyte": Date.now() + "-" + analito.id,
                                                    "username": "",
                                                    "full_name": nameUser,
                                                    "result_capture": analito.capture,
                                                    "date_capture": datetime_actual.format("D/M/YYYY hh:mm a")
                                                };
                                                if (usersCapture === null || usersCapture === undefined) {
                                                    usersCapture = [user_capture];
                                                } else {
                                                    let validacion = usersCapture[0];
                                                    if (validacion.result_capture === user_capture.result_capture) {
                                                        usersCapture = [user_capture, ...usersCapture];
                                                    }
                                                }
                                                analyte.usersCapture = usersCapture;

                                                analyte.isCaptured = true;
                                                analyte.is_captured = true;
                                                analyte.required = true;
                                            }
                                        }
                                        else if (analyte.id_analyte === analito.id) {

                                            let precoded = null;
                                            let number = null;
                                            let text = "";

                                            if (analito.id_type_analyte === 1) {
                                                precoded = analito.capture === '' ? '' : parseInt(analito.capture);
                                            } else if (analito.id_type_analyte === 6) {
                                                text = analito.capture;
                                            } else if (analito.id_type_analyte === 2) {
                                                number = analito.capture === '' ? '' :  parseInt(analito.capture);
                                            } else {
                                                number = analito.capture === '' ? '' : parseFloat(analito.capture, analyte.decimals);
                                            }

                                            let captureData = {
                                                "id_user": 0,
                                                "id_precoded_text": precoded,
                                                "number": number,
                                                "text": text,
                                                "id_exam_method": analito.id_app_code_or_method_exam,
                                                "is_interface": false,
                                                "comments": analito.comment
                                            }
                                            analyte.capture = captureData;

                                            let usersCapture = analyte.usersCapture;

                                            let datetime_actual = moment();
                                            let user_capture = {
                                                "id_work_order_exam_analyte": Date.now() + "-" + analito.id,
                                                "username": "",
                                                "full_name": nameUser,
                                                "result_capture": analito.capture,
                                                "date_capture": datetime_actual.format("D/M/YYYY hh:mm a")
                                            };

                                            if (usersCapture === null || usersCapture === undefined) {
                                                usersCapture = [user_capture];
                                            } else {
                                                let validacion = usersCapture[0];
                                                if (validacion.result_capture === user_capture.result_capture) {
                                                    usersCapture = [user_capture, ...usersCapture];
                                                }
                                            }
                                            analyte.usersCapture = usersCapture;

                                            analyte.isCaptured = true;
                                            analyte.is_captured = true;
                                            analyte.required=true;
                                        }
                                        return analyte;
                                    })
                                    exam.analytes = [...reAnalytes];

                                    exam.method_selected = analito.id_app_code_or_method_exam;
                                }

                                exam.analytes !== null && exam.analytes.forEach(element => {
                                    if (element.required) {
                                        if (element.isCaptured === false) {
                                            readyToValidateProfile = false;
                                        }
                                        if (element.userValidate === null) {
                                            readyToReleaseProfile = false;
                                        }
                                    }
                                });

                                return exam;
                            })
                            perfil.readyToValidateProfile = readyToValidateProfile;
                            perfil.readyToReleaseProfile = readyToReleaseProfile;
                        }
                        return perfil;
                    })
                }
            });

            //!SAVE RESULT PRESUNTIVE MEMORY
            const presumtive_results_capture = presumptive.map(item => {
                const newItem = { ...item };
                //!FILTER DELETE
                newItem.presumptives = newItem.presumptives.filter(element => !element.idDelete);
                newItem.presumptives.forEach(element => {
                    //!RECUPERATE VALUE INPUTS SAVE
                    element.id_mic_bacterium = element.id_mic_bacterium1;
                    element.id_mic_stain = element.id_mic_stain1;
                    element.id_mic_stain_result = element.id_mic_stain_result1;
                });
                return newItem;
            });
            
            setPresumptive([...presumtive_results_capture])  
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            createSweet("success", "success", "La carga de analitos fue satisfactoria");
            getData()
        }
        else if (respuesta.code === 401) validarSesion(history, respuesta.code, saveAllAnalytes);
        
        else if (respuesta.code === 500) createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        
        else createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        
         toggleLoadingButton(false);
    }

    const releaseExam = async (id_work_order_exam, id_exam) => {
        let payload = {
            "id_work_order_exam": id_work_order_exam,
            "id_exam": id_exam
        }

        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ReleaseExam');
        // console.log(respuesta);

        if (respuesta.code === 200) {
            // //console.log("Ëxito");
            let updateSections = sections;
            let updateProfiles = profiles;

            let validacion_impresion = true;

            updateSections.map(section => {
                let examenes = section.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam && exam.id_exam === id_exam) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                section.exams = [...examenes];
                return section;
            });

            updateProfiles.map(perfil => {
                let examenes = perfil.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam && exam.id_exam === id_exam) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                perfil.exams = [...examenes];
                return perfil;
            })
            // //console.log(updateSections);
            // //console.log(updateProfiles);
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            setPrintAvailable(respuesta.data);
            // await getData();
            //previewWorkOrder();
            createSweet("success", "success", "Se ha liberado el examen");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, releaseExam);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
        // else {
        //     createSweet("error", "error", "Hubo un error!");
        //     validarSesion(history, respuesta.code, releaseExam);
        // }
    }

    const releaseAll = async () => {
        setLoadingRelease(true);
        let id_work_order = workOrderId;
        let nuevas_secciones = JSON.parse(userSections);
        let filtro_secciones = JSON.parse(userFilterSections);
        nuevas_secciones = nuevas_secciones.filter(x => {
            if (filtro_secciones.find(y => y.id_section === x)) {
                return x
            }
        })

        let payload = {
            "sections": nuevas_secciones,
            "nim": nim,
            "id_work_order": id_work_order,
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ReleaseAll');

        if (respuesta.code === 200) {
            let liberados = respuesta.data.list_id_work_order_exams;

            let updateSections = sections;
            let updateProfiles = profiles;

            let validacion_impresion = true;
            updateSections.map(section => {
                // //console.log(section);
                let examenes = section.exams.map(exam => {
                    if (liberados.find(x => x === exam.id_work_order_exam) !== undefined) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                section.exams = [...examenes];
                return section;
            });

            updateProfiles.map(perfil => {
                let examenes = perfil.exams.map(exam => {
                    if (liberados.find(x => x === exam.id_work_order_exam) !== undefined) {
                        exam.isRelease = true;
                        let userData = {
                            "id_user": 0,
                            "id_external": "",
                            "is_admin": false,
                            "is_dfa": false,
                            "username": nameUser,
                            "name": "",
                            "paternal_surname": "",
                            "maternal_surname": "",
                            "email": null,
                            "id_job": null,
                            "job": null,
                            "card_code": null,
                        }
                        exam.user = userData;
                        exam.release_date = moment().format("D/M/YYYY hh:mm a");

                        let analyteUpdate = exam.analytes.map(analyte => {
                            analyte.userValidate = {
                                "id_work_order_exam_analyte": moment() + analyte.id_analyte,
                                "full_name": nameUser,
                                "result_capture": "0",
                                "date_capture": moment().format("D/M/YYYY hh:mm a")
                            }
                            return analyte;
                        })

                        exam.analytes = [...analyteUpdate];
                    }
                    if (!exam.isRelease) {
                        validacion_impresion = false;
                    }
                    return exam;
                })
                perfil.exams = [...examenes];
                return perfil;
            })
            // //console.log(updateSections);
            // //console.log(updateProfiles);
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            setPrintAvailable(respuesta.data.release);
            //previewWorkOrder();
            // await getData();
            createSweet("success", "success", "Se ha liberado el examen");
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, releaseExam);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
        setLoadingRelease(false);
        toggleRelease();
    }

    const validateAnalyte = async (id_work_order_exam, id_exam, id_analyte, sub_id, analito) => {
        let payload = {
            "id_work_order_exam": id_work_order_exam,
            "id_exam": id_exam,
            "id_analyte": id_analyte,
            "sub_id": sub_id !== null ? sub_id : ""
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };
        const respuesta = await sendRequest(requestOptions, 'Exam/ValidateAnalyte');
        if (respuesta.code === 200) {
            if (analito.is_exam) {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === analito.id_section) {
                        section.exams.map(exam => {
                            if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.id_analyte === analito.id) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);

            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === analito.id_profile) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === analito.id_exam && exam.id_work_order_exam === analito.id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.id_analyte === analito.id) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }

            createSweet("success", "success", "Se ha validado el resultado");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validateAnalyte);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
        //TODO:DESCOMENTRAR

        // else {
        //     createSweet("error", "error", "Hubo un error!");
        //     validarSesion(history, respuesta.code, releaseExam);
        // }
    }

    const validateExam = async (id_work_order_exam, id_exam, typeArray, sectionProfileId) => {
        let payload = {
            "id_work_order_exam": id_work_order_exam,
            "id_exam": id_exam,
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ValidateExam');
        // console.log(respuesta);
        if (respuesta.code === 200) {
            if (typeArray === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === id_exam && exam.id_work_order_exam === id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.isCaptured) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + analyte.id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                                exam.id_estatus_exam=4;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);

            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === id_exam && exam.id_work_order_exam === id_work_order_exam) {
                                let nuevos_analitos = exam.analytes.map(analyte => {
                                    if (analyte.isCaptured) {
                                        let userValidate = {
                                            "id_work_order_exam_analyte": Date.now() + "validate" + analyte.id_analyte,
                                            "full_name": nameUser,
                                            "result_capture": "0",
                                            "date_capture": moment().format("D/M/YYYY hh:mm a")
                                        }
                                        analyte.userValidate = userValidate;
                                    }
                                    return analyte;
                                })
                                exam.analytes = [...nuevos_analitos];
                                exam.id_estatus_exam=4;
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
            //previewWorkOrder();

            let bacterias = microBacteriums.filter(x => x.id_work_order_exam === id_work_order_exam);
            bacterias.forEach(async (element) => {
               // await validateBacterium(id_work_order_exam, id_exam, element.id_bacterium, "section");
            });
            createSweet("success", "success", "Se ha validado la prueba correctamente");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validateAnalyte);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    const validateProfile = async (id_profile, id_work_order, id_work_order_profile) => {

        let payload = {
            "id_work_order_exam": id_work_order_profile,
            "id_exam": id_profile,
        }
        let data = JSON.stringify(payload);
        let requestOptions = {
            method: 'POST',
            body: data
        };

        const respuesta = await sendRequest(requestOptions, 'Exam/ValidateProfile');
        if (respuesta.code === 200) {

            let updateProfiles = profiles;
            updateProfiles.map(perfil => {
                if (perfil.id_work_order_profile === id_work_order_profile) {
                    let readyToValidateProfile = true;
                    let readyToReleaseProfile = true;
                    perfil.exams.map(exam => {
                        let nuevos_analitos = exam.analytes.map(analyte => {
                            if (analyte.isCaptured) {
                                let userValidate = {
                                    "id_work_order_exam_analyte": Date.now() + "validate" + analyte.id_analyte,
                                    "full_name": nameUser,
                                    "result_capture": "0",
                                    "date_capture": moment().format("D/M/YYYY hh:mm a")
                                }
                                analyte.userValidate = userValidate;
                            }
                            return analyte;
                        })
                        exam.analytes = [...nuevos_analitos];
                        exam.id_estatus_exam=4;
                        exam.analytes !== null && exam.analytes.forEach(element => {
                            if (element.required) {
                                if (element.isCaptured === false) {
                                    readyToValidateProfile = false;
                                }
                                if (element.userValidate === null) {
                                    readyToReleaseProfile = false;
                                }
                            }
                        });
                        return exam;
                    })
                    perfil.readyToValidateProfile = readyToValidateProfile;
                    perfil.readyToReleaseProfile = readyToReleaseProfile;
                }
                return perfil;
            })
            setProfiles([...updateProfiles]);
            createSweet("success", "success", "Se ha validado el perfil correctamente");
        }
        else if (respuesta.code === 401) {

            validarSesion(history, respuesta.code, validateProfile);
        }
        else if (respuesta.code === 500) {

            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        }
        else {

            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    }

    const calculateAge = (birthday) => {
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        var anios = Math.abs(ageDate.getUTCFullYear() - 1970);


        return anios;
    }

    const calculateAgeMonths = (birthday) => {
        var a = moment();
        var b = moment(birthday, 'YYYY-MM-DD');
        var age = moment.duration(a.diff(b));
        var months = age.months();
        var days = age.days();
        let anios = months + " mes(es)" + " y " + days + " día(s)";
        return anios;
    }

    const printWorkOrder = async (is_printed) => {

        let payload = {
            "nim": nim,
            "password": "",
            "type": "NP"
        }
        let data = JSON.stringify(payload);
        let token = localStorage.getItem("token");

        let alter_url = "";

        if (is_printed) {
            alter_url = "?is_printed=" + is_printed;
        }

        await fetch(ConfigServer.serverUrl + "/api/PrintResult/DownloadSheetResults" + alter_url, {
            method: 'POST',
            body: data,
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
            .then(response => {
                if (response.status !== 200) {
                    createSweet("warning", "warning", "No se ha podido descargar el pdf");
                    return;
                }

                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "hoja_resultados_" + nim + ".pdf");
                document.body.appendChild(link);
                link.click();
                createSweet("success", "success", "Descarga completa");
            });

    }

    const previewWorkOrder = async () => {
        console.log("Sin preview");
        
        setworkMedicalOrderPDFLoading(true)
        let token = localStorage.getItem("token");

        await fetch(ConfigServer.serverUrl + `/api/PrintResult/PreviewAlt?nim=${nim}&type=NP&is_micro_sanitary=${false}&is_preview=true`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
        .then(response => {
            if (response.status !== 200) return;
            return response.json();
        })
        .then(async data => {
            // console.log(data);
            // console.log(nim);
            await fetch(`${ConfigServer.serverUrl}/api/PrintResult/GetFile/${data.watermark}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                setworkMedicalOrderPDFLoading(false);
                setworkMedicalOrderPDF(url);
                setworkMedicalOrderPDFDownload(`${ConfigServer.serverUrl}/api/PrintResult/DownloadFile/${nim}/${data.raw}?jwt=${token}`);
            });
        });
    }

    const previewProgressWorkOrder = async (_valid) => {
 
        setworkMedicalOrderPDFLoading(true)
        let token = localStorage.getItem("token");

        if (_valid === undefined) {
            
        }
        
        await fetch(ConfigServer.serverUrl + `/api/PrintResult/ProgressView?nim=${nim}&type=NP&is_micro_sanitary=false&is_preview=true`, {
            method: 'GET',
            headers: new Headers({
                "Authorization": "Bearer " + token,
                "Content-Type": "application/json"
            })
        })
        .then(response => {
            if (response.status !== 200) return;
            return response.json();
        })
        .then(async data => {
            // console.log(data);
            // console.log(nim);
            await fetch(`${ConfigServer.serverUrl}/api/PrintResult/GetFile/${data.watermark}`, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + token,
                    "Content-Type": "application/json"
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return;
                }
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                setworkMedicalOrderPDFLoading(false);
                setworkMedicalOrderPDF(url);
                setworkMedicalOrderPDFDownload(`${ConfigServer.serverUrl}/api/PrintResult/DownloadFile/${nim}/${data.raw}?jwt=${token}`);
            });
        });
    }

    const sendResultForEmail = async (sendDoctor) => {

        let payload = {
            "nim": nim,
            "password": "",
            "type": "NP",
            "doctor" : sendDoctor
        }

        let requestOptions = {
            method: 'POST',
            body: JSON.stringify(payload)
        };

        const respuesta = await sendRequest(requestOptions, "PrintResult/SendResultForEmail");

        if (respuesta.code === 200) {
            createSweet("create", "success", "Exito!", "Los resultados se han enviado correctamente");
        }
        else if (respuesta.code === 401) {
            validarSesion(history, respuesta.code, sendResultForEmail);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Resultados", respuesta.data.msg);
        }
        else {
            createSweet("error", "warning", "Resultados", respuesta.data.msg);
        }
    }

    useEffect(() => {
        getFuncionesFormula().then(resp => {
            getData(resp);
        });
         backNextState();
    }, [])

    const backNextState = () => {
        var nims = JSON.parse(localStorage.getItem("current_work_orders"));
        if(nims == null) nims = [];
        var idx = nims.indexOf(nim);
        setBackNim((idx <= 0)? null : nims[idx-1]);
        setNextNim((idx == (nims.length -1)) ? null : nims[idx+1])
    }

    const gotoNim = (goNim) => {
        if(goNim != null) {
            window.location.href = `/captura-resultados/${goNim}`
        }
        // else console.log("Error");
    }

    const handleEditCommentsPrintResults = (e) => {
        setCommentsPrintResult(e.target.value);
    }

    const saveCommentsWorkOrder = async () => {

        let formValues = {
            'id_work_order': workOrderId,
            'comment': commentsPrintResult
        };

        if (workOrderId != 0) {

            let requestOptions = {
                method: 'PUT',
                body: JSON.stringify(formValues)
            };

            const respuesta = await sendRequest(requestOptions, "WorkOrder/SaveGlobalCommentsWorkOrder");

            if (respuesta.code === 200) {
                createSweet("create", "success", "Exito!", "Comentarios actualizados");
            }
            else if (respuesta.code === 401) {
                validarSesion(history, respuesta.code, saveCommentsWorkOrder);
            }
            else if (respuesta.code === 500) {
                createSweet("error", "error", "Convenios", "Ocurrio un error en el servidor");
            }
            else {
                createSweet("error", "warning", "Convenios", respuesta.data.msg);
            }

        }
    }

    //#region TYPE BACTERIUM
    // ?  VALIDATE TYPE BACTERIUM
    const handleTypeBacterium = (_type,_idTemp,_id_work_order_exam,_id_mic_bacterium) => 
    {
        let _newArray = microBacteriums
        let _valid = _newArray.find(x => x.id_temp  === _idTemp);
       
        if (_valid !== undefined) {   
            let _validate = false;

            if (_valid.id_work_order_exam === _id_work_order_exam) {
                _validate = true;
            }

            if (_validate) {
                _valid.type_bacterium = _type
                switch (_type) {
                    case 1:                        
                        _valid.view1 = true
                        _valid.view2 = false
                        _valid.view3 = false
                        _valid.bacterium_texts = []
                        break;
                    case 2:
                        _valid.view1 = false
                        _valid.view2 = true
                        _valid.view3 = false
                        handleChangeComments(_type,_idTemp,_id_work_order_exam,_id_mic_bacterium)
                        _valid.antibiotics = []
                        break;
                    case 3:
                        _valid.view1 = false
                        _valid.view2 = false
                        _valid.view3 = true
                        handleChangeComments(_type,_idTemp,_id_work_order_exam,_id_mic_bacterium)
                        _valid.antibiotics = []
                        break;
                    default:
                        break;
                }
            }
            if (_newArray?.length > 0)
                setMicroBacteriums([..._newArray]);     
        } 
    }

    // ! TYPE BACTERIUM 1 ----------------

    // ?  NEW ANTIBIOGRAM
    const addMicroAislado = (_id_work_order_exam, type) => 
    {
        let bacters = microBacteriums;
        
        let nuevo = {
            id_temp: Date.now(),
            userValidate: null,
            id_work_order_exam : _id_work_order_exam,
            id_mic_stain_gram: "",
            id_mic_bacterium:"",
            quantity_number:"",
            id_bacterium_count_text: null,
            comment_resistance_mechanism:"",
            type_bacterium: null,
            id_mic_antibiogram:null,
            ids_resistance_mechanisms:[],
            bacterium_texts:[],
            antibiotics:[],
            view1:false,
            view2:false,
            view3:false,
            released_at:"",
            released_by:"",
            validated_at:"",
            validated_by:"",
        };

        setMicroBacteriums([...bacters, nuevo]);
        if (type === "section") {
            let secciones = sections.map(x => {
                let exams2 = x.exams.map(xx => {
                    if (xx.id_work_order_exam === _id_work_order_exam) {
                        if (xx.bacteriums !== undefined && xx.bacteriums !== null) {
                            xx.bacteriums = [...xx.bacteriums, nuevo];
                        } else {
                            xx.bacteriums = [nuevo];
                        }
                    }
                    return xx;
                })
                x.exams = [...exams2]
                return x;
            });
            setSections([...secciones])
        }else if(type === "profile"){
            profiles.map(x => {
                let exams2 = x.exams.map(xx => {
                    if (xx.id_work_order_exam === _id_work_order_exam) {
                        if (xx.bacteriums !== undefined && xx.bacteriums !== null) {
                            xx.bacteriums = [...xx.bacteriums, nuevo];
                        } else {
                            xx.bacteriums = [nuevo];
                        }
                    }
                    return xx;
                })
                x.exams = [...exams2]
                return x;
            });


        } else {
        }
    }

    // ? DELETE BACTERIUM 1
    const removeBacterium1 = async (_id_work_order_exam, _id, _id_mic_bacterium) => {
        let _bacter = microBacteriums.filter(_x => {
            if (_x?.id_work_order_exam === _id_work_order_exam) {
                if (_x?.id_temp === _id) {
                    if (_x?.update) {
                        sweetDeleteBacterium1(
                            "Microorganismo",
                            `Esta seguro de eliminar el microorganismo ${_id_mic_bacterium.label} , se perderan todos los datos `,
                            "error",
                            true, 
                            _id,
                            (_id) => {
                                setMicroBacteriums(prev => prev.filter(b => b.id_temp !== _id));
                            }
                        );
                    } else {    
                        return false;    
                    }
                }
            }
            return true; 
        });
        setMicroBacteriums([..._bacter]);
    };

    // !TYPE BACTERIUM 2 ----------------

    // ? NEW BACTERIUM TEXTS
    const handleChangeComments = (_type, _id, _idExam, _id_mic_bacterium) => {
      
        let _newArray = microBacteriums;
        let _valid = _newArray.find(x => x.id_temp === _id);
    
        if (_valid !== undefined) {
            let _validate = false;
    
            if (_id_mic_bacterium === null || _id_mic_bacterium === undefined || _id_mic_bacterium === "") {
                if (_valid.id_temp === _id) {
                    _validate = true;
                }
            } else {
                if (_valid.id_work_order_exam === _idExam) {
                    _validate = true;
                }
            }
    
            if (_validate) {
                
                let newEntry = {
                    id: Date.now(),
                    id_work_order_exam_bacterium_text: null,
                    text: ""
                };
                
                if (!Array.isArray(_valid.bacterium_texts)) {
                    _valid.bacterium_texts = [];
                }
                _valid.bacterium_texts.push(newEntry); 
    
                if (_newArray.length > 0) {
                    setMicroBacteriums([..._newArray]); 
                }
            }
        }
    };

    
    // ? DELETE BACTERIUM 2
    const removeBacteriumAntibitic = async (_id_work_order_exam, _id, _id_mic_bacterium, _i) => {
        let _filter = microBacteriums.filter(b => {
            return b.id_work_order_exam === _id_work_order_exam && b.id_temp === _i;
        });
        
        if (_filter.length > 0) {
            let _type = _filter[0].bacterium_texts;
    
            const _update_text = _type.filter(_find => {
                if (_find?.id === _id) {
                    
                    if (_find?.id_work_order_exam_bacterium_text === null || _find?.id_work_order_exam_bacterium_text === "") {
                        return false;
                    }else {
                    
                        sweetDeleteBacterium1(
                            "Antibiotico",
                            `Esta seguro de eliminar el antibiotico del microorganismo ${_id_mic_bacterium.label} , se perderan todos los datos `,
                            "error",
                            true,
                            _find?.id, 
                            async (id) => {
                                setMicroBacteriums(prev => {
                                    return prev.map(_search => {
                                        if (_search?.id_temp === _i) { 
                                            return {
                                                ..._search,
                                                bacterium_texts: _search?.bacterium_texts.filter(text => text.id !== id), 
                                            };
                                        }
                                        return _search;
                                    });
                                });
                            }
                        );
                    }
                }
                return true; 
            });
    
            if (_update_text.length !== _type.length) {
                setMicroBacteriums(prev => {
                    return prev.map(_update => {
                        if (_update?.id_temp === _i) {
                            return {
                                ..._update,
                                bacterium_texts: _update_text 
                            };
                        }
                        return _update;
                    });
                });
            }
        }
    };
    
    // ? VALIDATION SAVE BACTER
     const validBacterium = () => 
    {
        let statusValidation = false;

        if (microBacteriums.length > 0) {

            microBacteriums.forEach(_find => {
                // !REQUIRED FIELDS
                if (_find.id_mic_stain_gram === "") {
                    statusValidation = true;   
                }
                if (_find.id_mic_bacterium === "") {
                    statusValidation = true;   
                }
            });
        }
        else statusValidation = true;
        return statusValidation;
    }

    // * VALIDATE BACTERIUM -------------------------
    

    const ValidateBacterium = async (_id_work_order_exam,_id,_id_mic_bacterium,_type,_examId,_sectionProfileId,_type_array,_name_bacterium) => {

         const response = await validateBacterium({
            id_work_order_exam: _id_work_order_exam,
            id_mic_bacterium: _id_mic_bacterium.value,
        });   
        
        if (response.code === 200) {
            createSweett("Validación", `Se ha validado correctamente el microorganismo ${_name_bacterium.label} `, "success");
            const updatedMicroBacteriums = microBacteriums.map(_x => {
                if (_x?.id_work_order_exam === _id_work_order_exam && _x?.id_temp === _id) {
                    _x.validated_by = nameUser
                    _x.validated_at = moment().format("D/M/YYYY hh:mm a")
                }
                return _x; 
            });
            setMicroBacteriums(updatedMicroBacteriums);
        }
        else createSweet("warning", "warning", "Bacterias!", response?.msg);
    }

    const UnValidateBacterium = async (_id_work_order_exam,_id,_id_mic_bacterium,_type,_examId,_sectionProfileId,_type_array) => {

        const response = await unValidateBacterium({
           id_work_order_exam: _id_work_order_exam,
           id_mic_bacterium: _id_mic_bacterium.value,
       });   

       if (response.code === 200) {
        createSweet("success", "success", "Validación", "Se ha desvalidado correctamente")
           const updatedMicroBacteriums = microBacteriums.map(_x => {
               if (_x?.id_work_order_exam === _id_work_order_exam && _x?.id_temp === _id) {
                   _x.validated_by = ""
                   _x.validated_at = ""
                   
               }
               return _x; 
           });
           setMicroBacteriums(updatedMicroBacteriums);
       }
       else createSweet("warning", "warning", "Bacterias!", response?.msg);
   }

    const ReleaseBacterium = async (_id_work_order_exam,_id,_id_mic_bacterium,_type,_examId,_sectionProfileId,_type_array,_name_bacterium) => {

         const response = await releaseBacterium({
            id_work_order_exam: _id_work_order_exam,
            id_mic_bacterium: _id_mic_bacterium.value,
        });   

        if (response.code === 200) {
            createSweett("Liberación", `Se ha liberado correctamente el microorganismo ${_name_bacterium.label} `, "success");
            const updatedMicroBacteriums = microBacteriums.map(_x => {
                if (_x?.id_work_order_exam === _id_work_order_exam && _x?.id_temp === _id) {
                    _x.released_by = nameUser
                    _x.released_at = moment().format("D/M/YYYY hh:mm a")
                }
                return _x; 
            });
            setMicroBacteriums(updatedMicroBacteriums);
        }
        else createSweet("warning", "warning", "Bacterias!", response?.msg);
    }

    const UnReleaseBacterium = async (_id_work_order_exam,_id,_id_mic_bacterium,_type,_examId,_sectionProfileId,_type_array) => {

        const response = await unreleaseBacterium({
           id_work_order_exam: _id_work_order_exam,
           id_mic_bacterium: _id_mic_bacterium.value,
       });   

       if (response.code === 200) {
            createSweet("success", "success", "Validación", "Se ha desvalidado correctamente")
           const updatedMicroBacteriums = microBacteriums.map(_x => {
               if (_x?.id_work_order_exam === _id_work_order_exam && _x?.id_temp === _id) {
                   _x.released_by = ""
                   _x.released_at = ""
               }
               return _x; 
           });
           setMicroBacteriums(updatedMicroBacteriums);
       }
       else createSweet("warning", "warning", "Bacterias!", response?.msg);
   }

    // * END VALIDATE BACTERIUM -------------------------

     
    //#endregion
    
    //!NUEVO
    const removeValidationFromExamOrProfile = async (id_work_order_exam) => {
        let requestOptions = {
            method: 'GET',
        };
    
        const respuesta = await sendRequest(requestOptions, "ProcessExam/RemoveValidationFromExamOrProfile/" + id_work_order_exam);
        console.log('RESPUESTA: ', respuesta);
    
        if (respuesta.code === 200) {
            const idsToInvalidate = [id_work_order_exam, ...respuesta.data];
    
            let updateSections = sections.map(section => {
                section.exams = section.exams.map(exam => {
                    if (idsToInvalidate.includes(exam.id_work_order_exam)) {
                        exam.isRelease = false;
                        exam.user = null;
                        exam.release_date = null;
                        exam.id_estatus_exam = 3;
    
                        exam.analytes = exam.analytes.map(analyte => {
                            analyte.userValidate = null;
                            return analyte;
                        });
                    }
                    return exam;
                });
                return section;
            });
    
            let updateProfiles = profiles.map(perfil => {
                perfil.exams = perfil.exams.map(exam => {
                    if (idsToInvalidate.includes(exam.id_work_order_exam)) {
                        exam.isRelease = false;
                        exam.user = null;
                        exam.release_date = null;
                        exam.id_estatus_exam = 3;
    
                        exam.analytes = exam.analytes.map(analyte => {
                            analyte.userValidate = null;
                            return analyte;
                        });
                    }
                    return exam;
                });
                return perfil;
            });
    
            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
        } else if (respuesta.code === 401) {
            //validarSesion(history, respuesta.code, validateBacterium);
        } else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor");
        } else {
            createSweet("error", "warning", "Captura de resultados", respuesta.data.msg);
        }
    };
   
    const updateMethod = async (id_work_order_exam) => {

        let requestOptions = {
            method: 'GET',
        };

        const respuesta = await sendRequest(requestOptions, "Exam/UpdateMethodExam/" + id_work_order_exam);

        if (respuesta.code === 200) {
           
            let updateSections = sections;
            let updateProfiles = profiles;

            updateSections.map(section => {
                let examenes = section.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam) {
                        exam.method_selected=respuesta.data;
                    }
                    return exam;
                })
                section.exams = [...examenes];
                return section;
            });

            updateProfiles.map(perfil => {
                let examenes = perfil.exams.map(exam => {
                    if (exam.id_work_order_exam === id_work_order_exam) {
                        exam.method_selected =respuesta.data;
                        
                    }

                    return exam;
                })
                perfil.exams = [...examenes];
                return perfil;
            })

            setSections([...updateSections]);
            setProfiles([...updateProfiles]);
            createSweet("success", "success","Captura de resultados","Método actualizado correctamente.");
            window.location.reload();

        }
        else if (respuesta.code === 401) {
            //validarSesion(history, respuesta.code, validateBacterium);
        }
        else if (respuesta.code === 500) {
            createSweet("error", "error", "Captura de resultados", "Ocurrio un problema en el servidor.");
        }
        else {
            createSweet("error", "warning", "Captura de resultados.", respuesta.data.msg);
        }

    }
    //END METOOD EXAMEN

    //#region PRESUMTIVE RESULTS
    //*ACTIONS PRESUNTIVE RESULTS
    const addPresumptiveResult = (id_work_order_exam, _type,examId,_sectionProfileId) => {
        
        let _presumptive = presumptive;

        let nuevo = {
            "id":Date.now(),
            "id_work_order_exam": id_work_order_exam,
            "close_capture_presumptive_result": false,
            "print_presumptive": false,
            "presumptives": [
                {
                    "id":Date.now(),
                    "id_presumptive_result": null ,
                    "id_mic_stain": "",
                    "id_mic_stain_result": "",
                    "id_mic_bacterium": "",
                    "label_mic_stain": "",
                    "label_mic_stain_result": "",
                    "label_mic_bacterium": "",
                    "observations": "",
                    "time": "",
                    "id_work_order_exam": id_work_order_exam,
                    "isEdit": false,
                    "print_result" :false,
                    "user_release":null,
                    "user_validate": null,
                }
            ]
        };

        const getPresuntive = _presumptive?.find(x => x.id_work_order_exam === id_work_order_exam);

        if (getPresuntive !== undefined)
        {
            getPresuntive.presumptives.push(
                {
                    "id":Date.now(),
                    "id_presumptive_result": null ,
                    "id_mic_stain": "",
                    "id_mic_stain_result": "",
                    "id_mic_bacterium": "",
                    "label_mic_stain": "",
                    "label_mic_stain_result": "",
                    "label_mic_bacterium": "",
                    "observations": "",
                    "time": "",
                    "id_work_order_exam": id_work_order_exam,
                    "isEdit": false,
                    "print_result" :false,
                    "user_release":null,
                    "user_validate": null,
                }
            );
        }
        else
        {
            _presumptive.push(nuevo);
        }

        setPresumptive([..._presumptive]);
    

        if (_type === "profile") {
            profiles.map(x => {
                let exams2 = x.exams.map(xx => {
                    if (xx.id_work_order_exam === id_work_order_exam) {
                        if (xx.presumptive_results !== undefined && xx.presumptive_results !== null) {
                            if (xx.presumptive_results?.length > 0)
                            {
                                
                            }
                            else
                            {
                                xx.presumptive_results = [nuevo];
                            }
                        } else {
                            xx.presumptive_results = [nuevo];
                        }
                    }
                    return xx;
                })
                x.exams = [...exams2]
                return x;
            });

        }
        else {
             let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === id_work_order_exam) {
                                console.log("paso aqui");
                                if (exam.presumptive_results !== undefined && exam.presumptive_results !== null) {
                                    if (exam.presumptive_results?.length > 0)
                                    {
                                        
                                    }
                                    else
                                    {
                                        exam.presumptive_results = [nuevo];
                                    }
                                } else {
                                    exam.presumptive_results = [nuevo];
                                }
                                return exam;
                              
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);

        }

    }

    //!SAVE PRESUNTIVE FINAL
    const handleChangePresumptive = async (e,_id_work_order_exam,_type,examId,_sectionProfileId) => {

        let _valor = e.target.checked;

        if (_valor) {
            let _listTemp = await handleRequest("PUT", `Exam/LockPresumtiveResults/${_id_work_order_exam}`, "Global Impresion");

            if (_listTemp !== null) {

                createSweet("success", "success", "Reporte final", "Se a finalizado correctamente el reporte de presuntivos.");

                if (_type === "section") {
                    let updateSections = sections;
                    updateSections.map(section => {
                        if (section.id === _sectionProfileId) {
                            section.exams.map(exam => {
                                if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                    exam.presumptive_results.map(_presuntive => {
                                        _presuntive.close_capture_presumptive_result = _valor;  
                                        return _presuntive;
                                    })                                   
                                    return exam;
                                }
                                return section;
                            })
                        }
                        return section;
                    })
                    setSections([...updateSections]);
                } else {
                    let updateProfiles = profiles;
                    updateProfiles.map(perfil => {
                        if (perfil.id_profile === _sectionProfileId) {
                            perfil.exams.map(exam => {
                                if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                    exam.presumptive_results.map(_presuntive => {
                                        _presuntive.close_capture_presumptive_result = _valor;  
                                        return _presuntive;
                                    })
                                }
                                return exam;
                            })
                        }
                        return perfil;
                    })
                    setProfiles([...updateProfiles]);
                }
            }   
        }
        else {
            let _listTemp = await handleRequest("PUT", `Exam/UnlockPresumtiveResults/${_id_work_order_exam}`, "Global Impresion");

            if (_listTemp !== null) {

                createSweet("success", "success", "Reporte final", "Finalizado.");

                if (_type === "section") {
                   let updateSections = sections;
                    updateSections.map(section => {
                        if (section.id === _sectionProfileId) {
                            section.exams.map(exam => {
                                if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                    exam.presumptive_results.map(_presuntive => {
                                        _presuntive.close_capture_presumptive_result = _valor;  
                                        return _presuntive;
                                    })                                   
                                    return exam;
                                  
                                }
                                return section;
                            })
                        }
                        return section;
                    })
                    setSections([...updateSections]);
                } else {
                    let updateProfiles = profiles;
                    updateProfiles.map(perfil => {
                        if (perfil.id_profile === _sectionProfileId) {
                            perfil.exams.map(exam => {
                                if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                    exam.presumptive_results.map(_presuntive => {
                                        _presuntive.close_capture_presumptive_result = _valor;  
                                        return _presuntive;
                                    })
                                }
                                return exam;
                            })
                        }
                        return perfil;
                    })
                    setProfiles([...updateProfiles]);
                }
                removePrintingGlobal(_id_work_order_exam,_type,examId,_sectionProfileId,"remove")
           }
        }
    }

    //!REMOVE PRESUNTIVE
    const removePresumptiveResult = async (_id_work_order_exam, _id_presuntive, _edition, _type) => {
      
        //!VARIBLES 
        let _presumptive = presumptive;
    
        _presumptive = _presumptive.map(_x => {
            if (_x.id_work_order_exam === _id_work_order_exam) {
    
                _x.presumptives = _x.presumptives.filter(_a => {
                    if (_a.id_presumptive_result === _id_presuntive) {
                        if (_a.isEdit) {
                            _a.id_presumptive_result = null;
                            _a.idDelete = true
                            return true; 
                        } else {
                            return false; 
                        }
                    } else {
        
                        return true;
                    }
                });
            }
            return _x;
        });
    
        setPresumptive([..._presumptive]);
    };

    //!PRINTING PRESUNTIVE RESULTS
    const PrintingPresumptiveResult = async (_id_work_order_exam, _id_presumptive, _type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/ActivePrintPresumtiveResults/${_id_presumptive}`, "Presuntivos Imprimir");

        if (_listTemp !== null) {

            createSweet("success", "success", "Impresión de presuntivos", "Se a activado correctamento la Impresión.");
            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive) {
                                            _find.print_result = true;
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);

            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive) {
                                            console.log(_find);
                                            _find.print_result = true;
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }  
            }     
    };

    //!CANCEL PRINTING PRESUNTIVE
    const cancelPrintingPresumptive = async (_id_work_order_exam, _id_presumptive, _type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/DisablePrintPresumtiveResults/${_id_presumptive}`, "Presuntivos Imprimir");

        if (_listTemp !== null) {

            createSweet("success", "success", "Impresión de presuntivos", "Se a desactivado correctamento la impresión.");

            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive) {
                                            _find.print_result = false;
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive) {
                                            _find.print_result = false;
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }  
        }       
    }

    //!CANCEL REMOVE PRESUMTIVE
    const cancelRemovePresumtiveResults = async(_id_work_order_exam, _id_presuntive, _edition, _type) => {
          //!VARIBLES 
          presumptive.forEach(_x => {
            if (_x.id_work_order_exam === _id_work_order_exam) {
                _x.presumptives.forEach(_a => {
                    if (_a.id_presumptive_result === _id_presuntive) {
                        _a.idDelete = false;
                    } 
                });
            }
        });
        setPresumptive([...presumptive]); 
    }

    //!PRINTING PRESUMTIVE RESULTS GLOBAL
    const printingGlobal = async (_id_work_order_exam,_type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/EnableGlobalPrintPresumtiveResults/${_id_work_order_exam}`, "Global Impresion");

        if (_listTemp !== null) {

            createSweet("success", "success", "Impresión Gloabal", "Se a activado correctamento la Impresión Global.");

            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.print_presumptive = true;  
                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.print_presumptive = true;  
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
        }     
    }

    //!PRINTING PRESUMTIVE RESULTS GLOBAL
     const removePrintingGlobal = async (_id_work_order_exam,_type,examId,_sectionProfileId,_validRemove) => {

        let _listTemp = await handleRequest("PUT", `Exam/DisableGlobalPrintPresumtiveResults/${_id_work_order_exam}`, "Global Impresion");

        if (_listTemp !== null) {

            //!VALIDATION REMOVE CHANGE PRESUMTIVE
            if (_validRemove === undefined) {
                createSweet("success", "success", "Impresión Gloabal", "Se a desactivado correctamento la Impresión Global.");  
            }

            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.print_presumptive = false;  
                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.print_presumptive = false;  
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
         }    
    }
    //*END ACTIONS PRESUNTIVE RESULTS
 

    //*CONFIGURATIONS VALIDATIONS
    const valdatePresumtiveResults = async (_id_work_order_exam,_id_presumptive_result,_type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/ValidatePresumtiveResults/${_id_presumptive_result}`, "Presuntivos");

        if (_listTemp !== null) {

            createSweet("success", "success", "Validación de presuntivos", "Se a validado correctamente.");
            
            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_validate = nameUser
                                            _find.validate_at = moment().format("D/M/YYYY hh:mm a")
                                            return _find;         
                                           }
                                          })

                                    return _presuntive;
                                })                                   
                                return exam;
                              
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_validate = nameUser
                                            _find.validate_at = moment().format("D/M/YYYY hh:mm a")
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
        }
    }

    const unvalidatePresumtiveResults = async (_id_work_order_exam,_id_presumptive_result,_type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/UnvalidatePresumtiveResults/${_id_presumptive_result}`, "Presuntivos");

        if (_listTemp !== null) {

            createSweet("warning", "warning", "Validación de presuntivos", "Se a desvalidado correctamente.");

            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_validate = null
                                            _find.validate_at = null
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_validate = null;
                                            _find.validate_at = null;
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
        }
    }

    const ReleasePresumtiveResults = async (_id_work_order_exam,_id_presumptive_result,_type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/ReleasePresumtiveResults/${_id_presumptive_result}`, "Presuntivos");

        if (_listTemp !== null) {

            createSweet("success", "success", "Liberación de presuntivos", "Se a liberado correctamente.");

            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_release = nameUser;
                                            _find.release_at = moment().format("D/M/YYYY hh:mm a");
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_release = nameUser;
                                            _find.release_at = moment().format("D/M/YYYY hh:mm a");
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
        }
    }

    const unreleasePresumtiveResults = async (_id_work_order_exam,_id_presumptive_result,_type,examId,_sectionProfileId) => {

        let _listTemp = await handleRequest("PUT", `Exam/UnreleasePresumtiveResults/${_id_presumptive_result}`, "Presuntivos");

        if (_listTemp !== null) {

            createSweet("warning", "warning", "Liberación de presuntivos", "Se a desvalidado correctamente.");

            if (_type === "section") {
                let updateSections = sections;
                updateSections.map(section => {
                    if (section.id === _sectionProfileId) {
                        section.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_release = null;
                                            _find.release_at = null;
                                            return _find;         
                                           }
                                          })

                                    return _presuntive;
                                })                                   
                                return exam;
                            }
                            return section;
                        })
                    }
                    return section;
                })
                setSections([...updateSections]);
            } else {
                let updateProfiles = profiles;
                updateProfiles.map(perfil => {
                    if (perfil.id_profile === _sectionProfileId) {
                        perfil.exams.map(exam => {
                            if (exam.id_exam === examId && exam.id_work_order_exam === _id_work_order_exam) {
                                exam.presumptive_results.map(_presuntive => {
                                    _presuntive.presumptives.map(_find => {
                                        if (_find.id_presumptive_result  === _id_presumptive_result) {
                                            _find.user_release = null;
                                            _find.release_at = null;
                                            return _find;         
                                           }
                                          })
                                    return _presuntive;
                                })
                            }
                            return exam;
                        })
                    }
                    return perfil;
                })
                setProfiles([...updateProfiles]);
            }
        }
    }

    
    //*VALIDATION SAVE PRESUMTIVE
    const validTestRanges = () => {
        let statusValidation = false;
        if (presumptive?.length > 0) {
            presumptive.forEach(_find => {
                _find.presumptives.forEach(_valid => {

                    // //!REQUIRED FIELDS fields
                    if (_valid.id_mic_stain === "" && 
                        _valid.id_mic_stain_result === "" && 
                        _valid.time === "" && 
                        _valid.id_mic_bacterium === "" && 
                        _valid.observations === "") {
                        statusValidation = true; 
                    } else {
                        statusValidation = false; 
                    }
                    
                });  
            });
        }
        else {
            statusValidation = true;
        }
        return statusValidation;
    }
    //*END VALIDATION SAVE PRESUMTIVE
    //*END CONFIGURATIONS VALIDATIONS

    //#endregion


    const handleRequest = async (method, methodUrl, nameMethod, body = null) => {
        let requestOptions = {
            method: method
        };

        if (body !== null) {
            requestOptions = {
                ...requestOptions,
                body: JSON.stringify(body)
            };
        }

        const response = await sendRequest(requestOptions, methodUrl);

        if (response.code === 200) {

            return response.data;

        }
        else if (response.code === 409) {

            //createSweet("info", "info", "Atención", response.data.msg);
        }
        else if (response.code === 500) {
        }
        else {
        }
        return null;
    }

    return {
        loadingButton, analytes, profiles, sections, patient, listDoctors, sendDoctor,saveIndividualAnalyte, saveAllAnalytes, releaseExam, releaseAll, validateAnalyte, validateExam, validateProfile, printAvailable, dobleValidacion, resultsPartial, printWorkOrder, sendResultForEmail,
        observations, commentsPrintResult, handleEditCommentsPrintResults, saveCommentsWorkOrder,
        microBacteriums, addMicroAislado, microAntibiograms,
        nimPatient, commentsFlebo, workMedicalOrder, workMedicalOrderPDF, workMedicalOrderPDFLoading, workMedicalOrderPDFDownload, type_file,
        handleCreate, toggle, method, modal, modalTitle1, typeModal, modalRelease, toggleRelease, loadingRelease, getViewPreview, previewWorkOrder,
        saveIndividualExamImagenology, handleGetDocumentImagenology, modalViewImagenology, modalTitleImg, toggleModalViewImagenology, documentImagenology, loadingPatient,
        removeValidationFromExamOrProfile,updateMethod,paid,credit_payment,setAnalytes,
        backNim, nextNim, gotoNim,typeModalMedical,toggleMedical,modalMedical,
        // * BACTERIUMS
        microAntibiotics,setMicroAntibiotics,setMicroBacteriums,handleTypeBacterium,handleChangeComments,removeBacterium1,removeBacteriumAntibitic,
        ValidateBacterium,UnValidateBacterium,ReleaseBacterium,UnReleaseBacterium,

        // * PRESUMPTIVE
        addPresumptiveResult,presumptive,setPresumptive,removePresumptiveResult,handleChangePresumptive,PrintingPresumptiveResult,cancelRemovePresumtiveResults,printingGlobal,cancelPrintingPresumptive,removePrintingGlobal,
        
        // * VALIDATIONS PRESUMTIVE RESULTS
        valdatePresumtiveResults,ReleasePresumtiveResults,unvalidatePresumtiveResults,unreleasePresumtiveResults,

        // * CONFIGURATION
        taptopStyle,executeScroll,

    }
}
